import React from 'react';

import { IconProps } from './icon_definition';

export const SortRowsIcon = (props: IconProps) => {
    const { color = '#222222', ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M8 11.5L8 5.5" stroke={color} strokeLinecap="round" />
            <path d="M5.5 8.5H1.5" stroke={color} strokeLinecap="round" />
            <path d="M10.5 8.5L14.5 8.5" stroke={color} strokeLinecap="round" />
            <path d="M1.5 8.5L3.5 10.5" stroke={color} strokeLinecap="round" />
            <path d="M14.5 8.5L12.5 6.5" stroke={color} strokeLinecap="round" />
            <path d="M1.5 8.5L3.5 6.5" stroke={color} strokeLinecap="round" />
            <path d="M14.5 8.5L12.5 10.5" stroke={color} strokeLinecap="round" />
        </svg>
    );
};
