import React from 'react';

import { IconProps } from './icon_definition';

export const ArrowDownIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 2.5C8.27614 2.5 8.5 2.72386 8.5 3L8.5 13C8.5 13.2761 8.27614 13.5 8 13.5C7.72386 13.5 7.5 13.2761 7.5 13L7.5 3C7.5 2.72386 7.72386 2.5 8 2.5Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3536 9.64645C11.5488 9.84171 11.5488 10.1583 11.3536 10.3536L8.35355 13.3536C8.15829 13.5488 7.84171 13.5488 7.64645 13.3536C7.45118 13.1583 7.45118 12.8417 7.64645 12.6464L10.6464 9.64645C10.8417 9.45118 11.1583 9.45118 11.3536 9.64645Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.64645 9.64645C4.84171 9.45118 5.15829 9.45118 5.35355 9.64645L8.35355 12.6464C8.54882 12.8417 8.54882 13.1583 8.35355 13.3536C8.15829 13.5488 7.84171 13.5488 7.64645 13.3536L4.64645 10.3536C4.45118 10.1583 4.45118 9.84171 4.64645 9.64645Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
