import React from 'react';

import { IconProps } from './icon_definition';

export const ArrowUpIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 13.5C7.72386 13.5 7.5 13.2761 7.5 13L7.5 3C7.5 2.72386 7.72386 2.5 8 2.5C8.27614 2.5 8.5 2.72386 8.5 3L8.5 13C8.5 13.2761 8.27614 13.5 8 13.5Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.64645 6.35355C4.45118 6.15829 4.45118 5.84171 4.64645 5.64645L7.64645 2.64645C7.84171 2.45118 8.15829 2.45118 8.35355 2.64645C8.54881 2.84171 8.54882 3.15829 8.35355 3.35355L5.35355 6.35355C5.15829 6.54882 4.84171 6.54882 4.64645 6.35355Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3536 6.35355C11.1583 6.54882 10.8417 6.54882 10.6464 6.35355L7.64645 3.35355C7.45118 3.15829 7.45118 2.84171 7.64645 2.64645C7.84171 2.45118 8.15829 2.45118 8.35355 2.64645L11.3536 5.64645C11.5488 5.84171 11.5488 6.15829 11.3536 6.35355Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
