/* eslint-disable complexity */
import { UpdateScope } from 'lib/constants/inspections';

import { InspectionAnomaly } from '../types/inspections/anomaliesMap';

/**
 * Checks if an anomaly code represents a multi-module anomaly type
 * (inverter outage, combiner outage, string outage, harness outage, tracker misalignment)
 */
export const isMultiModuleAnomaly = (anomalyCode: string): boolean => {
    // Multi-module anomaly codes
    const multiModuleAnomalyCodes = ['io', 'co', 'so', 'ho', 'tr'];

    // Extract base code from codes with modifiers (e.g., 'tr-sv')
    const baseCode = anomalyCode.split('-')[0];

    return multiModuleAnomalyCodes.includes(baseCode);
};

/**
 * Extracts electrical component information from an anomaly
 * Uses speciesAssetMap when available for more complete electrical information
 * Works with the accumulated asset regions in speciesAssetMap
 */
export const getElectricalInfoFromAnomaly = (
    anomaly: InspectionAnomaly,
    speciesAssetMap?: Record<
        string,
        Array<{
            assetRegionUUID: string;
            combinerId?: string;
            inverterId?: string;
            stackId?: string;
            anomalyUuids?: string[];
        }>
    >,
) => {
    if (!anomaly) {
        return null;
    }

    // Get anomaly code from IR or RGB signal
    const anomalyCode =
        anomaly?.anomalyDefinition?.species?.ir_signal || anomaly?.anomalyDefinition?.species?.rgb_signal || '';

    // Get species UUID for lookup in speciesAssetMap
    const speciesUuid = anomaly?.anomalyDefinition?.species?.uuid;

    let inverterId;
    let combinerId;
    let stackId;

    // Get electrical info for this specific anomaly, not just any asset region with this species
    if (speciesAssetMap && speciesUuid && speciesAssetMap[speciesUuid] && speciesAssetMap[speciesUuid].length > 0) {
        // Find the asset info that contains this specific anomaly UUID
        const anomalyUuid = anomaly.uuid;
        const matchingAssetInfo = speciesAssetMap[speciesUuid].find(
            (assetInfo) => assetInfo.anomalyUuids && assetInfo.anomalyUuids.includes(anomalyUuid),
        );

        // Only use electrical info if we found the exact asset region containing this anomaly
        if (matchingAssetInfo) {
            inverterId = matchingAssetInfo.inverterId;
            combinerId = matchingAssetInfo.combinerId;
            stackId = matchingAssetInfo.stackId;
        }
    }

    // Determine if electrical encoding is available
    const hasInverterEncoding = !!inverterId;
    const hasCombinerEncoding = !!combinerId;

    return {
        anomalyCode,
        speciesUuid,
        inverterId,
        combinerId,
        stackId,
        hasInverterEncoding,
        hasCombinerEncoding,
    };
};

/**
 * Finds all anomalies with the same anomaly type and inverter ID
 */
export const findAnomaliesByInverter = (
    allAnomalies: InspectionAnomaly[],
    anomalyCode: string,
    inverterId: string,
): InspectionAnomaly[] => {
    if (!inverterId || !anomalyCode || !allAnomalies?.length) {
        return [];
    }

    return allAnomalies.filter((anomaly) => {
        // Check anomaly code match
        const code =
            anomaly?.anomalyDefinition?.species?.ir_signal || anomaly?.anomalyDefinition?.species?.rgb_signal || '';
        const baseCode = code.split('-')[0];
        const targetBaseCode = anomalyCode.split('-')[0];

        // Check inverter ID match
        const aInverterId = anomaly?.aoiAssetRegions?.[0]?.assetRegion?.nom_properties?.nom_inverter;

        return baseCode === targetBaseCode && inverterId === aInverterId;
    });
};

/**
 * Finds all anomalies with the same anomaly type and combiner ID
 */
export const findAnomaliesByCombiner = (
    allAnomalies: InspectionAnomaly[],
    anomalyCode: string,
    combinerId: string,
    inverterId?: string, // Add inverter ID parameter
): InspectionAnomaly[] => {
    if (!combinerId || !anomalyCode || !allAnomalies?.length) {
        return [];
    }

    return allAnomalies.filter((anomaly) => {
        // Check anomaly code match
        const code =
            anomaly?.anomalyDefinition?.species?.ir_signal || anomaly?.anomalyDefinition?.species?.rgb_signal || '';
        const baseCode = code.split('-')[0];
        const targetBaseCode = anomalyCode.split('-')[0];

        // Check combiner ID match
        const aCombinerId = anomaly?.aoiAssetRegions?.[0]?.assetRegion?.nom_properties?.nom_combiner;

        // Check inverter ID match if provided
        const aInverterId = anomaly?.aoiAssetRegions?.[0]?.assetRegion?.nom_properties?.nom_inverter;
        const inverterMatches = !inverterId || inverterId === aInverterId;

        return baseCode === targetBaseCode && combinerId === aCombinerId && inverterMatches;
    });
};

/**
 * Finds all anomalies with the same anomaly type and stack ID
 * Uses speciesAssetMap when available for more accurate electrical component relationships
 */
export const findAnomaliesByStack = (
    allAnomalies: InspectionAnomaly[],
    anomalyCode: string,
    stackId: string,
    speciesAssetMap?: Record<
        string,
        Array<{ assetRegionUUID: string; combinerId?: string; inverterId?: string; stackId?: string }>
    >,
): InspectionAnomaly[] => {
    if (!stackId || !anomalyCode || !allAnomalies?.length) {
        return [];
    }

    return allAnomalies.filter((anomaly) => {
        // Check anomaly code match
        const code =
            anomaly?.anomalyDefinition?.species?.ir_signal || anomaly?.anomalyDefinition?.species?.rgb_signal || '';
        const baseCode = code.split('-')[0];
        const targetBaseCode = anomalyCode.split('-')[0];

        // Get stack ID from speciesAssetMap
        let aStackId;

        if (speciesAssetMap && anomaly.anomalyDefinition?.species?.uuid) {
            const speciesUuid = anomaly.anomalyDefinition.species.uuid;

            // Use the first asset info if available
            if (speciesAssetMap[speciesUuid] && speciesAssetMap[speciesUuid].length > 0) {
                aStackId = speciesAssetMap[speciesUuid][0].stackId;
            }
        }

        return baseCode === targetBaseCode && stackId === aStackId;
    });
};

/**
 * Counts modules affected by the same anomaly type grouped by electrical component
 * Uses only the species UUID and speciesAssetMap to determine counts
 * Works with the accumulated asset regions in speciesAssetMap
 */
export const countModulesByScope = (
    selectedAnomaly: InspectionAnomaly,
    speciesAssetMap: Record<
        string,
        Array<{
            assetRegionUUID: string;
            combinerId?: string;
            inverterId?: string;
            stackId?: string;
            anomalyUuids?: string[];
        }>
    >,
    electricalInfo: ReturnType<typeof getElectricalInfoFromAnomaly>,
): {
    inverter?: number;
    combiner?: number;
    stack?: number;
} => {
    // Validate required inputs
    if (!selectedAnomaly || !speciesAssetMap || !electricalInfo) {
        return {};
    }

    const speciesUuid = selectedAnomaly?.anomalyDefinition?.species?.uuid;

    if (!speciesUuid || !speciesAssetMap[speciesUuid] || !speciesAssetMap[speciesUuid].length) {
        return {};
    }

    // Use the provided electrical component values
    const targetInverterId = electricalInfo.inverterId;
    const targetCombinerId = electricalInfo.combinerId;
    const targetStackId = electricalInfo.stackId;

    // If no electrical components are available, return empty counts
    if (!targetInverterId && !targetCombinerId && !targetStackId) {
        return {};
    }

    // Initialize counts
    const counts: {
        inverter?: number;
        combiner?: number;
        stack?: number;
    } = {};

    // Count modules by electrical component
    let inverterCount = 0;
    let combinerCount = 0;
    let stackCount = 0;

    // Only count modules with the same species as the selected anomaly
    const assetInfoArray = speciesAssetMap[speciesUuid];

    // For each asset region with this species, count if it matches the electrical components
    assetInfoArray.forEach((assetInfo) => {
        // Count by inverter
        if (targetInverterId && assetInfo.inverterId === targetInverterId) {
            inverterCount++;
        }

        // Count by combiner AND inverter
        if (
            targetCombinerId &&
            targetInverterId &&
            assetInfo.combinerId === targetCombinerId &&
            assetInfo.inverterId === targetInverterId
        ) {
            combinerCount++;
        }

        // Count by stack
        if (targetStackId && assetInfo.stackId === targetStackId) {
            stackCount++;
        }
    });

    // Set the counts in the result
    if (targetInverterId) {
        counts.inverter = inverterCount;
    }

    if (targetCombinerId && targetInverterId) {
        counts.combiner = combinerCount;
    }

    if (targetStackId) {
        counts.stack = stackCount;
    }

    return counts;
};

/**
 * Get anomaly UUIDs for batch update based on scope
 * Uses enhanced speciesAssetMap with anomaly UUIDs for direct lookup without filtering allAnomalies
 * ASSUMPTION: speciesUuid uniquely identifies the anomaly type (base code).
 */
export const getAnomalyUuidsByScope = (
    selectedAnomaly: InspectionAnomaly,
    scope: UpdateScope,
    speciesAssetMap?: Record<
        string,
        Array<{
            assetRegionUUID: string;
            combinerId?: string;
            inverterId?: string;
            stackId?: string;
            anomalyUuids?: string[];
        }>
    >,
): string[] => {
    // For single scope, just return the selected anomaly UUID
    if (!selectedAnomaly || scope === UpdateScope.SINGLE) {
        return selectedAnomaly ? [selectedAnomaly.uuid] : [];
    }

    // Create a Set to collect unique UUIDs
    const uniqueUuids = new Set<string>();

    // Always include the selected anomaly's UUID
    if (selectedAnomaly?.uuid) {
        uniqueUuids.add(selectedAnomaly.uuid);
    }

    // Get species UUID directly
    const speciesUuid = selectedAnomaly?.anomalyDefinition?.species?.uuid;

    // Use optional chaining for safer access
    const targetSpeciesAssetInfoArray = speciesUuid ? speciesAssetMap?.[speciesUuid] : undefined;

    // If the selected anomaly's species isn't in the map, or the array is empty, we can't determine scope
    if (!targetSpeciesAssetInfoArray || targetSpeciesAssetInfoArray.length === 0) {
        return Array.from(uniqueUuids);
    }

    // Find the specific asset info that contains this anomaly
    const anomalyUuid = selectedAnomaly.uuid;
    const matchingAssetInfo = targetSpeciesAssetInfoArray.find(
        (assetInfo) => assetInfo.anomalyUuids && assetInfo.anomalyUuids.includes(anomalyUuid),
    );

    // Only proceed if we found the exact asset info for this anomaly
    if (!matchingAssetInfo) {
        return Array.from(uniqueUuids);
    }

    // Use the electrical component values from the exact asset region containing this anomaly
    const targetInverterId = matchingAssetInfo.inverterId;
    const targetCombinerId = matchingAssetInfo.combinerId;
    const targetStackId = matchingAssetInfo.stackId;

    // Validate if required components exist for the scope
    const canProceed =
        (scope === UpdateScope.INVERTER && targetInverterId) ||
        (scope === UpdateScope.COMBINER && targetCombinerId && targetInverterId) ||
        (scope === UpdateScope.STACK && targetStackId);

    if (!canProceed) {
        return Array.from(uniqueUuids);
    }

    // Iterate ONLY through the asset regions associated with the target speciesUuid
    targetSpeciesAssetInfoArray.forEach((info) => {
        let match = false;

        // Check if the current asset region matches the target electrical scope
        switch (scope) {
            case UpdateScope.INVERTER:
                match = info.inverterId === targetInverterId;
                break;
            case UpdateScope.COMBINER:
                match = info.combinerId === targetCombinerId && info.inverterId === targetInverterId;
                break;
            case UpdateScope.STACK:
                match = info.stackId === targetStackId;
                break;
            default:
                match = false;
                break;
        }

        // If it matches the scope and has UUIDs, add them to our uniqueUuids Set
        if (match && info.anomalyUuids) {
            info.anomalyUuids.forEach((uuid) => uniqueUuids.add(uuid));
        }
    });

    // Return the array of unique UUIDs
    return Array.from(uniqueUuids);
};

/**
 * Returns a human-readable component name for the given update scope
 */
export const getScopeComponentName = (scope: UpdateScope): string => {
    switch (scope) {
        case UpdateScope.INVERTER:
            return 'Inverter';
        case UpdateScope.COMBINER:
            return 'Combiner';
        case UpdateScope.STACK:
            return 'Stack';
        default:
            return '';
    }
};

/**
 * Returns the module count for a specific scope from the counts object
 */
export const getModuleCountForScope = (
    scope: UpdateScope,
    counts: {
        inverter?: number;
        combiner?: number;
        stack?: number;
    },
): number => {
    switch (scope) {
        case UpdateScope.INVERTER:
            return counts.inverter || 0;
        case UpdateScope.COMBINER:
            return counts.combiner || 0;
        case UpdateScope.STACK:
            return counts.stack || 0;
        case UpdateScope.SINGLE:
            return 1;
        default:
            return 0;
    }
};
