import React from 'react';

import { IconProps } from './icon_definition';

export const SankeyIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M2 2.5C2 2.22386 2.22386 2 2.5 2H3V6H2.5C2.22386 6 2 5.77614 2 5.5V2.5Z" fill={color ?? '#222'} />
            <path d="M2 7.5C2 7.22386 2.22386 7 2.5 7H3V9H2.5C2.22386 9 2 8.77614 2 8.5V7.5Z" fill={color ?? '#222'} />
            <path
                d="M2 10.5C2 10.2239 2.22386 10 2.5 10H3V14H2.5C2.22386 14 2 13.7761 2 13.5V10.5Z"
                fill={color ?? '#222'}
            />
            <path
                d="M14 2H14.5C14.7761 2 15 2.22386 15 2.5V7.5C15 7.77614 14.7761 8 14.5 8H14V2Z"
                fill={color ?? '#222'}
            />
            <path
                d="M14 9H14.5C14.7761 9 15 9.22386 15 9.5V11.5C15 11.7761 14.7761 12 14.5 12H14V9Z"
                fill={color ?? '#222'}
            />
            <path
                d="M14 13H14.5C14.7761 13 15 13.2239 15 13.5V13.5C15 13.7761 14.7761 14 14.5 14H14V13Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 7V9C5.11373 9 5.95182 8.93875 6.6507 8.84656C6.42352 8.50615 6.19145 8.19967 5.94234 7.93394C5.37234 7.32594 4.77627 7 4 7ZM7.724 8.66667C7.74084 8.69369 7.75763 8.72081 7.77437 8.74801C7.93614 9.01091 8.0934 9.28183 8.24908 9.55556C8.29915 9.64359 8.34906 9.73192 8.3989 9.82037L8.5 10C8.97893 10.8514 9.45787 11.7029 10.0236 12.4C10.0245 12.4011 10.0254 12.4022 10.0263 12.4034C10.5758 13.0796 11.2072 13.6103 12 13.854C12.3075 13.9486 12.6393 14 13 14V13C12.9893 13 12.9787 12.9999 12.9681 12.9998C12.9486 12.9996 12.9291 12.9992 12.9098 12.9985C12.1847 12.9745 11.5779 12.661 11.0504 12.1538C10.3568 11.487 9.8001 10.4854 9.29125 9.36693C9.28616 9.35574 9.28108 9.34454 9.276 9.33333C9.26405 9.30697 9.25213 9.28055 9.24024 9.25406C9.11731 8.98036 8.99703 8.70018 8.87813 8.41667C8.75104 8.11366 8.62552 7.80683 8.5 7.5C8.21249 6.7972 7.92498 6.0944 7.61869 5.4375C7.6105 5.41992 7.60229 5.40238 7.59406 5.38486C6.8999 3.90664 6.10736 2.66889 5 2.20069C4.69249 2.07067 4.3607 2 4 2V6C4.76526 6 5.40038 6.23136 5.94963 6.61539C6.65018 7.1052 7.21102 7.84339 7.724 8.66667ZM8.35548 4.66039C8.73793 5.4403 9.07818 6.27215 9.40025 7.05953L9.42555 7.12137C9.58034 7.49976 9.7314 7.86813 9.88184 8.22252C10.6727 8.09312 11.6268 8 13 8V2C10.75 2 9.625 3.25 8.5 4.5C8.45185 4.5535 8.40371 4.60699 8.35548 4.66039ZM10.2972 9.16041C10.5696 9.74374 10.8493 10.2705 11.1544 10.7179C11.7783 11.633 12.3587 12 13 12V9C11.8584 9 11.0065 9.06435 10.2972 9.16041ZM9.03995 12.764C8.51049 12.0587 8.0647 11.266 7.65984 10.5461L7.62842 10.4903C7.4867 10.2383 7.3494 9.9949 7.21396 9.7615C6.40431 9.89915 5.42907 10 4 10V14C6.25 14 7.375 13.5 8.5 13L8.50001 13C8.67849 12.9207 8.85698 12.8413 9.03995 12.764Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
