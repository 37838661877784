import { useCallback, useEffect, useMemo, useState } from 'react';

import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { MapAnomalies } from 'components/Sites/types';
import { transformRawAnomalies } from 'lib/helpers';
import createCustomHook from 'lib/helpers/hookFactory';
import { GET_REPORT_ANOMALIES, GET_INSPECTION_MODULE_COUNT, GET_INSPECTION_OBSERVATION_AOIS } from 'lib/queries';

import { useAnomalyMapFilters } from '../useAnomalyMapFilters';
import { useAnomalyOverviewFilters } from '../useAnomalyOverviewFilters';

const filterMapAnomaliesByKeyObservations = (anomalyMap: MapAnomalies, observationIds: string[]) => {
    if (observationIds.length === 0) {
        // Only show anomalies that have key observation relationships
        return pickBy(anomalyMap, (anomalyEntry) => anomalyEntry.observationIds.length > 0);
    }

    const observationIDsSet = new Set(observationIds);

    return pickBy(
        anomalyMap,
        (anomalyEntry) =>
            anomalyEntry.observationIds.length > 0 &&
            some(anomalyEntry.observationIds, (observationID) => observationIDsSet.has(observationID)),
    );
};

const filterMapAnomaliesByAnomalyDetails = (
    anomalyMap: MapAnomalies,
    filters: { ir: string[]; rgb: string[]; remediationFilters: string[]; statusIds: string[] },
) => {
    const filterSets = Object.fromEntries(Object.entries(filters).map(([key, value]) => [key, new Set(value)]));

    return pickBy(anomalyMap, (anomalyEntry) =>
        some(
            anomalyEntry.species,
            ({ irFilter, rgbFilter, priority, statusId }) =>
                (filterSets.ir.size === 0 || filterSets.ir.has(irFilter)) &&
                (filterSets.rgb.size === 0 || filterSets.rgb.has(rgbFilter)) &&
                (filterSets.remediationFilters.size === 0 || filterSets.remediationFilters.has(priority)) &&
                (filterSets.statusIds.size === 0 || filterSets.statusIds.has(statusId)),
        ),
    );
};

const ValueProvider = () => {
    const { inspectionId } = useParams();
    // Anomalies in the inspection which has the ID in the current params
    // Unprocessed
    const [currentInspectionAnomalies, setCurrentInspectionAnomalies] = useState<any>();
    // The processed "Map Anomalies" which are for the current inspection (as determined by useParams)
    const [currentInspectionMapAnomalies, setCurrentInspectionMapAnomalies] = useState<MapAnomalies>({});
    // The anomalies which are active on the map, this can be shoehorned in by other components (i.e. for differentials)
    const [activeMapAnomalies, setActiveMapAnomalies] = useState<MapAnomalies>({});
    // The filtered version of the active map anomalies, this is what is displayed on the map
    const [filteredMapAnomalies, setFilteredMapAnomalies] = useState<any>({});
    const [moduleCount, setModuleCount] = useState<number>(0);
    // Map of species UUIDs to asset region UUIDs with electrical info
    const [speciesAssetMap, setSpeciesAssetMap] = useState<
        Record<string, Array<{ assetRegionUUID: string; combinerId?: string; inverterId?: string; stackId?: string }>>
    >({});

    const {
        superAnalysisTaskUUIDs,
        fetching: fetchingAnomalyOverview,
        anomalyDefinitions,
    } = useAnomalyOverviewFilters();
    const { filter: filters, showObservationsPanel: showKeyObservationsFilters } = useAnomalyMapFilters();

    const [{ data: activeReportAnomalies, fetching: fetchingReportAnomalies }] = useQuery({
        query: GET_REPORT_ANOMALIES,
        variables: {
            superAnalysisTaskUUIDs,
        },
        pause: fetchingAnomalyOverview,
    });

    const [{ data: moduleCountQueryData }] = useQuery({
        query: GET_INSPECTION_MODULE_COUNT,
        variables: {
            superReportTaskUUID: inspectionId,
        },
    });

    const [{ data: reportObservationAOIs }] = useQuery({
        query: GET_INSPECTION_OBSERVATION_AOIS,
        variables: {
            superReportTaskUUID: inspectionId,
        },
    });

    // Create the mapping from species UUID to asset regions with electrical info
    const buildSpeciesAssetMap = useCallback((mapAnomalies: MapAnomalies) => {
        const mapping: Record<
            string,
            Array<{
                assetRegionUUID: string;
                combinerId?: string;
                inverterId?: string;
                stackId?: string;
                anomalyUuids?: string[];
            }>
        > = {};

        Object.entries(mapAnomalies).forEach(([assetRegionUUID, anomalyData]) => {
            const { combinerId, inverterId, stackId, species, anomalyUuids } = anomalyData;

            // For each species in this asset region, add to mapping array
            species.forEach((speciesItem) => {
                const assetInfo = {
                    assetRegionUUID,
                    combinerId,
                    inverterId,
                    stackId,
                    anomalyUuids: anomalyUuids || [],
                };

                // If this species already has entries, append to the array
                if (mapping[speciesItem.uuid]) {
                    mapping[speciesItem.uuid].push(assetInfo);
                } else {
                    // Otherwise create a new array with this entry
                    mapping[speciesItem.uuid] = [assetInfo];
                }
            });
        });

        return mapping;
    }, []);

    useEffect(() => {
        if (moduleCountQueryData) {
            setModuleCount(moduleCountQueryData?.assetRegionsAggregate?.[0]?.activeModuleCount ?? 0);
        }
    }, [moduleCountQueryData]);

    useEffect(() => {
        if (activeReportAnomalies) {
            setCurrentInspectionAnomalies(activeReportAnomalies);
        }
    }, [activeReportAnomalies]);

    useEffect(() => {
        if (currentInspectionAnomalies && reportObservationAOIs && anomalyDefinitions) {
            const processedAnomalies = transformRawAnomalies(
                currentInspectionAnomalies,
                reportObservationAOIs,
                anomalyDefinitions,
            );

            setCurrentInspectionMapAnomalies(processedAnomalies);

            // Build the species to asset region mapping with electrical info
            setSpeciesAssetMap(buildSpeciesAssetMap(processedAnomalies));
        }
    }, [currentInspectionAnomalies, reportObservationAOIs, anomalyDefinitions, buildSpeciesAssetMap]);

    useEffect(() => {
        setActiveMapAnomalies(currentInspectionMapAnomalies);
    }, [currentInspectionMapAnomalies]);

    useEffect(() => {
        if (
            !showKeyObservationsFilters &&
            filters.ir.length === 0 &&
            filters.rgb.length === 0 &&
            filters.remediationFilters.length === 0 &&
            filters.statusIds.length === 0
        ) {
            setFilteredMapAnomalies(activeMapAnomalies);
        } else {
            if (showKeyObservationsFilters) {
                setFilteredMapAnomalies(
                    filterMapAnomaliesByKeyObservations(activeMapAnomalies, filters.observationIds),
                );
            } else {
                setFilteredMapAnomalies(filterMapAnomaliesByAnomalyDetails(activeMapAnomalies, filters));
            }
        }
    }, [activeMapAnomalies, filters, showKeyObservationsFilters]);

    const value = useMemo(
        () => ({
            activeMapAnomalies,
            currentInspectionId: inspectionId,
            currentInspectionMapAnomalies,
            currentInspectionAnomalies,
            filteredMapAnomalies,
            fetchingReportAnomalies,
            moduleCount,
            setMapAnomalies: setActiveMapAnomalies,
            fetchingAnomalyOverview,
            speciesAssetMap,
        }),
        [
            activeMapAnomalies,
            inspectionId,
            currentInspectionMapAnomalies,
            currentInspectionAnomalies,
            filteredMapAnomalies,
            fetchingReportAnomalies,
            moduleCount,
            setActiveMapAnomalies,
            fetchingAnomalyOverview,
            speciesAssetMap,
        ],
    );

    return value;
};

const { Provider: AnomalyMapDataProvider, useCustomHook: useAnomalyMapData } = createCustomHook<any>({
    name: 'useAnomalyMapData',
    callback: ValueProvider,
});

export { AnomalyMapDataProvider, useAnomalyMapData };
