import { AllIcon } from './AllIcon';
import { ArchiveIcon } from './ArchiveIcon';
import { ArrowCollapseIcon } from './ArrowCollapseIcon';
import { ArrowDownIcon } from './ArrowDownIcon';
import { ArrowExpandIcon } from './ArrowExpandIcon';
import { ArrowLeftIcon } from './ArrowLeftIcon';
import { ArrowRightIcon } from './ArrowRightIcon';
import { ArrowUpIcon } from './ArrowUpIcon';
import { BarcodeIcon } from './BarcodeIcon';
import { CalendarCompleteIcon } from './CalendarCompleteIcon';
import { CalendarIcon } from './CalendarIcon';
import { ChangedIcon } from './ChangedIcon';
import { CheckboxIcon } from './CheckboxIcon';
import { CheckmarkIcon } from './CheckmarkIcon';
import { ChevronDownIcon } from './ChevronDownIcon';
import { ChevronLeftIcon } from './ChevronLeftIcon';
import { ChevronRightIcon } from './ChevronRightIcon';
import { ChevronUpIcon } from './ChevronUpIcon';
import { CloseIcon } from './CloseIcon';
import { CombinerIcon } from './CombinerIcon';
import { CompareIcon } from './CompareIcon';
import { CopiedIcon } from './CopiedIcon';
import { CopyIcon } from './CopyIcon';
import { CurrentLocationIcon } from './CurrentLocation';
import { DashIcon } from './DashIcon';
import { DataTableIcon } from './DataTableIcon';
import { DiagnosticIcon } from './DiagnosticIcon';
import { DiagnosticPlusIcon } from './DiagnosticPlusIcon';
import { DocumentIcon } from './DocumentIcon';
import { DownloadIcon } from './DownloadIcon';
import { DroneIcon } from './DroneIcon';
import { DropdownIcon } from './DropdownIcon';
import { EditIcon } from './EditIcon';
import { ErrorIcon } from './ErrorIcon';
import { ExternalLinkIcon } from './ExternalLinkIcon';
import { FilesIcon } from './FilesIcon';
import { FilterIcon } from './FilterIcon';
import { FindingsIcon } from './FindingsIcon';
import { GlobeIcon } from './GlobeIcon';
import { Google } from './Google';
import { GroupingIcon } from './GroupingIcon';
import { HeatmapIcon } from './Heatmap';
import { HideIcon } from './HideIcon';
import { HorizontalGripperIcon } from './HorizontalGripper';
import { ImageIcon } from './ImageIcon';
import { InfoIcon } from './InfoIcon';
import { InverterIcon } from './InverterIcon';
import { LaptopIcon } from './LaptopIcon';
import { LayersIcon } from './LayersIcon';
import { LocationIcon } from './LocationIcon';
import { LockIcon } from './LockIcon';
import { LogoutIcon } from './LogoutIcon';
import { MailIcon } from './MailIcon';
import { MapIcon } from './MapIcon';
import { Microsoft } from './Microsoft';
import { ModuleAdvancedIcon } from './ModuleAdvancedIcon';
import { ModuleBasicIcon } from './ModuleBasicIcon';
import { ModuleIcon } from './ModuleIcon';
import { MoreIcon } from './MoreIcon';
import { NeedInspectionIcon } from './NeedInspectionIcon';
import { NextLineIcon } from './NextLineIcon';
import { NotificationIcon } from './NotificationIcon';
import { PastDueIcon } from './PastDueIcon';
import { PendingIcon } from './PendingIcon';
import { PlaneIcon } from './PlaneIcon';
import { PlusIcon } from './PlusIcon';
import { PowerIcon } from './PowerIcon';
import { Priority1Icon } from './Priority1Icon';
import { Priority2Icon } from './Priority2Icon';
import { Priority3Icon } from './Priority3Icon';
import { SankeyIcon } from './SankeyIcon';
import { SatelliteIcon } from './SatelliteIcon';
import { SearchIcon } from './SearchIcon';
import { SettingsIcon } from './Settings';
import { ShareIcon } from './ShareIcon';
import { ShowAllIcon } from './ShowAllIcon';
import { ShowEntireIcon } from './ShowEntireIcon';
import { ShowIcon } from './ShowIcon';
import { SingleUserIcon } from './SingleUserIcon';
import { SolarSitesIcon } from './SolarSitesIcon';
import { SortArrowIcon } from './SortArrow';
import { SortColumnsIcon } from './SortColumns';
import { SortRowsIcon } from './SortRows';
import { StackIcon } from './StackIcon';
import { StringLevelIcon } from './StringLevelIcon';
import { SupportIcon } from './SupportIcon';
import { SuspendIcon } from './SuspendIcon';
import { SwitchIcon } from './SwitchIcon';
import { TagIcon } from './TagIcon';
import { TeamIcon } from './TeamIcon';
import { UndoIcon } from './UndoIcon';
import { UpgradeIcon } from './UpgradeIcon';
import { UsersIcon } from './UsersIcon';
import { VerticalGripperIcon } from './VerticalGripper';
import { WebhooksIcon } from './WebhooksIcon';
import { ZoomOnSelectedIcon } from './ZoomOnSelectedIcon';

export type IconProps = React.SVGAttributes<SVGSVGElement>;

export const icons = {
    all: AllIcon,
    archive: ArchiveIcon,
    arrowCollapse: ArrowCollapseIcon,
    arrowExpand: ArrowExpandIcon,
    arrowDown: ArrowDownIcon,
    arrowLeft: ArrowLeftIcon,
    arrowRight: ArrowRightIcon,
    arrowUp: ArrowUpIcon,
    barcode: BarcodeIcon,
    calendar: CalendarIcon,
    calendarComplete: CalendarCompleteIcon,
    changed: ChangedIcon,
    checkbox: CheckboxIcon,
    checkmark: CheckmarkIcon,
    chevronDown: ChevronDownIcon,
    chevronLeft: ChevronLeftIcon,
    chevronRight: ChevronRightIcon,
    chevronUp: ChevronUpIcon,
    close: CloseIcon,
    combiner: CombinerIcon,
    compare: CompareIcon,
    copied: CopiedIcon,
    copy: CopyIcon,
    currentLocation: CurrentLocationIcon,
    dash: DashIcon,
    dataTable: DataTableIcon,
    diagnostic: DiagnosticIcon,
    diagnosticPlus: DiagnosticPlusIcon,
    document: DocumentIcon,
    download: DownloadIcon,
    dropdown: DropdownIcon,
    drone: DroneIcon,
    edit: EditIcon,
    error: ErrorIcon,
    externalLink: ExternalLinkIcon,
    files: FilesIcon,
    filter: FilterIcon,
    findings: FindingsIcon,
    globe: GlobeIcon,
    google: Google,
    grouping: GroupingIcon,
    heatmap: HeatmapIcon,
    hide: HideIcon,
    horizontalGripper: HorizontalGripperIcon,
    info: InfoIcon,
    image: ImageIcon,
    inverter: InverterIcon,
    laptop: LaptopIcon,
    layers: LayersIcon,
    location: LocationIcon,
    lock: LockIcon,
    logout: LogoutIcon,
    mail: MailIcon,
    map: MapIcon,
    microsoft: Microsoft,
    moduleAdvanced: ModuleAdvancedIcon,
    moduleBasic: ModuleBasicIcon,
    module: ModuleIcon,
    more: MoreIcon,
    nextLine: NextLineIcon,
    needInspection: NeedInspectionIcon,
    notification: NotificationIcon,
    pastDue: PastDueIcon,
    pending: PendingIcon,
    plane: PlaneIcon,
    plus: PlusIcon,
    power: PowerIcon,
    priority1: Priority1Icon,
    priority2: Priority2Icon,
    priority3: Priority3Icon,
    sankey: SankeyIcon,
    share: ShareIcon,
    satellite: SatelliteIcon,
    search: SearchIcon,
    settings: SettingsIcon,
    show: ShowIcon,
    showAll: ShowAllIcon,
    showEntire: ShowEntireIcon,
    solarSites: SolarSitesIcon,
    sortArrow: SortArrowIcon,
    sortColumns: SortColumnsIcon,
    sortRows: SortRowsIcon,
    stack: StackIcon,
    stringLevel: StringLevelIcon,
    support: SupportIcon,
    suspend: SuspendIcon,
    switch: SwitchIcon,
    tag: TagIcon,
    team: TeamIcon,
    undo: UndoIcon,
    upgrade: UpgradeIcon,
    user: SingleUserIcon,
    users: UsersIcon,
    verticalGripper: VerticalGripperIcon,
    webhook: WebhooksIcon,
    zoomOnSelected: ZoomOnSelectedIcon,
};

export type IconNames = keyof typeof icons;
export const iconOptions = Object.keys(icons) as Array<IconNames>;
