import React from 'react';

import { IconProps } from './icon_definition';

export const ErrorIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V8.5C8.5 8.77614 8.27614 9 8 9C7.72386 9 7.5 8.77614 7.5 8.5V5.5C7.5 5.22386 7.72386 5 8 5ZM8.5 10.5C8.5 10.7761 8.27614 11 8 11C7.72386 11 7.5 10.7761 7.5 10.5C7.5 10.2239 7.72386 10 8 10C8.27614 10 8.5 10.2239 8.5 10.5Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
