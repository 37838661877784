import React from 'react';

import { IconProps } from './icon_definition';

export const MailIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M12.5 4.5L3.5 4.5C2.94771 4.5 2.5 4.94772 2.5 5.5L2.5 10.5C2.5 11.0523 2.94771 11.5 3.5 11.5L12.5 11.5C13.0523 11.5 13.5 11.0523 13.5 10.5L13.5 5.5C13.5 4.94772 13.0523 4.5 12.5 4.5Z"
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
            <path d="M8 8.5L3 5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M9.5 7.5L13 11" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13 5L8 8.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M3 11L6.5 7.5" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
