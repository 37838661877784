import React from 'react';

import { IconProps } from './icon_definition';

export const StackIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 2H2V12H8V2ZM2 1C1.44772 1 1 1.44772 1 2V12C1 12.5523 1.44772 13 2 13H8C8.55228 13 9 12.5523 9 12V2C9 1.44772 8.55228 1 8 1H2ZM6 2.74999C6.13807 2.74999 6.25 2.86192 6.25 2.99999V3.75H6.99999C7.13806 3.75 7.24999 3.86193 7.24999 4C7.24999 4.13807 7.13806 4.25 6.99999 4.25H6.25V5.75H7C7.13807 5.75 7.25 5.86193 7.25 6C7.25 6.13807 7.13807 6.25 7 6.25H6.25V7.75H6.99999C7.13806 7.75 7.24999 7.86193 7.24999 8C7.24999 8.13807 7.13806 8.25 6.99999 8.25H6.25V9.75H7.00001C7.13808 9.75 7.25001 9.86193 7.25001 10C7.25001 10.1381 7.13808 10.25 7.00001 10.25H6.25V11C6.25 11.1381 6.13807 11.25 6 11.25C5.86193 11.25 5.75 11.1381 5.75 11V10.25H4.25V11C4.25 11.1381 4.13807 11.25 4 11.25C3.86193 11.25 3.75 11.1381 3.75 11V10.25H3.00001C2.86193 10.25 2.75001 10.1381 2.75001 10C2.75001 9.86193 2.86193 9.75 3.00001 9.75H3.75V8.25H2.99999C2.86192 8.25 2.74999 8.13807 2.74999 8C2.74999 7.86193 2.86192 7.75 2.99999 7.75H3.75V6.25H3C2.86193 6.25 2.75 6.13807 2.75 6C2.75 5.86193 2.86193 5.75 3 5.75H3.75V4.25H2.99999C2.86192 4.25 2.74999 4.13807 2.74999 4C2.74999 3.86193 2.86192 3.75 2.99999 3.75H3.75V3.00001C3.75 2.86194 3.86193 2.75001 4 2.75001C4.13807 2.75001 4.25 2.86194 4.25 3.00001V3.75H5.75V2.99999C5.75 2.86192 5.86193 2.74999 6 2.74999ZM4.25 9.75H5.75V8.25H4.25V9.75ZM5.75 6.25V7.75H4.25V6.25H5.75ZM4.25 5.75H5.75V4.25H4.25V5.75Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.13379 13.5C4.30669 13.7989 4.62987 14 5.00001 14H11C11.5523 14 12 13.5523 12 13V3C12 2.44772 11.5523 2 11 2H9.50001V3H11V13H9.11806C8.8434 13.3069 8.44426 13.5 8.00001 13.5H4.13379ZM9.50001 11.25H10C10.1381 11.25 10.25 11.1381 10.25 11C10.25 10.8619 10.1381 10.75 10 10.75H9.50001V11.25ZM9.50001 9.25H10C10.1381 9.25 10.25 9.13807 10.25 9C10.25 8.86193 10.1381 8.75 10 8.75H9.50001V9.25ZM9.50001 7.25H10C10.1381 7.25 10.25 7.13807 10.25 7C10.25 6.86192 10.1381 6.75 10 6.75H9.50001V7.25ZM9.50001 5.25001H10C10.1381 5.25001 10.25 5.13808 10.25 5.00001C10.25 4.86194 10.1381 4.75001 10 4.75001H9.50001V5.25001Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.13379 14.5C7.30669 14.7989 7.62987 15 8.00001 15H14C14.5523 15 15 14.5523 15 14V4C15 3.44772 14.5523 3 14 3H12.5V4L14 4V14H12.1181C11.8434 14.3069 11.4443 14.5 11 14.5H7.13379ZM12.5 12.25H13C13.1381 12.25 13.25 12.1381 13.25 12C13.25 11.8619 13.1381 11.75 13 11.75H12.5V12.25ZM12.5 10.25H13C13.1381 10.25 13.25 10.1381 13.25 10C13.25 9.86193 13.1381 9.75 13 9.75H12.5V10.25ZM12.5 8.25H13C13.1381 8.25 13.25 8.13807 13.25 8C13.25 7.86193 13.1381 7.75 13 7.75H12.5V8.25ZM12.5 6.25H13C13.1381 6.25 13.25 6.13807 13.25 6C13.25 5.86193 13.1381 5.75 13 5.75H12.5V6.25Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
