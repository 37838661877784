import React from 'react';

import { IconProps } from './icon_definition';

export const UndoIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M5 6.5L10 6.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path
                d="M10 6.5C10 6.5 12 6.5 12 8.5C12 10.5 10 10.5 10 10.5"
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
            <path d="M4 6.5L6 8.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M4 6.5L6 4.5" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
