import React from 'react';

import { IconProps } from './icon_definition';

export const CopyIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="6.5" y="6.5" width="6" height="6" rx="0.5" stroke={color ?? '#222'} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 4H9V6H10V4C10 3.44772 9.55228 3 9 3H4C3.44772 3 3 3.44772 3 4V9C3 9.55228 3.44772 10 4 10H6V9H4L4 4Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
