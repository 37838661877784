import React from 'react';

import { IconProps } from './icon_definition';

export const DataTableIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="2.5" y="2.5" width="11" height="11" rx="0.5" stroke={color ?? '#222'} />
            <rect x="2" y="4" width="12" height="1" rx="0.5" fill={color ?? '#222'} />
            <rect x="6" y="4" width="10" height="1" rx="0.5" transform="rotate(90 6 4)" fill={color ?? '#222'} />
            <rect x="10" y="4" width="10" height="1" rx="0.5" transform="rotate(90 10 4)" fill={color ?? '#222'} />
        </svg>
    );
};
