import React from 'react';

import { IconProps } from './icon_definition';

export const ShowEntireIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.14645 5.85355C9.34171 6.04882 9.65829 6.04882 9.85355 5.85355C10.0488 5.65829 10.0488 5.34171 9.85355 5.14645L8.35355 3.64645C8.15829 3.45118 7.84171 3.45118 7.64645 3.64645L6.14645 5.14645C5.95118 5.34171 5.95118 5.65829 6.14645 5.85355C6.34171 6.04882 6.65829 6.04882 6.85355 5.85355L8 4.70711L9.14645 5.85355Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.85355 10.1464C6.65829 9.95118 6.34171 9.95118 6.14645 10.1464C5.95118 10.3417 5.95118 10.6583 6.14645 10.8536L7.64645 12.3536C7.84171 12.5488 8.15829 12.5488 8.35355 12.3536L9.85355 10.8536C10.0488 10.6583 10.0488 10.3417 9.85355 10.1464C9.65829 9.95118 9.34171 9.95118 9.14645 10.1464L8 11.2929L6.85355 10.1464Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 5H3C2.44772 5 2 5.44772 2 6V10C2 10.5523 2.44772 11 3 11H5V10H3L3 6H5V5ZM11 10H13V8V6H11V5H13C13.5523 5 14 5.44772 14 6V8V10C14 10.5523 13.5523 11 13 11H11V10Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
