import React from 'react';

import { IconProps } from './icon_definition';

export const TeamIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M11.9427 11.003C12.02 11.5499 11.5527 12 11.0005 12C10.0558 12 8.68426 12 8 12C7.31574 12 5.94424 12 4.99954 12C4.44725 12 3.98003 11.5499 4.05731 11.003C4.23597 9.73889 4.97153 8 8 8C11.0285 8 11.764 9.73889 11.9427 11.003Z"
                fill={color ?? '#222'}
            />
            <circle cx="8" cy="5.5" r="2" fill={color ?? '#222'} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.50001 7.5C4.32844 7.5 5.00001 6.82843 5.00001 6C5.00001 5.17157 4.32844 4.5 3.50001 4.5C2.67158 4.5 2.00001 5.17157 2.00001 6C2.00001 6.82843 2.67158 7.5 3.50001 7.5ZM3.05254 11C3.05578 10.9549 3.06063 10.9093 3.06716 10.8631C3.17221 10.1198 3.45392 9.13015 4.24811 8.32515C4.31251 8.25987 4.37945 8.19664 4.44898 8.13553C4.18145 8.04924 3.86778 8 3.50001 8C1.76782 8 1.23583 9.09223 1.07244 10.0043C0.975044 10.5479 1.44765 11 1.99994 11H3.05254ZM12.9475 11H14.0001C14.5524 11 15.025 10.5479 14.9276 10.0043C14.7642 9.09223 14.2322 8 12.5 8C12.1322 8 11.8186 8.04924 11.551 8.13553C11.6206 8.19664 11.6875 8.25987 11.7519 8.32515C12.5461 9.13015 12.8278 10.1198 12.9329 10.8631C12.9394 10.9093 12.9442 10.9549 12.9475 11ZM14 6C14 6.82843 13.3284 7.5 12.5 7.5C11.6716 7.5 11 6.82843 11 6C11 5.17157 11.6716 4.5 12.5 4.5C13.3284 4.5 14 5.17157 14 6Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
