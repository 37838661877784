import { useCallback, useEffect } from 'react';

import { ConfirmModal, notifications } from '@dronebase/shared-ui-core';
import { Cross, Opened } from '@dronebase/shared-ui-icons';
import { Stack, Text, Button, Group, ActionIcon, Textarea } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { usePrevious } from '@mantine/hooks';
import { useForm, useFieldArray, Controller } from 'react-hook-form';

import {
    FILE_UPLOAD_ERROR_NOTIFICATION,
    MAX_FILE_SIZE,
    MAX_FILE_SIZE_MB,
    MAX_FILENAME_LENGTH_UPLOAD_MODAL,
    MAX_UPLOAD_FILE_COUNT,
} from 'lib/constants';
import { truncateFileNameWithExtension } from 'lib/helpers';
import { Metadata, UploadState } from 'lib/hooks/useGCSUpload';
import { getAnomalyFileUploadUrl } from 'lib/mutations/getAnomalyFileUploadUrl';
import { LightThemeProvider } from 'providers/LightThemeProvider';

interface UploadFileModalProps {
    opened: boolean;
    onClose: () => void;
    siteId: string | undefined;
    anomalyUUID: string;
    superReportTaskUUID: string | undefined;
    uploadToGCS: (file: File, metadata: Metadata) => Promise<{ ok: boolean }>;
    refetchFiles: () => void;
    finalizeUpload: (file: File) => void;
    uploadStates: Map<string, UploadState>;
}

interface UploadItem {
    file: File;
    caption: string;
}

interface FormValues {
    files: UploadItem[];
}

const UploadFileModal = ({
    opened,
    onClose,
    siteId,
    anomalyUUID,
    superReportTaskUUID,
    uploadToGCS,
    refetchFiles,
    uploadStates,
}: UploadFileModalProps) => {
    const {
        control,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: {
            files: [],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'files',
    });

    useEffect(() => {
        if (opened) {
            reset();
        }
    }, [opened, reset]);

    const previousUploadState = usePrevious(uploadStates);

    useEffect(() => {
        if (previousUploadState && uploadStates.size !== previousUploadState.size) {
            setTimeout(() => {
                refetchFiles();
            }, 2000);
        }
    }, [previousUploadState, uploadStates, refetchFiles]);

    const onSubmit = async (formData: FormValues) => {
        for (const item of formData.files) {
            const { file, caption } = item;

            try {
                const contentType = file.type;
                const filename = file.name;
                const { data: metadata, error } = await getAnomalyFileUploadUrl({
                    caption,
                    filename,
                    contentType,
                    siteId,
                    anomalyUUID,
                    superReportTaskUUID,
                });

                if (error || !metadata) {
                    notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);

                    return;
                }

                await uploadToGCS(file, metadata);
            } catch (error) {
                notifications.error(FILE_UPLOAD_ERROR_NOTIFICATION);
            }
        }

        onClose();
        reset();
    };

    const handleClose = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    const dropzoneFiles = watch('files');

    // The form is invalid if we have no files or any file-captions are missing.
    // React Hook Form will handle the "caption is required" at each index, but
    // we can do an overall check here for enabling/disabling the "Upload" button:
    const isFormValid =
        dropzoneFiles.length > 0 && dropzoneFiles.every((item) => !!item.caption) && !Object.keys(errors).length;

    const maxFileCountErrorMessage = `Only ${MAX_UPLOAD_FILE_COUNT} files can be uploaded at a time.`;
    const isFileCountAboveLimit = dropzoneFiles && dropzoneFiles.length >= MAX_UPLOAD_FILE_COUNT;
    const hasDropzoneError = !!errors.files?.message;

    return (
        <LightThemeProvider>
            <ConfirmModal
                buttonPosition="start"
                confirmLabel="Upload"
                cancelLabel="Cancel"
                modalContentProps={{
                    confirmButtonProps: {
                        disabled: !isFormValid,
                    },
                }}
                onConfirm={handleSubmit(onSubmit)}
                opened={opened}
                onClose={handleClose}
                title="Upload Files"
                size="md"
                mah="50.1rem"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing="md">
                        <Dropzone
                            disabled={isFileCountAboveLimit}
                            onDrop={(incomingFiles) => {
                                if (dropzoneFiles.length + incomingFiles.length > MAX_UPLOAD_FILE_COUNT) {
                                    setError('files', {
                                        type: 'manual',
                                        message: maxFileCountErrorMessage,
                                    });

                                    return;
                                }

                                // If we made it here, we are adding valid files -> remove any old dropzone error:
                                clearErrors('files');

                                incomingFiles.forEach((file) => {
                                    if (file.size > MAX_FILE_SIZE) {
                                        setError('files', {
                                            type: 'manual',
                                            message: `File size must not exceed ${MAX_FILE_SIZE_MB} MB`,
                                        });

                                        return;
                                    }

                                    append({ file, caption: '' });
                                });
                            }}
                            onReject={(fileRejections) => {
                                if (fileRejections.length > 0) {
                                    setError('files', {
                                        type: 'manual',
                                        message:
                                            `Invalid file(s). Please check file size (max ${MAX_FILE_SIZE_MB} MB) ` +
                                            `or file count(${MAX_UPLOAD_FILE_COUNT}).`,
                                    });
                                }
                            }}
                            maxSize={MAX_FILE_SIZE}
                            maxFiles={MAX_UPLOAD_FILE_COUNT}
                            style={{
                                width: '100%',
                                cursor: dropzoneFiles.length >= MAX_UPLOAD_FILE_COUNT ? 'not-allowed' : 'pointer',
                            }}
                            styles={{
                                root: {
                                    'border': hasDropzoneError
                                        ? '1px dashed #fa5252'
                                        : '1px dashed var(--color-grey-200)',
                                    'borderRadius': '0.25rem',
                                    'backgroundColor': isFileCountAboveLimit
                                        ? 'var(--color-grey-25)'
                                        : 'var(--color-white)',
                                    ':hover': {
                                        backgroundColor: isFileCountAboveLimit
                                            ? 'var(--color-grey-25)'
                                            : 'var(--color-white)',
                                    },
                                },
                                inner: {
                                    pointerEvents: 'all',
                                },
                            }}
                        >
                            <Stack ta="center" justify="center" align="center" spacing="0.5rem">
                                <Opened color="var(--color-grey-300)" width="1.25rem" height="1.25rem" />
                                <Text variant="body2light" c={isFileCountAboveLimit ? 'gray.3' : 'gray.9'}>
                                    Drag & drop a file or{' '}
                                    <Button
                                        variant="link"
                                        size="md"
                                        c={isFileCountAboveLimit ? 'gray.3' : undefined}
                                        styles={{
                                            root: {
                                                pointerEvents: isFileCountAboveLimit ? 'none' : 'all',
                                            },
                                        }}
                                    >
                                        Browse
                                    </Button>
                                </Text>
                                <Text variant="body3light" c="gray.4">
                                    (Maximum file size: {MAX_FILE_SIZE_MB} MB)
                                </Text>
                            </Stack>
                        </Dropzone>
                        {errors?.files?.message && (
                            <Text variant="caption1" c="#fa5252">
                                {errors.files.message}
                            </Text>
                        )}

                        <Stack spacing="1rem" style={{ overflowY: 'auto', maxHeight: '24rem' }}>
                            {fields.map((field, index) => (
                                <Group key={field.id} w="100%" align="flex-start" noWrap spacing="0.75rem">
                                    {/* {
                                    // If we want thumbnail images use this
                                    watch(`files.${index}.file`)?.type?.startsWith('image/') && index !== 0 && (
                                        <Image
                                            src={URL.createObjectURL(watch(`files.${index}.file`) as File)}
                                            alt={(watch(`files.${index}.file`) as File)?.name || 'file'}
                                            width="3rem"
                                            height="3rem"
                                            fit="cover"
                                            radius="sm"
                                        />
                                    )} */}

                                    <Stack w="100%" p={0} m={0} spacing="0.5rem">
                                        <Controller
                                            control={control}
                                            name={`files.${index}.caption`}
                                            rules={{
                                                required: 'Caption is required',
                                                minLength: {
                                                    value: 1,
                                                    message: 'Caption is required',
                                                },
                                                validate: {
                                                    notOnlySpaces: (value) => {
                                                        if (!value || value.trim().length === 0) {
                                                            return 'Caption cannot be empty or whitespace only';
                                                        }

                                                        return true;
                                                    },
                                                },
                                            }}
                                            render={({ field: captionField, fieldState }) => (
                                                <Textarea
                                                    {...captionField}
                                                    required
                                                    placeholder="Enter a caption"
                                                    error={
                                                        fieldState.isTouched && fieldState.error?.message
                                                            ? fieldState.error.message
                                                            : undefined
                                                    }
                                                    styles={{ input: { overflow: 'auto' } }}
                                                    autosize
                                                    minRows={1}
                                                    maxRows={3}
                                                />
                                            )}
                                        />
                                        <Text w="100%" variant="body2" c="gray.9">
                                            {truncateFileNameWithExtension(
                                                watch(`files.${index}.file`)?.name || 'Untitled File',
                                                MAX_FILENAME_LENGTH_UPLOAD_MODAL,
                                            )}
                                        </Text>
                                    </Stack>

                                    <ActionIcon
                                        w="auto"
                                        size="sm"
                                        mt="0.25rem"
                                        onClick={() => {
                                            remove(index);
                                        }}
                                    >
                                        <Cross />
                                    </ActionIcon>
                                </Group>
                            ))}
                        </Stack>
                    </Stack>
                </form>
            </ConfirmModal>
        </LightThemeProvider>
    );
};

export default UploadFileModal;
