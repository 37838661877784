import React from 'react';

import { IconProps } from './icon_definition';

export const ChevronLeftIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.85355 11.3536C9.65829 11.5488 9.34171 11.5488 9.14645 11.3536L6.14645 8.35355C5.95118 8.15829 5.95118 7.84171 6.14645 7.64645C6.34171 7.45118 6.65829 7.45118 6.85355 7.64645L9.85355 10.6464C10.0488 10.8417 10.0488 11.1583 9.85355 11.3536Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.85355 4.64645C10.0488 4.84171 10.0488 5.15829 9.85355 5.35355L6.85355 8.35355C6.65829 8.54882 6.34171 8.54882 6.14645 8.35355C5.95118 8.15829 5.95118 7.84171 6.14645 7.64645L9.14645 4.64645C9.34171 4.45118 9.65829 4.45118 9.85355 4.64645Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
