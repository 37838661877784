import React from 'react';

import { IconProps } from './icon_definition';

export const PendingIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8ZM13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8ZM8 4.5C8.27614 4.5 8.5 4.72386 8.5 5V7.69098L10.2236 8.55277C10.4706 8.67627 10.5707 8.9766 10.4472 9.22359C10.3237 9.47058 10.0234 9.5707 9.77638 9.4472L7.78632 8.45217C7.76349 8.44136 7.74161 8.42887 7.72083 8.41487C7.59957 8.3335 7.52336 8.20536 7.50449 8.06727C7.50094 8.04131 7.49941 8.01499 7.5 7.98857V5C7.5 4.72386 7.72386 4.5 8 4.5Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
