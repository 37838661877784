import { notifications } from '@dronebase/shared-ui-core';

import { SITE_INSPECTION_API_URL } from 'lib/constants';
import { UpdateScope } from 'lib/constants/inspections';
import { fetchData } from 'lib/hooks';
import { getAnomalyUuidsByScope } from 'lib/inspections/electricalComponents';
import { InspectionAnomaly } from 'lib/types/inspections/anomaliesMap';

/**
 * Updates the status of one or more anomalies
 * Calls the success callback with the updated anomaly UUIDs for optimistic updates
 */
export const changeAnomalyStatus = async (
    inspectionId: string,
    anomalyUuids: string[],
    anomalyStatusId: number,
    onSuccessCallback: (updatedAnomalyUuids: string[]) => void,
) => {
    if (!anomalyUuids.length) {
        notifications.error({
            title: 'No anomalies selected for update',
            withCloseButton: true,
            withIcon: true,
        });

        return;
    }

    // Create an update for each anomaly UUID
    const updates = anomalyUuids.map((uuid) => ({
        filter: {
            anomaly_uuid: uuid,
        },
        data: {
            client_status_id: anomalyStatusId,
        },
    }));

    const { error } = await fetchData({
        url: `${SITE_INSPECTION_API_URL}/inspections/${inspectionId}/anomalies/update`,
        method: 'POST',
        body: {
            updates,
        },
    });

    if (error) {
        notifications.error({
            title: 'Something went wrong. Please try again later.',
            withCloseButton: true,
            withIcon: true,
        });
    } else {
        const updatedCount = anomalyUuids.length;
        const message =
            updatedCount > 1 ? `${updatedCount} anomalies have been updated` : 'Anomaly status has been updated';

        notifications.success({
            title: message,
            withCloseButton: true,
            withIcon: true,
        });

        onSuccessCallback(anomalyUuids);
    }
};

/**
 * Updates anomaly status based on a specific scope
 * Uses speciesAssetMap for more accurate electrical component relationships
 * Returns the array of affected anomaly UUIDs for optimistic updates
 */
export const changeAnomalyStatusByScope = async (
    inspectionId: string,
    selectedAnomaly: InspectionAnomaly,
    anomalyStatusId: number,
    scope: UpdateScope,
    onSuccessCallback: (anomalyUuids: string[]) => void,
    speciesAssetMap?: Record<
        string,
        Array<{
            assetRegionUUID: string;
            combinerId?: string;
            inverterId?: string;
            stackId?: string;
            anomalyUuids?: string[];
        }>
    >,
) => {
    // Get UUIDs based on the selected scope
    const anomalyUuids = getAnomalyUuidsByScope(selectedAnomaly, scope, speciesAssetMap);

    // Call changeAnomalyStatus with the UUIDs - no need for wrapper anymore
    return changeAnomalyStatus(inspectionId, anomalyUuids, anomalyStatusId, onSuccessCallback);
};
