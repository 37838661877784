import React from 'react';

import { IconProps } from './icon_definition';

// eslint-disable-next-line complexity
export const ShowAllIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M2.5 2.5V5.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M2.5 2.5H5.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M2.5 2.5L6 6" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 13.5L13.5 10.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 13.5L10.5 13.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 13.5L10 10" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 2.5L10.5 2.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 2.5L13.5 5.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 2.5L10 6" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M2.5 13.5L5.5 13.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M2.5 13.5L2.5 10.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M2.5 13.5L6 10" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
