import React from 'react';

import { IconProps } from './icon_definition';

export const ModuleIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="3.5" y="1.5" width="9" height="13" rx="0.5" stroke={color ?? '#222'} />
            <path d="M8 3L8 13" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M6 3L6 13" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M10 3L10 13" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M5 4H11" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M5 6H11" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M5 8H11" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M5 10H11" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M5 12H11" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
        </svg>
    );
};
