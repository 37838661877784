import { RefObject } from 'react';

import { atom } from 'jotai';

import { UploadState } from 'lib/hooks/useGCSUpload';
import { BlobInstance } from 'lib/types/inspections/anomalies';

export const fullscreenFileAtom = atom<BlobInstance | null>(null);
export const fileToDeleteAtom = atom<BlobInstance | null>(null);
export const fullscreenFilePreviewContainerRefAtom = atom<RefObject<HTMLDivElement> | null>(null);
export const deleteModalOpenedAtom = atom(false);
export const externalFileListAtom = atom<BlobInstance[]>([]);
export const externalFileListIndexAtom = atom<number>(0);
export const uploadStatesAtom = atom<Map<string, UploadState>>(new Map());
