import React from 'react';

import { IconProps } from './icon_definition';

export const DocumentIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M5 3.5H8.5V5.9C8.5 6.23137 8.76863 6.5 9.1 6.5H11.5V9V12C11.5 12.2761 11.2761 12.5 11 12.5H5C4.72386 12.5 4.5 12.2761 4.5 12V4C4.5 3.72386 4.72386 3.5 5 3.5Z"
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
            <path d="M8.5 3.5L11.5 6.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M6.25 8.25H9.75" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M6.25 9.5H9" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M6.25 10.75H9.5" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <rect x="6" y="5" width="1" height="1" rx="0.25" fill={color ?? '#222'} />
        </svg>
    );
};
