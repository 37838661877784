import React from 'react';

import { IconProps } from './icon_definition';

export const HorizontalGripperIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <line x1="11" y1="2.5" x2="11" y2="13.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <line x1="7.5" y1="2.5" x2="7.5" y2="13.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <line x1="4" y1="2.5" x2="4" y2="13.5" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
