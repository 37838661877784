import React from 'react';

import { IconProps } from './icon_definition';

export const HeatmapIcon = ({ color = '#222222', ...rest }: IconProps) => (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="4.5" y="2.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="4.5" y="10.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="8.5" y="4.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="8.5" y="10.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="8.5" y="12.5" width="1" height="1" rx="0.25" fill={color} />
            <path
                d="M12.5 2.75C12.5 2.61193 12.6119 2.5 12.75 2.5H13.25C13.3881 2.5 13.5 2.61193 13.5 2.75V3.25C13.5 3.38807 13.3881 3.5 13.25 3.5H12.75C12.6119 3.5 12.5 3.38807 12.5 3.25V2.75Z"
                fill={color}
            />
            <path
                d="M12.5 4.75C12.5 4.61193 12.6119 4.5 12.75 4.5H13.25C13.3881 4.5 13.5 4.61193 13.5 4.75V5.25C13.5 5.38807 13.3881 5.5 13.25 5.5H12.75C12.6119 5.5 12.5 5.38807 12.5 5.25V4.75Z"
                fill={color}
            />
            <path
                d="M12.5 8.75C12.5 8.61193 12.6119 8.5 12.75 8.5H13.25C13.3881 8.5 13.5 8.61193 13.5 8.75V9.25C13.5 9.38807 13.3881 9.5 13.25 9.5H12.75C12.6119 9.5 12.5 9.38807 12.5 9.25V8.75Z"
                fill={color}
            />
            <path
                d="M12.5 10.75C12.5 10.6119 12.6119 10.5 12.75 10.5H13.25C13.3881 10.5 13.5 10.6119 13.5 10.75V11.25C13.5 11.3881 13.3881 11.5 13.25 11.5H12.75C12.6119 11.5 12.5 11.3881 12.5 11.25V10.75Z"
                fill={color}
            />
            <path
                d="M12.5 12.75C12.5 12.6119 12.6119 12.5 12.75 12.5H13.25C13.3881 12.5 13.5 12.6119 13.5 12.75V13.25C13.5 13.3881 13.3881 13.5 13.25 13.5H12.75C12.6119 13.5 12.5 13.3881 12.5 13.25V12.75Z"
                fill={color}
            />
            <rect x="2.5" y="2.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="2.5" y="6.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="2.5" y="10.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="6.5" y="2.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="6.5" y="4.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="6.5" y="6.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="6.5" y="12.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="10.5" y="2.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="10.5" y="6.5" width="1" height="1" rx="0.25" fill={color} />
            <rect x="10.5" y="8.5" width="1" height="1" rx="0.25" fill={color} />
        </svg>
    );
