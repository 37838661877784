import React from 'react';

import { IconProps } from './icon_definition';

export const SatelliteIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect
                x="2.58493"
                y="12.3792"
                width="2"
                height="3"
                rx="0.5"
                transform="rotate(-60 2.58493 12.3792)"
                stroke={color ?? '#222'}
            />
            <rect
                x="7.08493"
                y="4.58493"
                width="2"
                height="3"
                rx="0.5"
                transform="rotate(-60 7.08493 4.58493)"
                stroke={color ?? '#222'}
            />
            <path
                d="M3.58493 10.6471L4.33493 9.34807C4.473 9.10893 4.7788 9.02699 5.01794 9.16506L6.74999 10.1651C6.98914 10.3031 7.07108 10.6089 6.93301 10.8481L6.18301 12.1471L3.58493 10.6471Z"
                stroke={color ?? '#222'}
            />
            <path
                d="M8.08493 2.85289L8.83493 1.55385C8.973 1.31471 9.2788 1.23277 9.51794 1.37084L11.25 2.37084C11.4891 2.50891 11.5711 2.81471 11.433 3.05385L10.683 4.35289L8.08493 2.85289Z"
                stroke={color ?? '#222'}
            />
            <rect
                x="9.41507"
                y="10.549"
                width="8"
                height="3"
                rx="0.5"
                transform="rotate(-150 9.41507 10.549)"
                stroke={color ?? '#222'}
            />
            <path
                d="M9.91748 10.1242L11.0211 12.3563C11.0608 12.4365 11.1114 12.4788 11.1406 12.4933L9.91748 10.1242ZM9.91748 10.1242C9.84338 9.9743 9.84908 9.79733 9.93267 9.65255L10.1651 9.25001L10.3975 8.84746C10.4811 8.70269 10.6315 8.60926 10.7983 8.5985L13.2832 8.43824M9.91748 10.1242L13.2832 8.43824M13.2832 8.43824C13.3726 8.43248 13.4344 8.45519 13.4616 8.4732C13.4734 8.48099 13.4758 8.48569 13.4752 8.4847C13.4739 8.48269 13.4762 8.48435 13.4771 8.49634C13.5048 8.86465 13.4304 9.59432 12.7632 10.75M13.2832 8.43824L12.7632 10.75M12.7632 10.75C12.0959 11.9057 11.5012 12.3349 11.1684 12.4952M12.7632 10.75L11.1684 12.4952M11.1684 12.4952C11.1622 12.4982 11.1587 12.4991 11.1573 12.4993M11.1684 12.4952L11.1573 12.4993M11.1573 12.4993C11.1576 12.4993 11.1576 12.4993 11.1574 12.4993M11.1573 12.4993L11.1574 12.4993M11.1573 12.4993C11.1568 12.4993 11.1566 12.4993 11.1566 12.4993L11.1573 12.4993ZM11.1573 12.4993C11.1573 12.4993 11.1573 12.4993 11.1574 12.4993M11.1573 12.4993L11.1574 12.4993"
                stroke={color ?? '#222'}
            />
        </svg>
    );
};
