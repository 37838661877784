import React from 'react';

import { IconProps } from './icon_definition';

export const CloseIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L7.64645 8.35355C7.45118 8.15829 7.45118 7.84171 7.64645 7.64645C7.84171 7.45118 8.15829 7.45118 8.35355 7.64645L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.35355 8.35355C8.15829 8.54882 7.84171 8.54882 7.64645 8.35355C7.45118 8.15829 7.45118 7.84171 7.64645 7.64645L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8.35355 7.64645C8.54881 7.84171 8.54881 8.15829 8.35355 8.35355C8.15829 8.54882 7.84171 8.54882 7.64645 8.35355L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.64645 7.64645C7.84171 7.45118 8.15829 7.45118 8.35355 7.64645C8.54881 7.84171 8.54881 8.15829 8.35355 8.35355L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
