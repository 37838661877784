import React from 'react';

import { IconProps } from './icon_definition';

export const PlaneIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_2302_259396)">
                <path
                    d="M10.2206 5.37441L10.2981 5.45192L10.3756 5.52942C10.5378 5.69166 10.5689 5.94349 10.4508 6.14022L9.79185 7.23848C9.67381 7.43522 9.70481 7.68705 9.86705 7.84928L12.4194 10.4017L11.3588 11.4623L8.5252 9.25844C8.32616 9.10362 8.04298 9.12125 7.86468 9.29956L6.98108 10.1832C6.84717 10.3171 6.8004 10.5152 6.86029 10.6948L7.11613 11.4623L6.40902 12.1694L5.49481 11.2552C5.40104 11.1614 5.27386 11.1088 5.14125 11.1088C4.86511 11.1088 4.64125 10.8849 4.64125 10.6088C4.64125 10.4762 4.58857 10.349 4.49481 10.2552L3.58059 9.341L4.2877 8.6339L5.0552 8.88973C5.23487 8.94962 5.43295 8.90286 5.56687 8.76894L6.45046 7.88535C6.62877 7.70704 6.6464 7.42387 6.49159 7.22482L4.2877 4.39126L5.34836 3.3306L7.90074 5.88298C8.06297 6.04521 8.3148 6.07621 8.51154 5.95817L9.6098 5.29922C9.80654 5.18117 10.0584 5.21217 10.2206 5.37441Z"
                    stroke={color ?? '#222'}
                    strokeLinejoin="round"
                />
                <path
                    d="M9.94455 3.68414L12.0659 5.80546"
                    stroke={color ?? '#222'}
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.3588 4.39124L10.2981 5.4519"
                    stroke={color ?? '#222'}
                    strokeWidth="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2302_259396">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
