import { RefObject, useCallback, useEffect } from 'react';

import { Upload } from '@dronebase/shared-ui-icons';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useParams } from 'react-router-dom';

import DeleteModal from 'components/common/modals/DeleteModal';
import useAnomalyFiles from 'lib/hooks/useAnomalyFiles';
import { useGcsUpload } from 'lib/hooks/useGCSUpload';
import useWorkspaceDetails from 'lib/hooks/useWorkspaceDetails';
import deleteAnomalyFile from 'lib/mutations/deleteAnomalyFile';
import { HandleInitiateDelete } from 'lib/types/inspections/anomalies';
import {
    deleteModalOpenedAtom,
    externalFileListAtom,
    externalFileListIndexAtom,
    fileToDeleteAtom,
    fullscreenFileAtom,
    fullscreenFilePreviewContainerRefAtom,
} from 'state/inspections/anomalySidebar';

import FilePreview from '../../FilePreview/FilePreview';
import { SingleDetail } from '../SingleDetail';
import { UploadedFilesListContainer } from '../UploadedFilesListContainer';
import UploadFileModal from '../UploadFileModal';

interface AnomalyFileManagerProps {
    anomalyUUID: string;
}

const AnomalyFileManager = ({ anomalyUUID }: AnomalyFileManagerProps) => {
    const { currentUserHasFileManagerPermission } = useWorkspaceDetails();
    const { siteId, inspectionId } = useParams();
    const { externalFiles, fetching, refetchFiles } = useAnomalyFiles(anomalyUUID);

    const [, setExternalFileListAtom] = useAtom(externalFileListAtom);
    const [, setExternalFileListIndex] = useAtom(externalFileListIndexAtom);
    const [fullScreenImageBlobInstance, setFullScreenImageBlobInstance] = useAtom(fullscreenFileAtom);
    const previewPortalContainerRef = useAtomValue(
        fullscreenFilePreviewContainerRefAtom,
    ) as RefObject<HTMLDivElement> | null;

    const setFullScreenFile = useSetAtom(fullscreenFileAtom);

    useEffect(() => {
        if (externalFiles) {
            setExternalFileListAtom(externalFiles);
        }
    }, [externalFiles, setExternalFileListAtom]);

    const [opened, { open, close }] = useDisclosure(false);
    const { uploadToGCS, finalizeUpload, uploadStates, cancelUpload } = useGcsUpload();

    const [deleteModalOpened, setDeleteModalOpened] = useAtom(deleteModalOpenedAtom);
    const [fileToDelete, setFileToDelete] = useAtom(fileToDeleteAtom);

    const closePreview = useCallback(() => {
        setFullScreenImageBlobInstance(null);
    }, [setFullScreenImageBlobInstance]);

    const onSelectCurrentFile = useCallback(
        (fileIndex: number) => {
            // calculate the carousel cyclic index of the file to be displayed
            const fileIndexComputed = (fileIndex + externalFiles.length) % externalFiles.length;

            setFullScreenFile(externalFiles[fileIndexComputed]);
            setFileToDelete(externalFiles[fileIndexComputed]);
            setExternalFileListIndex(fileIndexComputed);
        },
        [externalFiles, setFullScreenFile, setFileToDelete, setExternalFileListIndex],
    );

    const handleInitiateDelete: HandleInitiateDelete = (file, shouldClosePreview) => {
        setFileToDelete(file);
        shouldClosePreview && closePreview();
        setDeleteModalOpened(true);
    };

    const handleConfirmDelete = async () => {
        if (fileToDelete) {
            await deleteAnomalyFile(fileToDelete.blob_index.uuid);
            setTimeout(() => undefined, 1000);
            refetchFiles();
        }

        setFileToDelete(null);
        setDeleteModalOpened(false);
        setFullScreenImageBlobInstance(null);
    };

    return (
        <>
            <DeleteModal
                opened={deleteModalOpened}
                onClose={() => {
                    setDeleteModalOpened(false);
                    setFileToDelete(null);
                }}
                onDelete={handleConfirmDelete}
                zIndex={9999}
            />
            <SingleDetail
                label="Uploaded Files"
                uploadButton={
                    currentUserHasFileManagerPermission && (
                        <Button
                            onClick={open}
                            leftIcon={<Upload width="1rem" height="1rem" />}
                            variant="text"
                            size="md"
                            styles={{
                                root: {
                                    'color': 'var(--color-purple-300)',
                                    ':hover': {
                                        color: 'var(--color-purple-400)',
                                    },
                                },
                            }}
                        >
                            Upload
                        </Button>
                    )
                }
            >
                <UploadedFilesListContainer
                    cancelUpload={cancelUpload}
                    files={externalFiles}
                    fetchingFiles={fetching}
                    onDelete={handleInitiateDelete}
                    onRowClick={onSelectCurrentFile}
                    anomalyUUID={anomalyUUID}
                />
                <UploadFileModal
                    opened={opened}
                    onClose={close}
                    siteId={siteId}
                    anomalyUUID={anomalyUUID}
                    superReportTaskUUID={inspectionId}
                    uploadToGCS={uploadToGCS}
                    refetchFiles={refetchFiles}
                    finalizeUpload={finalizeUpload}
                    uploadStates={uploadStates}
                />
                {fullScreenImageBlobInstance && previewPortalContainerRef?.current && (
                    <FilePreview
                        hasFileManagerPermission={currentUserHasFileManagerPermission}
                        portalContainerRef={previewPortalContainerRef}
                        onDelete={handleInitiateDelete}
                        onSelectCurrentFile={onSelectCurrentFile}
                    />
                )}
            </SingleDetail>
        </>
    );
};

export default AnomalyFileManager;
