import React from 'react';

import { IconProps } from './icon_definition';

export const ShareIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M8 2L8 9" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M8 1.5L6 3.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M8 1.5L10 3.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path
                d="M6.54545 5H5C4.44771 5 4 5.44772 4 6V12C4 12.5523 4.44772 13 5 13H11C11.5523 13 12 12.5523 12 12V6C12 5.44772 11.5523 5 11 5H9.45455"
                stroke={color ?? '#222'}
            />
        </svg>
    );
};
