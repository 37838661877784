import React from 'react';

import { IconProps } from './icon_definition';

export const CompareIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M4 4.5H6H8.5V6V8V11.5H4C3.72386 11.5 3.5 11.2761 3.5 11V5C3.5 4.72386 3.72386 4.5 4 4.5Z"
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
            <path
                d="M12 11.5L10.5 11.5L8.5 11.5L8.5 10L8.5 8L8.5 4.5L12 4.5C12.2761 4.5 12.5 4.72386 12.5 5L12.5 11C12.5 11.2761 12.2761 11.5 12 11.5Z"
                fill={color ?? '#222'}
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
        </svg>
    );
};
