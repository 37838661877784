import React from 'react';

import { IconProps } from './icon_definition';

export const NextLineIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M13.5 12.5L12 11" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 12.5L12 14" stroke={color ?? '#222'} strokeLinecap="round" />
            <path
                d="M13.5 13C13.7761 13 14 12.7761 14 12.5C14 12.2239 13.7761 12 13.5 12V13ZM13 3H10.5V4H13V3ZM6 7.5V8.5H7V7.5H6ZM10.5 13H13.5V12H10.5V13ZM6 8.5C6 10.9853 8.01472 13 10.5 13V12C8.567 12 7 10.433 7 8.5H6ZM10.5 3C8.01472 3 6 5.01472 6 7.5H7C7 5.567 8.567 4 10.5 4V3Z"
                fill={color ?? '#222'}
            />
            <path d="M13.5 2V2C12.8944 2.90833 12.8944 4.09167 13.5 5V5" stroke="black" strokeLinecap="round" />
        </svg>
    );
};
