import React from 'react';

import { IconProps } from './icon_definition';

export const SearchIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M12.5 12.5L9 9" stroke={color ?? '#222'} strokeLinecap="round" />
            <circle cx="6.5" cy="6.5" r="3" stroke={color ?? '#222'} />
        </svg>
    );
};
