/* eslint-disable complexity */
import { useState } from 'react';

import { Tabs } from '@mantine/core';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';

import { AnomalyEntry, InspectionLoaderData } from 'components/inspections/types';
import { ROUTES } from 'lib/constants/routes';
import { formatDateWithLocalTime } from 'lib/helpers';
import { useAnomalyMapData, useDifferentialMode } from 'lib/hooks';
import { useFeatureFlag } from 'lib/hooks/useFeatureFlag';
import { useMultiModuleAnomaly } from 'lib/hooks/useMultiModuleAnomaly';
import { useNavigationBlocker } from 'lib/hooks/useNavigationBlocker';
import { InspectionAnomaly } from 'lib/types/inspections/anomaliesMap';

import AnomalyImages from './AnomalyImages';
import AnomalyPriority from './AnomalyPriority';
import { AnomalyStatusSelection } from './AnomalyStatusSelection';
import AnomalyFileManager from './AnomalyTab/AnomalyFileManager';
import DiscardChangesModal from './DiscardChangesModal';
import ModuleTabFooter from './ModuleTabFooter';
import MultiModuleUpdateModal from './MultiModuleUpdateModal';
import { SingleDetail } from './SingleDetail';
import UpdateScopeBanner from './UpdateScopeBanner';
import { TabContentContainer } from '../TabContainers.styled';

// Define interface for component props
interface AnomalyDetailsProps {
    anomalyToDisplay: AnomalyEntry;
    currentInspectionAnomalies: InspectionAnomaly[];
    inspectionId: string;
    siteId: string | undefined;
    assetRegionUUID: string | undefined;
    anomalyTabNumber: string | undefined;
    refetchAssetRegionAnomalies: () => void;
}

// Component that displays anomaly details
const AnomalyDetailsContent = ({
    anomalyToDisplay,
    currentInspectionAnomalies,
    inspectionId,
    siteId,
    assetRegionUUID,
    anomalyTabNumber,
    refetchAssetRegionAnomalies,
}: AnomalyDetailsProps) => {
    // Internal hooks for data that was previously passed via props
    const { anomalyStatuses } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const anomalyStatusOptions = anomalyStatuses.map((status) => status.name);
    const { activeMapAnomalies, setMapAnomalies } = useAnomalyMapData();
    const { active: differentialModeIsActive } = useDifferentialMode();
    const navigate = useNavigate();

    // Handle tab change internally now
    const handleAnomalyTabChange = (tab: string) => {
        navigate(
            `${ROUTES.sites.index}/${siteId}/${inspectionId}/${ROUTES.sites.inspection.map.index}/` +
                `${assetRegionUUID}/${ROUTES.sites.inspection.map.anomaly}/${Number(tab)}`,
        );
    };

    // Internal state for anomaly status selection
    const [anomalyStatusSelection, setAnomalyStatusSelection] = useState({
        id: anomalyStatuses.find((status) => status.name === anomalyToDisplay?.anomalyStatus?.name)?.id ?? 0,
        name: anomalyToDisplay?.anomalyStatus?.name ?? anomalyStatusOptions[0],
        hasChanged: false,
    });

    // Navigation blocker setup
    const navigationBlocker = useNavigationBlocker(anomalyStatusSelection.hasChanged);

    // Extract basic anomaly info
    const anomalyType = anomalyToDisplay.anomalyDefinition.globalDefinition.anomalyDefinitionName;
    const anomalyPriority = anomalyToDisplay.anomalyDefinition.priority.toString() as '1' | '2' | '3';
    const anomalySubsystem = anomalyToDisplay.anomalyDefinition.subsystem.subsystemName;
    const anomalyDescription = anomalyToDisplay.anomalyDefinition.description;
    const statusUpdatedDate = anomalyToDisplay.clientUpdatedAt
        ? formatDateWithLocalTime(anomalyToDisplay.clientUpdatedAt)
        : '-';
    const anomalyUUID = anomalyToDisplay.uuid;

    // Feature flag for multi-module updates
    const isMultiModuleUpdateEnabled = useFeatureFlag('IS_MULTI_MODULE_UPDATE_ENABLED');

    // Use multi-module anomaly hook
    const {
        showUpdateBanner,
        moduleCountsByScope,
        selectedUpdateScope,
        multiModuleUpdateModalOpen,
        setMultiModuleUpdateModalOpen,
        handleSave: handleMultiModuleSave,
        handleMultiModuleUpdateConfirm,
        anomalyDetails,
    } = useMultiModuleAnomaly({
        anomaly: anomalyToDisplay as unknown as InspectionAnomaly,
        inspectionId: inspectionId || '',
        statusHasChanged: anomalyStatusSelection.hasChanged,
        isEnabled: isMultiModuleUpdateEnabled,
    });

    const handleSave = async () => {
        const newAnomalyStatusId =
            anomalyStatuses.find((status) => status.name === anomalyStatusSelection.name)?.id ?? 0;

        const successCallback = (updatedAnomalyUuids: string[]) => {
            // Skip processing if no anomalies were updated
            if (updatedAnomalyUuids.length === 0) {
                return;
            }

            // Update all matching anomalies in the map
            const updatedMapAnomalies = { ...activeMapAnomalies };

            // Update all relevant anomalies in the map
            Object.keys(updatedMapAnomalies).forEach((regionUUID) => {
                const region = updatedMapAnomalies[regionUUID];

                // Check if this region has any anomalies that were updated
                if (
                    region.anomalyUuids &&
                    region.anomalyUuids.some((uuid: string) => updatedAnomalyUuids.includes(uuid))
                ) {
                    // Only update species that match the selected anomaly's species UUID
                    // This is safer than updating all species in the region
                    if (anomalyDetails && anomalyDetails.speciesUuid) {
                        region.species.forEach((species: { uuid: string; statusId: string }) => {
                            // Only update status for species matching the one being updated
                            if (species.uuid === anomalyDetails.speciesUuid) {
                                species.statusId = String(newAnomalyStatusId);
                            }
                        });
                    }
                }
            });

            // Update the state with all optimistically updated anomalies
            setMapAnomalies(updatedMapAnomalies);

            setAnomalyStatusSelection((prev) => ({ ...prev, hasChanged: false }));
            refetchAssetRegionAnomalies();
        };

        // Always use the hook's handleSave, which will determine whether to
        // use multi-module updates based on flags and state
        await handleMultiModuleSave(newAnomalyStatusId, successCallback);
    };

    const handleDiscard = () => {
        navigationBlocker.proceed();

        setAnomalyStatusSelection({
            id: anomalyStatuses.find((status) => status.name === anomalyToDisplay.anomalyStatus.name)?.id ?? 0,
            name: anomalyToDisplay.anomalyStatus.name ?? anomalyStatusOptions[0],
            hasChanged: false,
        });
    };

    const currentAnomalyStatus = anomalyStatusSelection.hasChanged
        ? anomalyStatusSelection.name
        : anomalyToDisplay.anomalyStatus.name;

    return (
        <>
            <DiscardChangesModal
                opened={navigationBlocker.isNavigationBlocked}
                onClose={navigationBlocker.halt}
                onDiscard={handleDiscard}
            />
            {isMultiModuleUpdateEnabled && (
                <MultiModuleUpdateModal
                    opened={multiModuleUpdateModalOpen}
                    onClose={() => setMultiModuleUpdateModalOpen(false)}
                    onConfirm={handleMultiModuleUpdateConfirm}
                    moduleCountsByScope={moduleCountsByScope}
                    hasInverterEncoding={anomalyDetails?.hasInverterEncoding || false}
                    hasCombinerEncoding={anomalyDetails?.hasCombinerEncoding || false}
                    componentIds={{
                        inverterId: anomalyDetails?.inverterId,
                        combinerId: anomalyDetails?.combinerId,
                        stackId: anomalyDetails?.stackId,
                    }}
                    initialScope={selectedUpdateScope}
                />
            )}
            <TabContentContainer>
                {currentInspectionAnomalies.length > 1 && !differentialModeIsActive && (
                    <Tabs
                        variant="segmented"
                        value={anomalyTabNumber}
                        onTabChange={handleAnomalyTabChange}
                        h="max-content"
                    >
                        <Tabs.List>
                            {currentInspectionAnomalies.map((anomaly: InspectionAnomaly, index: number) => (
                                <Tabs.Tab fz="var(--body2-font-size)" key={anomaly.uuid} value={`${index + 1}`}>
                                    Anomaly {index + 1}
                                </Tabs.Tab>
                            ))}
                        </Tabs.List>
                    </Tabs>
                )}
                <SingleDetail label="Type" value={anomalyType} />
                <SingleDetail label="Priority">
                    <AnomalyPriority anomalyPriority={anomalyPriority} />
                </SingleDetail>
                <SingleDetail label="Subsystem" value={anomalySubsystem} />
                <SingleDetail label="Status">
                    <AnomalyStatusSelection
                        anomalyStatusOptions={anomalyStatusOptions}
                        currentAnomalyStatus={currentAnomalyStatus}
                        onStatusChange={(newStatus: string) => {
                            setAnomalyStatusSelection({
                                id: anomalyStatuses.find((status) => status.name === newStatus)?.id ?? 0,
                                name: newStatus,
                                hasChanged: anomalyToDisplay.anomalyStatus.name !== newStatus,
                            });
                        }}
                    />
                    {isMultiModuleUpdateEnabled && showUpdateBanner && (
                        <UpdateScopeBanner
                            scope={selectedUpdateScope}
                            moduleCountsByScope={moduleCountsByScope}
                            onChangeScope={() => setMultiModuleUpdateModalOpen(true)}
                            componentIds={{
                                inverterId: anomalyDetails?.inverterId,
                                combinerId: anomalyDetails?.combinerId,
                                stackId: anomalyDetails?.stackId,
                            }}
                        />
                    )}
                </SingleDetail>
                <SingleDetail label="Status Updated Date" value={statusUpdatedDate} />
                <SingleDetail label="Images">
                    <AnomalyImages anomaly={anomalyToDisplay} />
                </SingleDetail>
                <SingleDetail label="Description" value={anomalyDescription} />
                <AnomalyFileManager anomalyUUID={anomalyUUID} />
            </TabContentContainer>
            <ModuleTabFooter
                onCancel={handleDiscard}
                disabled={!anomalyStatusSelection.hasChanged}
                onSave={handleSave}
            />
        </>
    );
};

export default AnomalyDetailsContent;
