import React from 'react';

import { IconProps } from './icon_definition';

export const SwitchIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M3.5 6L12.5 6" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M3.5 11L12.5 11" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M3.5 6L6 8.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M12.5 11L10 8.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M3.5 6L6 3.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M12.5 11L10 13.5" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
