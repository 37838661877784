import { Cross } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Text, Group, Progress, Loader, Center, Stack, ActionIcon, Flex, FlexProps } from '@mantine/core';

import { EllipsisText } from 'components/common/text/text';
import { UploadState } from 'lib/hooks/useGCSUpload';

interface UploadingAnomalyFileCardProps {
    uploadState: UploadState;
    cancelUpload: () => void;
}

const UploadingAnomalyFileCard = ({ uploadState, cancelUpload }: UploadingAnomalyFileCardProps) => (
    <HoverableFlex align="center" justify="space-between" h="3.5rem" gap="0.75rem" w="100%" p="0.25rem">
        <Group style={{ borderRadius: '0.25rem' }} position="center" p={0}>
            <Center
                h="3rem"
                w="3rem"
                p={0}
                m={0}
                style={{ borderRadius: '0.25rem', backgroundColor: 'var(--color-grey-800)' }}
            >
                <Loader size={24} />
            </Center>
        </Group>
        <Stack h="100%" w="100%" spacing={1} justify="center" p={0}>
            <EllipsisText variant="body2light" c="gray.0">
                {uploadState.caption}
            </EllipsisText>
            <Group noWrap align="center" position="apart" w="100%" spacing={12}>
                <Progress
                    w="100%"
                    p={0}
                    size="0.25rem"
                    value={uploadState.progress}
                    styles={{
                        root: {
                            backgroundColor: 'var(--color-grey-400)',
                        },
                        bar: {
                            backgroundColor: 'var(--color-purple-300)',
                        },
                    }}
                />
                <Text variant="caption1" c="gray.2" maw="2rem" pr="0.5rem">
                    {`${Math.floor(uploadState.progress)}%`}
                </Text>
            </Group>
        </Stack>

        <ActionIcon size="sm" p={0} m={0} onClick={cancelUpload}>
            <Cross />
        </ActionIcon>
    </HoverableFlex>
);

export default UploadingAnomalyFileCard;

const HoverableFlex = styled(Flex)<FlexProps>({
    '&:hover': {
        backgroundColor: 'var(--color-grey-900)',
        borderRadius: '0.5rem',
    },
});
