import React from 'react';

import { IconProps } from './icon_definition';

export const GlobeIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3Z"
                fill={color ?? '#222'}
            />
            <path d="M8 3.5V12.5" stroke={color ?? '#222'} strokeWidth="0.5" />
            <path d="M8 3.5C8 3.5 5.5 4.5 5.5 8C5.5 11.5 8 12.5 8 12.5" stroke={color ?? '#222'} strokeWidth="0.5" />
            <path d="M8 3.5C8 3.5 10.5 4.5 10.5 8C10.5 11.5 8 12.5 8 12.5" stroke={color ?? '#222'} strokeWidth="0.5" />
            <path d="M3.5 8H12.5" stroke={color ?? '#222'} strokeWidth="0.5" />
            <path d="M4 6H12M4 10H12" stroke={color ?? '#222'} strokeWidth="0.5" />
        </svg>
    );
};
