import React from 'react';

import { IconProps } from './icon_definition';

export const BarcodeIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_2317_259374)">
                <path d="M3 5V11" stroke={color ?? '#222'} />
                <path d="M6 5V11" stroke={color ?? '#222'} strokeWidth="0.5" />
                <path d="M5 5V11" stroke={color ?? '#222'} strokeWidth="0.5" />
                <path d="M8 5V11" stroke={color ?? '#222'} />
                <path d="M9 5V11" stroke={color ?? '#222'} strokeWidth="0.5" />
                <path d="M10 5V11" stroke={color ?? '#222'} strokeWidth="0.5" />
                <path d="M11 5V11" stroke={color ?? '#222'} strokeWidth="0.5" />
                <path d="M13 5V11" stroke={color ?? '#222'} />
            </g>
            <defs>
                <clipPath id="clip0_2317_259374">
                    <rect x="2.5" y="5" width="11" height="6" rx="0.5" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
