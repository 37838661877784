import { MapFilterCategorySet, MapFilterOption, MapFilterOptionSet } from 'components/inspections/types';
import { Config } from 'config';

import { colors } from '.';

export const SITE_INSPECTION_API_URL = Config.get('SITE_INSPECTION_API_URL');

export enum DIFFERENTIAL_MODES {
    PRESENT = 'Present In Both',
    CHANGED_CURRENT = 'Changed Anomalies - Current',
    CHANGED_PREVIOUS = 'Changed Anomalies - Previous',
    ABSENT = 'Absent',
    NEW = 'New',
    PREVIOUS = '<Previous Report Date>',
    LATEST = '<Latest Report Date>',
}

export enum DIFFERENTIAL_MODES_FILTER_OPTIONS {
    PRESENT = 'Present In Both',
    CHANGED = 'Changed Anomalies',
    ABSENT = 'Absent',
    NEW = 'New',
    PREVIOUS = '<Previous Report Date>',
    LATEST = '<Latest Report Date>',
}

export const DIFFERNTIAL_MODE_ICON_NAMES: Record<DIFFERENTIAL_MODES_FILTER_OPTIONS, string> = {
    [DIFFERENTIAL_MODES_FILTER_OPTIONS.PRESENT]: 'differentialPresentInBothMode',
    [DIFFERENTIAL_MODES_FILTER_OPTIONS.CHANGED]: 'differentialChangedAnomaliesMode',
    [DIFFERENTIAL_MODES_FILTER_OPTIONS.ABSENT]: 'differentialAbsentMode',
    [DIFFERENTIAL_MODES_FILTER_OPTIONS.NEW]: 'differentialNewMode',
    [DIFFERENTIAL_MODES_FILTER_OPTIONS.PREVIOUS]: 'differentialFullReportMode',
    [DIFFERENTIAL_MODES_FILTER_OPTIONS.LATEST]: 'differentialFullReportMode',
};

export enum REPORT_TABS {
    FINDINGS = 'Findings',
    ANOMALIES = 'Anomalies',
    MAP = 'Map',
    FILES = 'Files',
}

export enum MODULE_TABS {
    ANOMALY = 'Anomaly',
    MODULE = 'Module',
}

export const FULL_REPORT_TABS = [REPORT_TABS.FINDINGS, REPORT_TABS.ANOMALIES, REPORT_TABS.MAP, REPORT_TABS.FILES];
export const DIFFERENTIAL_REPORT_TABS = [REPORT_TABS.MAP, REPORT_TABS.ANOMALIES];
export const MODULE_DETAILS_TABS = [MODULE_TABS.ANOMALY, MODULE_TABS.MODULE];
export const GLASS_CRACK_REPORT_TABS = [REPORT_TABS.ANOMALIES, REPORT_TABS.MAP, REPORT_TABS.FILES];

export const REPORT_TYPES = {
    DIAGNOSTIC_REPORT: 'Diagnostic',
    STRING_LEVEL: 'String Level',
    MODULE_BASIC: 'Module Basic',
    MODULE_ADVANCED: 'Module Advanced',
    FSX: 'Full Mapping Scope',
    GLASS_CRACK: 'Glass Crack Inspection',
};

// One time only - site on prod that needs a Module Advanced report to show as a Glass Crack report
// LPL && Heliolytics workspaces
export const SPECIAL_GI_REPORT_SRTS = ['d192abac-7bf1-404b-b030-a04f74894436', '446b5bc6-32ae-4067-81d5-4cb8d33ef9de'];

export const FAULT_TYPE_INSPECTION_TYPE_MAP: Record<string, string[]> = {
    bos: [],
    major: [REPORT_TYPES.STRING_LEVEL, REPORT_TYPES.FSX],
    minor: [REPORT_TYPES.MODULE_BASIC, REPORT_TYPES.STRING_LEVEL, REPORT_TYPES.FSX],
};

export enum InspectionUpgradeOptions {
    StringLevel = 'string_level',
    ModuleBasic = 'module_basic',
    ModuleAdvanced = 'module_advanced',
}

export const DEFAULT_UPGRADE_OPTION_COLOR = colors.primaryGreen;

export const REPORT_TYPE_CTA_BUTTON_COLOR: any = {
    string_level: colors.secondaryYellow,
    module_basic: DEFAULT_UPGRADE_OPTION_COLOR,
    module_advanced: colors.primaryBlue,
};
export const REPORT_TYPE_CTA_TEXT: { [key: string]: string } = {
    string_level: 'String Level',
    module_basic: 'Module Basic',
    module_advanced: 'Module Advanced',
};
export const DIAGNOSTIC_REPORT_TYPES = [REPORT_TYPES.DIAGNOSTIC_REPORT, REPORT_TYPES.FSX];

export const DEFAULT_SITE_LOSS_VALUES = {
    APR: 1800.0,
    HURDLE_FACTOR: 7.0,
    PPA_RATE: 0.08,
    NPV_DURATION: 15.0,
};

export const REMEDIATION_LEVEL_1_NAME = 'Remediation Recommended';
export const REMEDIATION_LEVEL_2_NAME = 'Monitor and Remediate';
export const REMEDIATION_LEVEL_3_NAME = 'Long Term Monitoring';

export const REMEDIATION_LEVEL_1 = `1 - ${REMEDIATION_LEVEL_1_NAME}`;
export const REMEDIATION_LEVEL_2 = `2 - ${REMEDIATION_LEVEL_2_NAME}`;
export const REMEDIATION_LEVEL_3 = `3 - ${REMEDIATION_LEVEL_3_NAME}`;
export const ALL_REMEDIATION_LEVELS = [REMEDIATION_LEVEL_1, REMEDIATION_LEVEL_2, REMEDIATION_LEVEL_3];

export const PRIORITY_LEVEL_TO_REMEDIATION_CATEGORY: any = {
    1: REMEDIATION_LEVEL_1,
    2: REMEDIATION_LEVEL_2,
    3: REMEDIATION_LEVEL_3,
};

export const PRIORITY_LEVEL_TO_ICON: any = {
    1: 'priorityOne',
    2: 'priorityTwo',
    3: 'priorityThree',
};

export const ANOMALY_ATTRIBUTES = {
    IR_ANOMALY: 'IR Anomaly',
    IR_IMAGE_URL: 'IR Image URL',
    RGB_ANOMALY: 'RGB Anomaly',
    RGB_IMAGE_URL: 'RGB Image URL',
    PRIORITY_LEVEL: 'Priority Level',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    ENERGY_LOSS_WEIGHT: 'Energy Loss Weight',
    POSITION_ID: 'Position ID',
    STACK_ID: 'Stack ID',
    IR_SIGNAL: 'IR Signal',
    SUBSYSTEM: 'Subsystem',
    REMEDIATION_CATEGORY: 'Priority',
    RGB_SIGNAL: 'RGB Signal',
    POWER_LOSS: 'Power Loss',
    COMBINER_ID: 'Combiner ID',
    INVERTER_ID: 'Inverter ID',
    STATUS: 'Status',
    UUID: 'UUID',
    ANOMALY_STATUS: 'Anomaly Status',
    STATUS_UPDATED_DATE: 'Status Updated Date',
    ANOMALY_TEMP: 'Anomaly Temperature',
    BASE_TEMP: 'Base Temperature',
    TEMP_DELTA: 'Temperature Delta',
    SERIAL_NUMBER: 'Serial Number',
};

export const EMPTY_SIGNAL_OR_MODIFIER = 'n/a';
export const NO_IR_SIGNAL = 'No IR Signal';
export const NO_RGB_SIGNAL = 'No RGB Signal';

export const HIDDEN_TABULAR_TABLE_ANOMALY_ATTRIBUTES = [
    ANOMALY_ATTRIBUTES.IR_ANOMALY,
    ANOMALY_ATTRIBUTES.IR_IMAGE_URL,
    ANOMALY_ATTRIBUTES.RGB_ANOMALY,
    ANOMALY_ATTRIBUTES.RGB_IMAGE_URL,
    ANOMALY_ATTRIBUTES.PRIORITY_LEVEL,
    ANOMALY_ATTRIBUTES.POWER_LOSS,
];

// Migrated from Anomaly Map

// Removed the type from these colours (RGBAColor from deck.gl),
// might have to add the deck typing library to make it work
export const VIEWPORT_PATH_COLOR = [91, 72, 251];
export const ASSET_HIGHLIGHTED_COLOR = [243, 57, 11, 255];
export const ASSET_DEFAULT_COLOR = [227, 220, 216, 100];
export const MINIMAP_SITE_FILL_COLOR = [226, 223, 241];
export const MINIMAP_SITE_OUTLINE_COLOR = [103, 85, 252];
export const MAIN_MAP_SITE_FILL_COLOR = [0, 0, 128, 10];
export const MAIN_MAP_SITE_OUTLINE_COLOR = [85, 84, 145];
export const SELECTED_LAYER_OUTLINE_COLOR = [255, 153, 0];
export const INVERTER_LAYER_OUTLINE_COLOR = [104, 31, 197];
export const STACK_LAYER_OUTLINE_COLOR = [35, 42, 52];
export const COMBINER_LAYER_OUTLINE_COLOR = [88, 105, 255];
export const SET_LAYER_OUTLINE_COLOR = [0, 0, 0];
export const SELECTED_MODULE_FILL_COLOR = [255, 255, 255];
export const TRANSPARENT_FILL_COLOR = [0, 0, 0, 0];
export const MODULE_DEFAULT_LINE_COLOR = [0, 0, 0, 255];

export const MODULE_ASSET_TYPE_UUID = '5c5bafc2-35dd-42b1-9036-40c803500d87';
export const COMBINER_ASSET_TYPE_UUID = '6e0faa50-c763-4bba-82c2-838567c9e7dd';
export const INVERTER_ASSET_TYPE_UUID = 'e25ec74f-b47d-4389-9019-0f40f1336885';
export const STACK_ASSET_TYPE_UUID = 'caf9b123-6a58-4d9c-ba4d-fd97dfa313db';
export const SET_ASSET_TYPE_UUID = '4a3c9160-a6f3-4de9-8d90-ac9ad77382a0';
export const LON_LAT_DECIMAL_PLACES_SHOWN = 6;

export enum MODULE_POSITION_DIRECTIONS {
    ROW_FROM_SOUTHERN_END_OF_STACK = 'Row from Southern end of stack',
    ROW_FROM_WESTERN_END_OF_STACK = 'Row from Western end of stack',
    ROW_FROM_EASTERN_END_OF_STACK = 'Row from Eastern end of stack',
    SET_FROM_NORTH = 'Set from North',
    SET_FROM_SOUTH = 'Set from South',
    SET_FROM_EAST = 'Set from East',
    SET_FROM_WEST = 'Set from West',
    COLUMN_IN_SET_FROM_WEST = 'Column in set from West',
    COLUMN_IN_SET_FROM_EAST = 'Column in set from East',
    MODULE_FROM_EAST = 'Module from East',
    MODULE_FROM_WEST = 'Module from West',
    MODULE_FROM_BOTTOM_OF_ROW = 'Module from bottom of row',
    MODULE_FROM_NORTHERN_END_OF_ROW = 'Module from Northern end of row',
    MODULE_FROM_SOUTHERN_END_OF_ROW = 'Module from Southern end of row',
    MODULE_IN_SET_FROM_NORTH = 'Module in set from North',
    MODULE_IN_SET_FROM_SOUTH = 'Module in set from South',
}

// From site-management-api/src/app/sites.py in site-management-api
export const TRACKER_TYPES = {
    // # Example: 4[3-1W,5-60E]A
    GROUND_MOUNT_VISIBLE: {
        name: 'Ground Mount or Dual Axis Tracker - Visible Sets',
        regex: /^(\d+)\[(\d+)-(\d+)W,(\d+)-(\d+)E\]([A-Z]?)$/,
        directions: [
            MODULE_POSITION_DIRECTIONS.ROW_FROM_SOUTHERN_END_OF_STACK,
            MODULE_POSITION_DIRECTIONS.SET_FROM_WEST,
            MODULE_POSITION_DIRECTIONS.COLUMN_IN_SET_FROM_WEST,
            MODULE_POSITION_DIRECTIONS.SET_FROM_EAST,
            MODULE_POSITION_DIRECTIONS.COLUMN_IN_SET_FROM_EAST,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_BOTTOM_OF_ROW,
        ],
    },
    // # Example: 2[1W,31E]D
    GROUND_MOUNT_NON_VISIBLE: {
        name: 'Ground Mount - Non-Visible Sets',
        regex: /^(\d+)\[(\d+)W,(\d+)E\]([A-Z]?)$/,
        directions: [
            MODULE_POSITION_DIRECTIONS.ROW_FROM_SOUTHERN_END_OF_STACK,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_WEST,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_EAST,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_BOTTOM_OF_ROW,
        ],
    },
    // # Example: 3[7-22N]A & [1-60S]B
    TRACKER_N_S_VISIBLE: {
        name: 'Tracker - From N or S - Visible Sets',
        regex: /^(\d+)\[(\d+)-(\d+)[S|N]\]([A-Z]?)$/,
        directions: [
            MODULE_POSITION_DIRECTIONS.ROW_FROM_WESTERN_END_OF_STACK,
            MODULE_POSITION_DIRECTIONS.SET_FROM_SOUTH,
            MODULE_POSITION_DIRECTIONS.MODULE_IN_SET_FROM_SOUTH,
            MODULE_POSITION_DIRECTIONS.SET_FROM_NORTH,
            MODULE_POSITION_DIRECTIONS.MODULE_IN_SET_FROM_NORTH,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_BOTTOM_OF_ROW,
        ],
    },
    // # Example: 19[2N]C & 6[4S]A
    TRACKER_N_S_NON_VISIBLE: {
        name: 'Tracker - From N or S - Non-Visible Sets',
        regex: /^(\d+)\[(\d+)[S|N]\]([A-Z]?)$/,
        directions: [
            MODULE_POSITION_DIRECTIONS.ROW_FROM_EASTERN_END_OF_STACK,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_NORTHERN_END_OF_ROW,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_SOUTHERN_END_OF_ROW,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_BOTTOM_OF_ROW,
        ],
    },
    // # Example: 2[7-4S,3-1N]B
    TRACKER_VISIBLE: {
        name: 'Tracker - Visible Sets',
        regex: /^(\d+)\[(\d+)-(\d+)S,(\d+)-(\d+)N\]([A-Z]?)$/,
        directions: [
            MODULE_POSITION_DIRECTIONS.ROW_FROM_EASTERN_END_OF_STACK,
            MODULE_POSITION_DIRECTIONS.SET_FROM_SOUTH,
            MODULE_POSITION_DIRECTIONS.MODULE_IN_SET_FROM_SOUTH,
            MODULE_POSITION_DIRECTIONS.SET_FROM_NORTH,
            MODULE_POSITION_DIRECTIONS.MODULE_IN_SET_FROM_NORTH,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_BOTTOM_OF_ROW,
        ],
    },
    // # Example: 1[4S,8N]B
    TRACKER_NON_VISIBLE: {
        name: 'Tracker - Non-Visible Sets',
        regex: /^(\d+)\[(\d+)S,(\d+)N\]([A-Z]?)$/,
        directions: [
            MODULE_POSITION_DIRECTIONS.ROW_FROM_EASTERN_END_OF_STACK,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_SOUTHERN_END_OF_ROW,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_NORTHERN_END_OF_ROW,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_BOTTOM_OF_ROW,
        ],
    },
    // # Example: 2,6
    CARPORT_OR_ROOFTOP: {
        name: 'Carport or Rooftop',
        regex: /^(\d+),(\d+)$/,
        directions: [
            MODULE_POSITION_DIRECTIONS.ROW_FROM_SOUTHERN_END_OF_STACK,
            MODULE_POSITION_DIRECTIONS.MODULE_FROM_WEST,
        ],
    },
};

export const TRACKER_TYPES_WITH_SETS = [
    TRACKER_TYPES.TRACKER_N_S_VISIBLE.name,
    TRACKER_TYPES.TRACKER_VISIBLE.name,
    TRACKER_TYPES.GROUND_MOUNT_VISIBLE.name,
];

export const HEATMAP_COLORS = [
    [255, 255, 174],
    [255, 218, 110],
    [255, 179, 63],
    [255, 141, 46],
    [243, 57, 11],
    [191, 0, 32],
];

export const MAPBOX_TOKEN = String(Config.get('MAPBOX_TOKEN'));
export const MAPBOX_STATIC_BASE_URL = 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static';
export const SITE_CARD_DISPLAY_PROPERTIES = {
    'stroke': '#FF0000',
    'fill-opacity': 0,
};

// TODO: Use constants here for the remediation keys
const REMEDIATION_FILTERS: MapFilterOptionSet = {
    priorityHigh: {
        name: REMEDIATION_LEVEL_1,
        databaseValue: '1',
        numberOfResults: 0,
    },
    priorityMedium: {
        name: REMEDIATION_LEVEL_2,
        databaseValue: '2',
        numberOfResults: 0,
    },
    priorityLow: {
        name: REMEDIATION_LEVEL_3,
        databaseValue: '3',
        numberOfResults: 0,
    },
};

const STATUS_FILTERS = {
    '0': {
        name: 'Pending',
        databaseValue: '0',
        count: 0,
    },
    '1': {
        name: 'In Progress',
        databaseValue: '1',
        count: 0,
    },
    '2': {
        name: 'On Hold',
        databaseValue: '2',
        count: 0,
    },
    '3': {
        name: 'Not Found',
        databaseValue: '3',
        count: 0,
    },
    '4': {
        name: 'No Action Required',
        databaseValue: '4',
        count: 0,
    },
    '5': {
        name: 'Resolved',
        databaseValue: '5',
        count: 0,
    },
};

export enum DISPLAY_MAP_LAYER_KEYS {
    OUTLINE = 'showOutline',
    INVERTER = 'showInverter',
    COMBINER = 'showCombiner',
    STACK = 'stack',
}

export enum MAP_LAYER_ASSET_TYPES {
    INVERTER = 'inverter',
    COMBINER = 'combiner',
    STACK = 'stack',
}

export type MapLayerFilterOptions = 'outline' | MAP_LAYER_ASSET_TYPES;

export const mapLayerFilterOptions: Record<MapLayerFilterOptions, MapFilterOption> = {
    outline: {
        name: 'Site Outline',
        databaseValue: DISPLAY_MAP_LAYER_KEYS.OUTLINE,
        color: MAIN_MAP_SITE_OUTLINE_COLOR,
    },
    [MAP_LAYER_ASSET_TYPES.STACK]: {
        name: 'Stack Boundaries',
        databaseValue: DISPLAY_MAP_LAYER_KEYS.STACK,
        color: STACK_LAYER_OUTLINE_COLOR,
    },
    [MAP_LAYER_ASSET_TYPES.INVERTER]: {
        name: 'Inverter Boundaries',
        databaseValue: DISPLAY_MAP_LAYER_KEYS.INVERTER,
        color: INVERTER_LAYER_OUTLINE_COLOR,
    },
    [MAP_LAYER_ASSET_TYPES.COMBINER]: {
        name: 'Combiner Boundaries',
        databaseValue: DISPLAY_MAP_LAYER_KEYS.COMBINER,
        color: COMBINER_LAYER_OUTLINE_COLOR,
    },
};

export const staticAnomalyFilters: MapFilterCategorySet = {
    remediationFilters: {
        name: 'Priority',
        loading: false,
        filterOptions: REMEDIATION_FILTERS,
    },
    statusIds: {
        name: 'Anomaly Status',
        loading: true,
        filterOptions: STATUS_FILTERS,
    },
    ir: {
        name: 'IR Signal',
        loading: true,
        filterOptions: {},
    },
    rgb: {
        name: 'RGB Signal',
        loading: true,
        filterOptions: {},
    },
};

// Hexmap

export const HEX_QUANTILE_COLORS = {
    A: HEATMAP_COLORS[1],
    B: HEATMAP_COLORS[2],
    C: HEATMAP_COLORS[3],
    D: HEATMAP_COLORS[4],
};
export const HEXMAP_FILL_ALPHA = 120;
export const HEXMAP_LINE_ALPHA = 255;

export const AssetTypes: Record<
    MAP_LAYER_ASSET_TYPES,
    { uuid: string; color: number[]; layerId: string; nom: string }
> = {
    [MAP_LAYER_ASSET_TYPES.COMBINER]: {
        uuid: COMBINER_ASSET_TYPE_UUID,
        color: COMBINER_LAYER_OUTLINE_COLOR,
        layerId: 'combiner-layer',
        nom: 'nomProperties',
    },
    [MAP_LAYER_ASSET_TYPES.INVERTER]: {
        uuid: INVERTER_ASSET_TYPE_UUID,
        color: INVERTER_LAYER_OUTLINE_COLOR,
        layerId: 'inverter-layer',
        nom: 'nomProperties',
    },
    [MAP_LAYER_ASSET_TYPES.STACK]: {
        uuid: STACK_ASSET_TYPE_UUID,
        color: STACK_LAYER_OUTLINE_COLOR,
        layerId: 'stack-layer',
        nom: 'nomStack',
    },
};

export const MODULE_LAYER_ID = 'module-layer';
export const SET_LAYER_ID = 'set-layer';

export const DIFFERENTIALS_APPLICATION_NAME = 'differentials';

export const MIN_ANOMALY_ZOOM_LEVEL = 17;
export const MIN_COMBINER_OUTLINE_ZOOM = 17;
export const MIN_COMBINER_LABEL_ZOOM = 18;
export const MIN_ZOOM_PICKABLE = 16;
export const MAX_LABEL_ZOOM = 20;

export const ANOMALIES_TAB = 'Anomalies';
export const OBSERVATIONS_TAB = 'Observations';

export const FILTER_OPTION_DEFAULT_SHOWN_LIMIT = 10;
export const ANOMALY_FILE_DEFAULT_SHOWN_LIMIT = 5;

export const MAX_FILE_SIZE_MB = 20;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // 20MB
export const MAX_FILENAME_LENGTH_UPLOAD_MODAL = 45;
export const FILE_UPLOAD_ERROR_NOTIFICATION = {
    title: 'Upload Failed',
    message: 'An error occurred while uploading the file.',
};
export const MAX_UPLOAD_FILE_COUNT = 5;

export const ANOMALY_CLIENT_FILE_CATEGORY = 'Client Attachment';
export const ANOMALY_INTERNAL_IMAGE_CATEGORY = 'Anomaly Image';
export const ANOMALY_FILE_CATEGORIES = [ANOMALY_INTERNAL_IMAGE_CATEGORY, ANOMALY_CLIENT_FILE_CATEGORY];

export const IMAGE_MIME_TYPE: string[] = [
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/svg+xml',
    'image/webp',
    'image/avif',
];

export const IMAGE_PREVIEW_DETAILS_SIDEBAR_WIDTH = '30rem';

/**
 * Enum for update scope options when updating multi-module anomalies
 */
export enum UpdateScope {
    SINGLE = 'single',
    STACK = 'stack',
    INVERTER = 'inverter',
    COMBINER = 'combiner',
}
