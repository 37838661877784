import { Box, Group, Text, Button } from '@mantine/core';

import { icons } from 'core/Icons/icon_definition';
import { UpdateScope } from 'lib/constants/inspections';
import { getScopeComponentName, getModuleCountForScope } from 'lib/inspections/electricalComponents';

interface UpdateScopeBannerProps {
    scope: UpdateScope;
    moduleCountsByScope: {
        inverter?: number;
        combiner?: number;
        string?: number;
        stack?: number;
    };
    onChangeScope: () => void;
    // Component identifiers
    componentIds?: {
        inverterId?: string;
        combinerId?: string;
        stackId?: string;
    };
}

const UpdateScopeBanner = ({ scope, moduleCountsByScope, onChangeScope, componentIds }: UpdateScopeBannerProps) => {
    // Helper function to format text with both combiner and inverter IDs
    const getCombinerInverterText = (
        scope: UpdateScope,
        componentIds?: { combinerId?: string; inverterId?: string; stackId?: string },
    ) => {
        if (scope === UpdateScope.COMBINER && componentIds?.combinerId && componentIds?.inverterId) {
            return ` (Combiner: ${componentIds.combinerId}, Inverter: ${componentIds.inverterId})`;
        } else if (scope === UpdateScope.COMBINER && componentIds?.combinerId) {
            return ` (${componentIds.combinerId})`;
        } else if (scope === UpdateScope.INVERTER && componentIds?.inverterId) {
            return ` (${componentIds.inverterId})`;
        } else if (scope === UpdateScope.STACK && componentIds?.stackId) {
            return ` (${componentIds.stackId})`;
        }

        return '';
    };
    // Get module count for current scope
    const moduleCount = getModuleCountForScope(scope, moduleCountsByScope);

    // Don't show banner for invalid module counts
    if (moduleCount <= 0) {
        return null;
    }

    const componentType = getScopeComponentName(scope);
    const InfoIcon = icons.info;

    return (
        <Box
            sx={(theme) => ({
                backgroundColor: '#4254B9',
                padding: '12px 16px',
                borderRadius: '4px',
                marginTop: '8px',
                marginBottom: '12px',
                border: '1px solid #3a49a3',
                color: 'white',
            })}
        >
            <Group position="apart">
                <Group spacing="xs">
                    <InfoIcon color="white" />
                    <Text size="sm">
                        {scope === UpdateScope.SINGLE ? (
                            'Only the selected module will be updated.'
                        ) : (
                            <>
                                {moduleCount} module{moduleCount > 1 ? 's' : ''} affected by the same anomaly will be
                                updated within the same {componentType}
                                {getCombinerInverterText(scope, componentIds)}.
                            </>
                        )}
                    </Text>
                </Group>
                <Button variant="filled" color="orange" onClick={onChangeScope} compact>
                    Change Updating Option
                </Button>
            </Group>
        </Box>
    );
};

export default UpdateScopeBanner;
