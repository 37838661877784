import React from 'react';

import { IconProps } from './icon_definition';

export const Priority3Icon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M9.69598 8.096C9.80265 8.34133 9.85598 8.624 9.85598 8.944C9.85598 9.22667 9.80798 9.49333 9.71198 9.744C9.57865 10.0853 9.36265 10.352 9.06398 10.544C8.76532 10.736 8.41065 10.832 7.99998 10.832C7.59465 10.832 7.23732 10.7307 6.92798 10.528C6.62398 10.3253 6.40532 10.0507 6.27198 9.704C6.20798 9.53867 6.16532 9.328 6.14398 9.072C6.14398 8.98133 6.18932 8.936 6.27998 8.936H7.14398C7.22932 8.936 7.27732 8.98133 7.28798 9.072C7.29865 9.2 7.32532 9.31733 7.36798 9.424C7.41598 9.56267 7.49332 9.672 7.59998 9.752C7.71198 9.82667 7.84265 9.864 7.99198 9.864C8.29065 9.864 8.49598 9.73333 8.60798 9.472C8.68265 9.312 8.71998 9.12533 8.71998 8.912C8.71998 8.68267 8.67732 8.47733 8.59198 8.296C8.47465 8.04533 8.27198 7.92 7.98398 7.92C7.88798 7.92 7.76532 7.97067 7.61598 8.072C7.58398 8.088 7.55732 8.096 7.53598 8.096C7.49332 8.096 7.45598 8.07733 7.42398 8.04L6.99998 7.472C6.97865 7.44533 6.96798 7.41333 6.96798 7.376C6.96798 7.344 6.98398 7.312 7.01598 7.28L8.26398 6.192C8.27998 6.18133 8.28532 6.17067 8.27998 6.16C8.27465 6.144 8.26132 6.136 8.23998 6.136H6.39198C6.30132 6.136 6.25598 6.09067 6.25598 6V5.304C6.25598 5.21333 6.30132 5.168 6.39198 5.168H9.64798C9.73865 5.168 9.78398 5.21333 9.78398 5.304V6.088C9.78398 6.152 9.75998 6.208 9.71198 6.256L8.66398 7.208C8.63198 7.24 8.64265 7.26133 8.69598 7.272C9.15998 7.36267 9.49332 7.63733 9.69598 8.096Z"
                fill={color ?? '#222'}
            />
            <rect x="2.5" y="2.5" width="11" height="11" rx="0.5" stroke={color ?? '#222'} />
        </svg>
    );
};
