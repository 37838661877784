import React from 'react';

import { IconProps } from './icon_definition';

export const NotificationIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <mask id="path-1-inside-1_2855_262805" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 6C11 4.34315 9.65686 3 8 3C6.34315 3 5 4.34315 5 6C5 6.0055 5.00002 6.011 5.00005 6.0165L5.00004 6.54556C5.00003 8.11711 4.37572 9.62429 3.26445 10.7355C2.79784 11.2022 3.12831 12 3.7882 12H3.95378H12.2118C12.8717 12 13.2022 11.2022 12.7356 10.7356C11.6243 9.6243 11 8.11712 11 6.54557V6H11Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 6C11 4.34315 9.65686 3 8 3C6.34315 3 5 4.34315 5 6C5 6.0055 5.00002 6.011 5.00005 6.0165L5.00004 6.54556C5.00003 8.11711 4.37572 9.62429 3.26445 10.7355C2.79784 11.2022 3.12831 12 3.7882 12H3.95378H12.2118C12.8717 12 13.2022 11.2022 12.7356 10.7356C11.6243 9.6243 11 8.11712 11 6.54557V6H11Z"
                fill={color ?? '#222'}
            />
            <path
                d="M11 6H10V7H11V6ZM5.00005 6.0165L6.00006 6.01651L6.00003 6.01112L5.00005 6.0165ZM5.00004 6.54556L4.00004 6.54555L5.00004 6.54556ZM3.26445 10.7355L2.55735 10.0284H2.55735L3.26445 10.7355ZM12.7356 10.7356L13.4427 10.0285V10.0285L12.7356 10.7356ZM11 6H12V5H11V6ZM8 4C9.10457 4 10 4.89543 10 6H12C12 3.79086 10.2091 2 8 2V4ZM6 6C6 4.89543 6.89543 4 8 4V2C5.79086 2 4 3.79086 4 6H6ZM6.00003 6.01112C6.00001 6.0074 6 6.0037 6 6H4C4 6.00731 4.00002 6.0146 4.00006 6.02188L6.00003 6.01112ZM6.00004 6.54557L6.00005 6.01651L4.00005 6.01649L4.00004 6.54555L6.00004 6.54557ZM3.97156 11.4427C5.27036 10.1439 6.00002 8.38234 6.00004 6.54557L4.00004 6.54555C4.00003 7.85189 3.48108 9.10472 2.55735 10.0284L3.97156 11.4427ZM3.7882 11C4.01922 11 4.13491 11.2793 3.97156 11.4427L2.55735 10.0284C1.46076 11.125 2.2374 13 3.7882 13V11ZM3.95378 11H3.7882V13H3.95378V11ZM12.2118 11H3.95378V13H12.2118V11ZM12.0284 11.4427C11.8651 11.2793 11.9808 11 12.2118 11V13C13.7626 13 14.5392 11.125 13.4427 10.0285L12.0284 11.4427ZM10 6.54557C10 8.38233 10.7297 10.1439 12.0284 11.4427L13.4427 10.0285C12.519 9.10473 12 7.8519 12 6.54557H10ZM10 6V6.54557H12V6H10ZM11 7H11V5H11V7Z"
                fill={color ?? '#222'}
                mask="url(#path-1-inside-1_2855_262805)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13H7Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
