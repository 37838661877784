import React from 'react';

import { IconProps } from './icon_definition';

export const ChangedIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M3.5 10.5L5.5 10.5L9 5.5L12.5 5.5"
                stroke={color ?? '#222'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.65 6.27096L5.90962 5.21327C5.81605 5.07961 5.66316 5 5.5 5H3.5C3.22386 5 3 5.22386 3 5.5C3 5.77614 3.22386 6 3.5 6H5.23967L6.03967 7.14286L6.65 6.27096ZM6.65 8.01475L7.26033 7.14286L7.85 7.98525L7.23967 8.85714L6.65 8.01475ZM7.85 9.72904L8.59038 10.7867C8.68395 10.9204 8.83684 11 9 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H9.26033L8.46033 8.85714L7.85 9.72904Z"
                fill={color ?? '#222'}
            />
            <path d="M12.5 10.5L11 9" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M12.5 5.5L11 4" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M12.5 10.5L11 12" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M12.5 5.5L11 7" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
