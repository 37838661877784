import { useState, useEffect } from 'react';

import { getCookie } from 'lib/cookies';

interface FeatureFlags {
    [key: string]: boolean | Record<string, any>;
}

// Single workspace that should have multi-module update enabled
const HELIO_WORKSPACE_ID = 'fcce8108-1a4b-410b-9024-a8181fbca3ef';
const TEST_WORKSPACE_ID = '9a19090f-32a5-4f88-b6e2-69b11ee3b614';

const featureFlags: FeatureFlags = {
    IS_ANOMALY_FILE_MVP2_ENABLED: true,
    // Feature flag with workspace-specific override
    IS_MULTI_MODULE_UPDATE_ENABLED: {
        default: false, // Default setting for all workspaces
        workspaces: [HELIO_WORKSPACE_ID, TEST_WORKSPACE_ID], // The single workspace that should have it enabled
    },
    // Add more feature flags here
};

export const useFeatureFlag = (flagName: keyof typeof featureFlags) => {
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        const flag = featureFlags[flagName];

        // Handle workspace-specific flag
        if (typeof flag === 'object' && 'workspaces' in flag) {
            const currentWorkspace = getCookie('helio-workspace')?.id;
            const isWorkspaceEnabled = currentWorkspace && (flag.workspaces as string[]).includes(currentWorkspace);

            setIsEnabled(isWorkspaceEnabled || (flag.default as boolean));
        } else {
            // Regular feature flag
            setIsEnabled(flag as boolean);
        }
    }, [flagName]);

    return isEnabled;
};
