import { useRef } from 'react';

import { useAtom } from 'jotai';

import { uploadStatesAtom } from 'state/inspections/anomalySidebar';

export interface UploadState {
    file: File;
    progress: number;
    isUploading: boolean;
    error: string | null;
    isCompleted: boolean;
    caption: string;
    anomalyUUID: string;
}

export interface Metadata {
    url: string;
    headers: Record<string, string>;
}

export const useGcsUpload = () => {
    const [uploadStates, setUploadStates] = useAtom(uploadStatesAtom);
    const xhrRequests = useRef<Map<string, XMLHttpRequest>>(new Map());

    const startUpload = (file: File, caption: string, anomalyUUID: string) => {
        setUploadStates((prev) => {
            const newMap = new Map(prev);

            newMap.set(file.name, {
                file,
                progress: 0,
                isUploading: true,
                error: null,
                isCompleted: false,
                caption,
                anomalyUUID,
            });

            return newMap;
        });
    };

    const finalizeUpload = (file: File) => {
        setUploadStates((prev) => {
            const newMap = new Map(prev);
            const uploadState = newMap.get(file.name);

            if (uploadState) {
                newMap.delete(file.name);
            }

            return newMap;
        });
    };

    const cancelUpload = (file: File) => {
        const xhr = xhrRequests.current.get(file.name);

        if (xhr) {
            xhr.abort();
            xhrRequests.current.delete(file.name);
            finalizeUpload(file);
        }
    };

    const uploadToGCS = async (file: File, metadata: Metadata): Promise<{ ok: boolean }> => {
        if (!file || !metadata || !metadata.url) {
            const errorMsg = 'File and metadata with URL are required.';

            setUploadStates((prev) => {
                const newMap = new Map(prev);
                const uploadState = newMap.get(file.name);

                if (uploadState) {
                    newMap.set(file.name, { ...uploadState, error: errorMsg });
                }

                return newMap;
            });

            throw new Error(errorMsg);
        }

        try {
            const caption = decodeURIComponent(metadata.headers['x-goog-meta-caption']) || file.name;
            const anomalyUUID = metadata.headers['x-goog-meta-anomaly_uuid'];

            startUpload(file, caption, anomalyUUID);

            const xhr = new XMLHttpRequest();

            xhrRequests.current.set(file.name, xhr);

            xhr.open('PUT', metadata.url);

            const signedHeaders = new URL(metadata.url).searchParams.get('X-Goog-SignedHeaders');

            if (!signedHeaders) {
                throw new Error('Signed headers are missing in the metadata URL.');
            }

            const requiredHeaders = signedHeaders.split(';');

            xhr.setRequestHeader('Content-Type', file.type);

            requiredHeaders.forEach((header) => {
                if (header.startsWith('x-goog-meta-')) {
                    if (!metadata.headers[header]) {
                        throw new Error(`Missing required header: ${header}.`);
                    }

                    xhr.setRequestHeader(header, metadata.headers[header]);
                }
            });

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;

                    setUploadStates((prev) => {
                        const newMap = new Map(prev);
                        const uploadState = newMap.get(file.name);

                        if (uploadState) {
                            newMap.set(file.name, { ...uploadState, progress });
                        }

                        return newMap;
                    });
                }
            };

            xhr.onload = () => {
                if (xhr.status === 200) {
                    finalizeUpload(file);
                } else {
                    setUploadStates((prev) => {
                        const newMap = new Map(prev);
                        const uploadState = newMap.get(file.name);

                        if (uploadState) {
                            newMap.set(file.name, {
                                ...uploadState,
                                error: `Upload failed with status ${xhr.status}: ${xhr.responseText}`,
                            });
                        }

                        return newMap;
                    });
                }

                xhrRequests.current.delete(file.name);
            };

            xhr.onerror = () => {
                const errorMsg = `Network error during upload of file: ${file.name}`;

                setUploadStates((prev) => {
                    const newMap = new Map(prev);
                    const uploadState = newMap.get(file.name);

                    if (uploadState) {
                        newMap.set(file.name, { ...uploadState, error: errorMsg });
                    }

                    return newMap;
                });
            };

            xhr.send(file);

            return { ok: true };
        } catch (error) {
            setUploadStates((prev) => {
                const newMap = new Map(prev);
                const uploadState = newMap.get(file.name);

                if (uploadState) {
                    newMap.set(file.name, {
                        ...uploadState,
                        error: error instanceof Error ? error.message : String(error),
                    });
                }

                return newMap;
            });

            return { ok: false };
        }
    };

    return {
        cancelUpload,
        finalizeUpload,
        startUpload,
        uploadToGCS,
        uploadStates,
    };
};
