import React from 'react';

import { IconProps } from './icon_definition';

export const SingleUserIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <circle cx="8" cy="5" r="2" fill={color ?? '#222'} />
            <path
                d="M11.9634 12.0019C12.012 12.5521 11.5527 13 11.0005 13C10.0558 13 8.68426 13 8 13C7.31574 13 5.94424 13 4.99954 13C4.44725 13 3.98797 12.5521 4.03659 12.0019C4.17495 10.4365 4.83654 8 8 8C11.1635 8 11.825 10.4365 11.9634 12.0019Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
