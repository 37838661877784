import React from 'react';

import { IconProps } from './icon_definition';

export const Priority1Icon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9627 1.82144C10.7749 1.63418 10.5204 1.52919 10.2552 1.52957L5.72608 1.53613C5.46086 1.53652 5.20666 1.64224 5.0194 1.83005L1.82146 5.03728C1.63419 5.22508 1.5292 5.47959 1.52959 5.74481L1.53615 10.2739C1.53653 10.5392 1.64226 10.7934 1.83007 10.9806L5.03729 14.1786C5.2251 14.3658 5.4796 14.4708 5.74482 14.4704L10.274 14.4639C10.5392 14.4635 10.7934 14.3578 10.9806 14.17L14.1786 10.9627C14.3658 10.7749 14.4708 10.5204 14.4705 10.2552L14.4639 5.72607C14.4635 5.46085 14.3578 5.20665 14.17 5.01938L10.9627 1.82144ZM7.65 5.25C7.586 5.25 7.53 5.26067 7.482 5.282L6.754 5.57C6.68466 5.59134 6.65 5.642 6.65 5.722V6.33C6.65 6.41 6.69 6.45 6.77 6.45H6.802L7.506 6.306C7.522 6.30067 7.53533 6.30334 7.546 6.314C7.55666 6.32467 7.562 6.338 7.562 6.354V10.714C7.562 10.8047 7.60733 10.85 7.698 10.85H8.554C8.64466 10.85 8.69 10.8047 8.69 10.714V5.386C8.69 5.29534 8.64466 5.25 8.554 5.25H7.65Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
