import React from 'react';

import { IconProps } from './icon_definition';

export const CalendarIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 4V3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V4V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V4ZM13 5H3V13H13V5ZM5 10C5.55228 10 6 10.4477 6 11C6 11.5523 5.55228 12 5 12C4.44772 12 4 11.5523 4 11C4 10.4477 4.44772 10 5 10Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
