import React from 'react';

import { IconProps } from './icon_definition';

export const MoreIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="12" y="7" width="2" height="2" rx="1" fill={color ?? '#222'} />
            <rect x="2" y="7" width="2" height="2" rx="1" fill={color ?? '#222'} />
            <rect x="7" y="7" width="2" height="2" rx="1" fill={color ?? '#222'} />
        </svg>
    );
};
