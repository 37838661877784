import { Text } from '@mantine/core';
import { WebMercatorViewport } from 'deck.gl/typed';

import { ElectricalRegionLayer } from 'components/inspections/AnomalyMap/CustomLayers';

export const errorHandlers = async (error: any) => {
    try {
        // Check if error is a Response object with json method
        if (error && typeof error.json === 'function') {
            const errorJSON = await error.json();

            if (errorJSON?.detail && typeof errorJSON?.detail === 'string') {
                return errorJSON.detail;
            } else if (error?.status === 401) {
                return 'You are not authorised to perform that action.';
            } else if (error?.status === 404) {
                return 'The requested page could not be found.';
            }
        }

        // Handle CORS errors or other network errors
        if (error instanceof TypeError && error.message.includes('NetworkError')) {
            return 'Network error: CORS policy may be blocking the request.';
        }

        // Handle other error types
        return error?.message || 'An error occurred. Please try again later or contact support.';
    } catch (e) {
        return 'An error occurred while processing the error response.';
    }
};

// TODO: inline this
export const formatRowHeader = (label: string) => <Text variant="caption1">{label}</Text>;

export const debounce = (func: any, delay = 1000) => {
    let timer: any;

    return (...args: any[]) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => {
            timer = null;
            func.apply(this, args);
        }, delay);
    };
};

export const roundCoord = (coord: number, precision = 5) => Number(coord.toFixed(precision));

/**
 *
 *
 * @param {*} url - the url of the current page
 * @param {*} keys - the keys to extract from the url. (string or array).
 * If the key is an array, the 2nd value should represent the type of the key's value
 * @param {*} storedParams - the currently stored params present in localStorage (if available)
 * @return {*} - object containing all the params in the url
 */
export const getUrlParams = (url: string, keys: string[], storedParams: string | null = null) => {
    const urlParams = new URL(url).searchParams;
    const _storedParams = storedParams ? JSON.parse(storedParams) : {};
    const _params: any = {};

    keys.forEach((key) => {
        const val = urlParams.get(key) || _storedParams[key];

        if (val) {
            _params[key] = val;
        }
    });

    return Object.keys(_params).length > 0 ? _params : null;
};

export const getViewportBounds = (view: any) => {
    const { width, height } = view;

    if (!width) {
        return null;
    }

    const viewport = new WebMercatorViewport(view);

    const topLeft = viewport.unproject([0, 0]);
    const topRight = viewport.unproject([width, 0]);
    const bottomLeft = viewport.unproject([0, height]);
    const bottomRight = viewport.unproject([width, height]);

    return [[topLeft, topRight, bottomRight, bottomLeft, topLeft]];
};

// @ts-ignore
export const buildElectricalRegionLayer = (props: any) => new ElectricalRegionLayer(props);

export const getInitials = (name: string) =>
    name
        .split(' ')
        .slice(0, 2)
        .map((word) => word[0])
        .join('');

/**
 * Truncates a file name to a specified maximum length, preserving the file extension if present.
 *
 * @param name - The original file name.
 * @param maxFileNameLength - The maximum allowed length for the file name.
 * @returns The truncated file name with the extension preserved if applicable.
 *
 * @example
 * ```typescript
 * const truncatedName = truncateFileNameWithExtension('exampleFileName.txt', 10);
 * console.log(truncatedName); // Output: "exampleF....txt"
 * ```
 */
export const truncateFileNameWithExtension = (name: string, maxFileNameLength: number) => {
    const { length } = name;

    if (length <= maxFileNameLength) {
        return name;
    }

    const dotIndex = name.lastIndexOf('.');

    // If no dot is found or it's the first character, there's no valid extension to separate
    if (dotIndex < 1) {
        return `${name.slice(0, maxFileNameLength)}...`;
    }

    const fileExtension = name.slice(dotIndex + 1);
    const fileName = name.slice(0, dotIndex);

    return `${fileName.slice(0, maxFileNameLength)}....${fileExtension}`;
};
