import React from 'react';

import { IconProps } from './icon_definition';

export const LogoutIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M13.5 8L6 8" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 8L11.5 6" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M13.5 8L11.5 10" stroke={color ?? '#222'} strokeLinecap="round" />
            <path
                d="M9.5 6L9.5 3.5C9.5 2.94772 9.05228 2.5 8.5 2.5L3.5 2.5C2.94772 2.5 2.5 2.94772 2.5 3.5L2.5 12.5C2.5 13.0523 2.94772 13.5 3.5 13.5L8.5 13.5C9.05228 13.5 9.5 13.0523 9.5 12.5L9.5 10"
                stroke={color ?? '#222'}
            />
        </svg>
    );
};
