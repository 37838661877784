import { IconProps } from './icon_definition';

export const ChevronDownIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3536 6.14645C11.5488 6.34171 11.5488 6.65829 11.3536 6.85355L8.35355 9.85355C8.15829 10.0488 7.84171 10.0488 7.64645 9.85355C7.45118 9.65829 7.45118 9.34171 7.64645 9.14645L10.6464 6.14645C10.8417 5.95118 11.1583 5.95118 11.3536 6.14645Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.64645 6.14645C4.84171 5.95118 5.15829 5.95118 5.35355 6.14645L8.35355 9.14645C8.54882 9.34171 8.54882 9.65829 8.35355 9.85355C8.15829 10.0488 7.84171 10.0488 7.64645 9.85355L4.64645 6.85355C4.45118 6.65829 4.45118 6.34171 4.64645 6.14645Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
