import React from 'react';

import { IconProps } from './icon_definition';

export const CopiedIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <mask id="path-1Inside-1_2286_259411" fill="white">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3ZM9.85355 7.35355C10.0488 7.15829 10.0488 6.84171 9.85355 6.64645C9.65829 6.45118 9.34171 6.45118 9.14645 6.64645L7.5 8.29289L6.85355 7.64645C6.65829 7.45118 6.34171 7.45118 6.14645 7.64645C5.95118 7.84171 5.95118 8.15829 6.14645 8.35355L7.14645 9.35355C7.34171 9.54882 7.65829 9.54882 7.85355 9.35355L9.85355 7.35355Z"
                />
            </mask>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3ZM9.85355 7.35355C10.0488 7.15829 10.0488 6.84171 9.85355 6.64645C9.65829 6.45118 9.34171 6.45118 9.14645 6.64645L7.5 8.29289L6.85355 7.64645C6.65829 7.45118 6.34171 7.45118 6.14645 7.64645C5.95118 7.84171 5.95118 8.15829 6.14645 8.35355L7.14645 9.35355C7.34171 9.54882 7.65829 9.54882 7.85355 9.35355L9.85355 7.35355Z"
                fill={color ?? '#222'}
            />
            <path
                d="M9.85355 6.64645L9.14645 7.35355L9.14645 7.35355L9.85355 6.64645ZM9.85355 7.35355L9.14645 6.64645L9.14645 6.64645L9.85355 7.35355ZM9.14645 6.64645L9.85355 7.35355L9.85355 7.35355L9.14645 6.64645ZM7.5 8.29289L6.79289 9L7.5 9.70711L8.20711 9L7.5 8.29289ZM6.85355 7.64645L6.14645 8.35355L6.14645 8.35355L6.85355 7.64645ZM6.14645 7.64645L6.85355 8.35355L6.85355 8.35355L6.14645 7.64645ZM6.14645 8.35355L6.85355 7.64645L6.85355 7.64645L6.14645 8.35355ZM7.14645 9.35355L7.85355 8.64645L7.85355 8.64645L7.14645 9.35355ZM7.85355 9.35355L7.14645 8.64645L7.14645 8.64645L7.85355 9.35355ZM4 8C4 5.79086 5.79086 4 8 4V2C4.68629 2 2 4.68629 2 8H4ZM8 12C5.79086 12 4 10.2091 4 8H2C2 11.3137 4.68629 14 8 14V12ZM12 8C12 10.2091 10.2091 12 8 12V14C11.3137 14 14 11.3137 14 8H12ZM8 4C10.2091 4 12 5.79086 12 8H14C14 4.68629 11.3137 2 8 2V4ZM9.14645 7.35355C8.95118 7.15829 8.95118 6.84171 9.14645 6.64645L10.5607 8.06066C11.1464 7.47487 11.1464 6.52513 10.5607 5.93934L9.14645 7.35355ZM9.85355 7.35355C9.65829 7.54882 9.34171 7.54882 9.14645 7.35355L10.5607 5.93934C9.97487 5.35355 9.02513 5.35355 8.43934 5.93934L9.85355 7.35355ZM8.20711 9L9.85355 7.35355L8.43934 5.93934L6.79289 7.58579L8.20711 9ZM6.14645 8.35355L6.79289 9L8.20711 7.58579L7.56066 6.93934L6.14645 8.35355ZM6.85355 8.35355C6.65829 8.54882 6.34171 8.54882 6.14645 8.35355L7.56066 6.93934C6.97487 6.35355 6.02513 6.35355 5.43934 6.93934L6.85355 8.35355ZM6.85355 7.64645C7.04882 7.84171 7.04882 8.15829 6.85355 8.35355L5.43934 6.93934C4.85355 7.52513 4.85355 8.47487 5.43934 9.06066L6.85355 7.64645ZM7.85355 8.64645L6.85355 7.64645L5.43934 9.06066L6.43934 10.0607L7.85355 8.64645ZM7.14645 8.64645C7.34171 8.45118 7.65829 8.45118 7.85355 8.64645L6.43934 10.0607C7.02513 10.6464 7.97487 10.6464 8.56066 10.0607L7.14645 8.64645ZM9.14645 6.64645L7.14645 8.64645L8.56066 10.0607L10.5607 8.06066L9.14645 6.64645Z"
                fill={color ?? '#222'}
                mask="url(#path-1Inside-1_2286_259411)"
            />
        </svg>
    );
};
