import React from 'react';

import { IconProps } from './icon_definition';

export const ImageIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M4 4.5H8H12C12.2761 4.5 12.5 4.72386 12.5 5V6V8V11C12.5 11.2761 12.2761 11.5 12 11.5H4C3.72386 11.5 3.5 11.2761 3.5 11V5C3.5 4.72386 3.72386 4.5 4 4.5Z"
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
            <path
                d="M11 11.5L8 11.5L4.20711 11.5L3.5 10.7929L3.5 10L3.5 9.26759L5.66894 7.82163C5.85011 7.70085 6.08861 7.7112 6.25864 7.84722L7.78457 9.06797C8.16388 9.37141 8.66664 9.4724 9.1337 9.33895L11.5 8.66286L11.5 11C11.5 11.2761 11.2761 11.5 11 11.5Z"
                fill={color ?? '#222'}
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
            <rect x="9" y="6" width="2" height="2" rx="1" fill={color ?? '#222'} />
        </svg>
    );
};
