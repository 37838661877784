import React from 'react';

import { IconProps } from './icon_definition';

export const Priority2Icon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.70711 1.70711C8.31658 1.31658 7.68342 1.31658 7.2929 1.70711L1.70711 7.2929C1.31658 7.68342 1.31658 8.31658 1.70711 8.70711L7.2929 14.2929C7.68342 14.6834 8.31658 14.6834 8.70711 14.2929L14.2929 8.70711C14.6834 8.31658 14.6834 7.68342 14.2929 7.2929L8.70711 1.70711ZM6.12 10.696C6.12 10.7867 6.16533 10.832 6.256 10.832H9.776C9.86667 10.832 9.912 10.7867 9.912 10.696V10C9.912 9.90933 9.86667 9.864 9.776 9.864H7.68C7.65867 9.864 7.64533 9.85867 7.64 9.848C7.63467 9.832 7.64 9.81867 7.656 9.808C7.90133 9.54667 8.02667 9.41333 8.032 9.408C8.55467 8.864 8.92267 8.46133 9.136 8.2C9.36 7.928 9.528 7.67467 9.64 7.44C9.752 7.20533 9.808 6.976 9.808 6.752C9.808 6.45867 9.73067 6.192 9.576 5.952C9.42667 5.712 9.21067 5.52267 8.928 5.384C8.65067 5.24 8.32267 5.168 7.944 5.168C7.60267 5.168 7.29333 5.22667 7.016 5.344C6.744 5.46133 6.52533 5.632 6.36 5.856C6.19467 6.07467 6.104 6.328 6.088 6.616V6.952C6.088 7.04267 6.13333 7.088 6.224 7.088H7.088C7.17867 7.088 7.224 7.04267 7.224 6.952V6.792C7.224 6.59467 7.29067 6.43733 7.424 6.32C7.55733 6.19733 7.73067 6.136 7.944 6.136C8.15733 6.136 8.33067 6.19733 8.464 6.32C8.59733 6.43733 8.664 6.58933 8.664 6.776C8.664 7.03733 8.42133 7.43733 7.936 7.976C7.81333 8.104 7.62933 8.30667 7.384 8.584C7.29867 8.67467 7.13333 8.856 6.888 9.128C6.64267 9.39467 6.41067 9.63733 6.192 9.856C6.144 9.90933 6.12 9.96533 6.12 10.024V10.696Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
