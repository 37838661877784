import React from 'react';

import { ChevronLeft, ChevronRight } from '@dronebase/shared-ui-icons';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';

interface CarouselButtonGroupProps {
    onLeftClick: () => void;
    onRightClick: () => void;
    paginationIndicator: React.ReactNode;
}

interface CarouselButtonProps {
    onClick: () => void;
    icon: React.ReactNode;
}

const CarouselButton: React.FC<CarouselButtonProps> = ({ onClick, icon }) => (
    <Button variant="subtle" onClick={onClick} sx={{ boxShadow: 'none', borderRadius: 0 }}>
        {icon}
    </Button>
);

const CarouselButtonGroup: React.FC<CarouselButtonGroupProps> = ({
    onLeftClick,
    onRightClick,
    paginationIndicator,
}) => {
    useHotkeys([
        ['ArrowLeft', onLeftClick],
        ['ArrowRight', onRightClick],
    ]);

    return (
        <Flex justify="center" w="100%" align="center" pos="absolute" bottom="1rem">
            <Box
                bg="gray.7"
                display="flex"
                p="xs"
                sx={{
                    alignItems: 'center',
                    borderRadius: '0.5rem',
                }}
            >
                <CarouselButton onClick={onLeftClick} icon={<ChevronLeft />} />
                <Text variant="body1" color="gray.1" miw={1}>
                    {paginationIndicator}
                </Text>
                <CarouselButton onClick={onRightClick} icon={<ChevronRight />} />
            </Box>
        </Flex>
    );
};

export default CarouselButtonGroup;
