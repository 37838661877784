import React from 'react';

import { IconProps } from './icon_definition';

export const ChevronUpIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.64645 9.85355C4.45118 9.65829 4.45118 9.34171 4.64645 9.14645L7.64645 6.14645C7.84171 5.95118 8.15829 5.95118 8.35355 6.14645C8.54882 6.34171 8.54882 6.65829 8.35355 6.85355L5.35355 9.85355C5.15829 10.0488 4.84171 10.0488 4.64645 9.85355Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3536 9.85355C11.1583 10.0488 10.8417 10.0488 10.6464 9.85355L7.64645 6.85355C7.45118 6.65829 7.45118 6.34171 7.64645 6.14645C7.84171 5.95118 8.15829 5.95118 8.35355 6.14645L11.3536 9.14645C11.5488 9.34171 11.5488 9.65829 11.3536 9.85355Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
