import React from 'react';

import { IconProps } from './icon_definition';

export const CheckboxIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M6.5 7.5L8.5 9.5L12.5 5.5" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M10 4.5H5.5C4.94772 4.5 4.5 4.94772 4.5 5.5V10.5C4.5 11.0523 4.94772 11.5 5.5 11.5H10.5C11.0523 11.5 11.5 11.0523 11.5 10.5V9.5"
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
        </svg>
    );
};
