import { gql } from 'urql';

import { ANOMALY_FILE_CATEGORIES } from 'lib/constants';

export const GET_PERFORMACE_RANGES_FOR_INSPECTION_UUID = gql`
    query GetPerformanceGradeForInspectionUuid($superReportTaskUuid: uuid!, $siteCapacity: Float!) {
        superReportTask: tasking_super_report_tasks_by_pk(uuid: $superReportTaskUuid) {
            performanceRating: performance_rating {
                ranges: performance_ranges(
                    where: {
                        performance_dc_range: {
                            size_lower: { _lte: $siteCapacity }
                            size_upper: { _gt: $siteCapacity }
                        }
                    }
                    order_by: { grade: asc }
                ) {
                    grade
                    bosLower: bos_lower
                    bosUpper: bos_upper
                    majorLower: major_lower
                    majorUpper: major_upper
                    minorLower: minor_lower
                    minorUpper: minor_upper
                }
            }
        }
    }
`;

export const GET_REPORT_ANOMALIES = gql`
    query GetAssetRegionsWithAnomalies($superAnalysisTaskUUIDs: [uuid!]!) {
        aois: analysis_areas_of_interest(where: { super_analysis_task_uuid: { _in: $superAnalysisTaskUUIDs } }) {
            uuid
            geometry
            aoiAssetRegions: aoi_asset_regions {
                assetRegionUUID: asset_region_uuid
                assetRegion: asset_region {
                    stack: nom_stack
                    uuid
                    inverterId: nom_properties(path: "nom_inverter")
                    combinerId: nom_properties(path: "nom_combiner")
                    positionId: nom_properties(path: "['Position ID']")
                }
            }
            anomalyEntries: anomaly_entries {
                uuid
                definition_uuid
                statusId: client_status_id
            }
        }
    }
`;

export const GET_INSPECTION_MODULE_COUNT = gql`
    query GetInspectionModuleCount($superReportTaskUUID: uuid!) {
        assetRegionsAggregate: inspections_report_summaries(
            where: { super_report_task_uuid: { _eq: $superReportTaskUUID } }
        ) {
            activeModuleCount: active_module_count
        }
    }
`;

export const GET_INSPECTION_OBSERVATION_AOIS = gql`
    query GetInspectionObservationAOIs($superReportTaskUUID: uuid!) {
        inspectionsReportObservations: inspections_report_observations(
            where: { super_report_task_uuid: { _eq: $superReportTaskUUID } }
        ) {
            uuid
            reportObservationAois: report_observation_aois {
                aoiUuid: aoi_uuid
            }
        }
    }
`;

export const GET_SITE_GEOMETRY = gql`
    query GetSiteGeometry($siteUuid: uuid!) {
        site: sites_sites_by_pk(uuid: $siteUuid) {
            geometry
        }
    }
`;

export const GET_HEX_DATA_BY_SUPER_REPORT_TASK = gql`
    query GetAnomalyAggregationHexagons($superReportTaskUuid: uuid!) {
        diagnosticAnomalyAggregations: inspections_report_diagnostic_anomaly_aggregations(
            where: { super_report_task_uuid: { _eq: $superReportTaskUuid } }
        ) {
            affectedModuleCount: affected_module_count
            aggregationGeometry: aggregation_geometry {
                geometry
                diagnosticId: diagnostic_id
            }
            uuid
            performanceQuartile: performance_quartile
            anomalyCount: anomaly_count
            modulesAnalyzed: modules_analyzed
            definition {
                globalDefinition: global_definition {
                    nameExternal: name_external
                }
            }
        }
    }
`;

// Split this into two 'get present ir/viz signals'
export const GET_PRESENT_IR_RGB_SIGNALS = gql`
    query GetSignalsInReport($superReportTaskUuid: uuid!) {
        irSignals: inspections_report_signal_aggregations(
            where: { super_report_task_uuid: { _eq: $superReportTaskUuid }, signal: { signal_type: { _eq: "IR" } } }
            order_by: { anomaly_count: desc }
        ) {
            signal {
                name
                code
            }
            anomalyCount: anomaly_count
        }
        rgbSignals: inspections_report_signal_aggregations(
            where: { super_report_task_uuid: { _eq: $superReportTaskUuid }, signal: { signal_type: { _eq: "RGB" } } }
            order_by: { anomaly_count: desc }
        ) {
            signal {
                name
                code
            }
            anomalyCount: anomaly_count
        }
    }
`;

export const GET_ANOMALIES_BY_ASSET_REGION = (querySource: string) => {
    let properties = '';

    if (querySource === 'tooltip') {
        properties = `
            uuid
            definition: anomaly_definition {
                nameExternal: name_external
            }
            aoi: area_of_interest {
                legacyAnomaly: legacy_anomaly {
                    moduleId: module_id
                }
            }
        `;
    } else if (querySource === 'modal') {
        properties = `
        uuid
        anomalyStatus: client_status {
            name
            id
        }
        createdAt: created_at
        updatedAt: updated_at
        clientUpdatedAt: client_updated_at
        anomalyDefinition: anomaly_definition {
            priority
            globalDefinition: global_definition {
                anomalyDefinitionName: name_external
            }
            definitionRemediations: definition_remediations {
                remediation {
                    nameExternal: name_external
                }
            }
            description
            species {
                uuid
                genus {
                    parentAnomalyName: name_external
                }
                ir_signal
                rgb_signal
            }
            subsystem {
                subsystemName: name
            }
        }
        aoi: area_of_interest {
            legacyAnomaly: legacy_anomaly {
                moduleId: module_id
                irSampleImages: ir_sample_images {
                    url: public_gcp_url
                }
                vizSampleImages: viz_sample_images {
                    url: public_gcp_url
                }
                images {
                    imageType: image_type
                    url: public_gcp_url
                }
            }
            superAnalysisTask: super_analysis_task {
                superReportTaskSuperAnalysisTasks: super_report_task_super_analysis_tasks {
                    superReportTaskUuid: super_report_task_uuid
                }
            }
        }
        images {
            imageType: image_type
            blobImage: blob_image {
                blobInstanceIndex: blob_instance_index {
                    url
                }
            }
        }
        aoiAssetRegions: aoi_asset_regions {
            assetRegion: asset_region {
                nom_module
                nom_row
                nom_set
                nom_stack
            }
        }
        `;
    }

    return gql`
        query GetAnomaliesOnAssetRegionByReport($assetRegionUuid: uuid!, $superAnalysisTaskUUIDs: [uuid!]!) {
            anomalyEntries: analysis_anomaly_entries(
                where: {
                    area_of_interest: {
                        aoi_asset_regions: { asset_region_uuid: { _eq: $assetRegionUuid } }
                        super_analysis_task_uuid: {_in: $superAnalysisTaskUUIDs}
                    }
                },
                order_by: [
                    { anomaly_definition: { priority: asc } },
                    { anomaly_definition: { species: { name_external: asc } } }
                ]
            ) {
                ${properties}
            }
        }
    `;
};

export const GET_ANOMALY_IMAGES_AND_FILES = gql`
    query GetAnomalyImages($siteUuid: uuid!, $anomalyUuid: uuid!, $superReportTaskUuid: uuid!) {
        reedling_index_blob_instance_index(
            where: {
                blob_indices: {
                    blob_index_bond: {
                        site_uuid: { _eq: $siteUuid }
                        anomaly_uuid: { _eq: $anomalyUuid }
                        super_report_task_uuid: { _eq: $superReportTaskUuid }
                    }
                }
            }
            order_by: { blob_index: { blob_created_at: desc } }
        ) {
            url
            caption
            blob_index {
                type
                size_bytes
                object_name
                is_internal
                blob_created_at
                uuid
                blob_index_bonds {
                    anomaly_uuid
                    user_uuid
                    user {
                        email
                    }
                    blob_category {
                        name
                        description
                    }
                }
            }
        }
    }
`;

export const GET_REPORT_TYPES = gql`
    query GetReportTypes {
        inspectionReportTypes: inspections_report_types {
            nameExternal: name_external
            uuid
        }
    }
`;

export const GET_ANALYSIS_CLIENT_STATUS = gql`
    query GetAnalysisClientStatus {
        analysis_client_status(order_by: { id: asc }) {
            id
            name
        }
    }
`;

export const GET_WORKSPACE_APPLICATIONS = gql`
    query WorkspaceApplications($workspaceId: uuid!) {
        applications: auth_workspace_applications(where: { workspace_uuid: { _eq: $workspaceId } }) {
            application {
                name
            }
        }
    }
`;

export const GET_INSPECTION_SUMMARY = gql`
    query GetInspectionSummary($inspectionID: uuid!, $workspaceID: uuid!) {
        superReportTasks: tasking_super_report_tasks(
            where: { uuid: { _eq: $inspectionID }, super_task: { workspace_uuid: { _eq: $workspaceID } } }
        ) {
            inspectionDate: inspection_date
            description: summary_description
            reportType: report_type {
                uuid
                nameExternal: name_external
            }
            inspectionSummary: report_summary {
                powerLoss: power_loss_watts
                bosGrade: bos_grade
                majorGrade: major_grade
                minorGrade: minor_grade
                minor: minor_module_count
                major: major_module_count
                bos: bos_module_count
                activeModules: active_module_count
                bosPowerLoss: bos_power_loss_watts
                majorPowerLoss: major_power_loss_watts
                minorPowerLoss: minor_power_loss_watts
                superReportTaskUuid: super_report_task_uuid
                uuid
            }
            superReportSuperAnalysisTasks: super_report_task_super_analysis_tasks {
                superAnalysisTask: super_analysis_task {
                    superTask: super_task {
                        draftVersionUUID: draft_version_uuid
                    }
                }
            }
            superAnalysisTaskCount: super_report_task_super_analysis_tasks_aggregate {
                aggregate {
                    count
                }
            }
            upgrade
            upgradeExpiresAt: upgrade_expires_at
            reportUpgrades: report_upgrades(where: { super_report_task_uuid: { _eq: $inspectionID } }) {
                reportTypeUuid: report_type_uuid
            }
        }
    }
`;

const ANOMALY_SUMMARY_FRAGMENT = `
anomalySummary: inspections_report_definition_aggregation(
    where: { super_report_task_uuid: { _eq: $uuid } }
    order_by: { anomaly_count: desc }
) {
    affectedModuleCount: affected_module_count
    anomalyCount: anomaly_count
    definition {
        uuid
        globalDefinition: global_definition {
            nameExternal: name_external
        }
        species {
            uuid
            irSignal: ir_signal
            irModifier: ir_modifier
            rgbSignal: rgb_signal
            rgbModifier: rgb_modifier
        }
        priority
        energyLossMid: energy_loss_mid
    }
}
`;

export const GET_REPORT_ANOMALY_SUMMARY = gql`
query GetReportAnomalySummary($uuid: uuid!) {
    ${ANOMALY_SUMMARY_FRAGMENT}
}
`;

export const GET_REPORT_ANOMALY_SUMMARY_WITH_SATS = gql`
    query GetReportAnomalySummaryWithSATs($uuid: uuid!) {
        ${ANOMALY_SUMMARY_FRAGMENT}
        superAnalysisTasks: tasking_super_report_task_super_analysis_tasks(
            where: { super_report_task_uuid: { _eq: $uuid } }
        ) {
            superAnalysisTaskUUID: super_analysis_task_uuid
        }
    }
`;

export const GET_INDEPENDENT_ANOMALY_SPECIES = gql`
    query GetAllIndependentAnomalySpecies {
        anomalySpecies: anomaly_species(
            where: {
                deleted_at: { _is_null: true }
                _or: [
                    { ir_signal: { _neq: "n/a" }, rgb_signal: { _eq: "n/a" }, rgb_modifier: { _eq: "n/a" } }
                    { rgb_signal: { _neq: "n/a" }, ir_signal: { _eq: "n/a" }, ir_modifier: { _eq: "n/a" } }
                ]
            }
        ) {
            rgbModifier: rgb_modifier
            rgbSignal: rgb_signal
            nameExternal: name_external
            irSignal: ir_signal
            irModifier: ir_modifier
        }
    }
`;

export const GET_ASSETS_BY_TYPE_AND_SITE = gql`
    query GetAssetGeometriesAndLabels($inspectionId: uuid!, $assetRegionTypeUuids: [uuid!]!) {
        reportTask: tasking_super_report_tasks_by_pk(uuid: $inspectionId) {
            superTask: super_task {
                draftVersion: draft_version {
                    assetRegions: asset_regions(where: { asset_region_type_uuid: { _in: $assetRegionTypeUuids } }) {
                        uuid
                        geometry
                        nomProperties: nom_properties
                        nomStack: nom_stack
                    }
                }
            }
        }
    }
`;

export const GET_KEY_OBSERVATION_IMAGE = gql`
    query GetKeyObservationImage($uuid: uuid!) {
        keyObservationImageResponse: analysis_anomaly_entries(where: { uuid: { _eq: $uuid } }) {
            aoi: area_of_interest {
                legacy: legacy_anomaly {
                    default: images {
                        public_gcp_url
                    }
                    ir: ir_sample_images {
                        public_gcp_url
                    }
                    viz: viz_sample_images {
                        public_gcp_url
                    }
                }
            }
        }
    }
`;

// Files with categoryName are user-uploaded anomaly files and shouldn't be shown
export const GET_INSPECTION_DOWNLOADS = gql`
    query GetInspectionDownloads($superReportTaskUuid: uuid!) {
        blobs: reedling_index_blob_index_bonds(
            where: {
                super_report_task_uuid: { _eq: $superReportTaskUuid }
                blob_index: { is_internal: { _eq: false } }
                _or: [
                    {category_name: {_nin: ${JSON.stringify(ANOMALY_FILE_CATEGORIES)}}},
                    {category_name: {_is_null: true}}
                ]
            }
        ) {
            categoryName: category_name
            blobIndex: blob_index {
                objectName: object_name
                blobInstance: blob_instance_index {
                    url
                }
            }
        }
    }
`;

export const GET_ANOMALY_DATA_CSV_URL = gql`
    query GetAnomalyDataCsvUrl($searchString: String!, $altSearchString: String!, $workspaceId: uuid!) {
        reedling_index_blob_index(
            where: {
                workspace_uuid: { _eq: $workspaceId }
                _or: [{ object_name: { _like: $searchString } }, { object_name: { _like: $altSearchString } }]
            }
            order_by: { blob_instance_index: { updated_at: desc } }
        ) {
            blobInstance: blob_instance_index {
                url
            }
        }
    }
`;

export const GET_INSPECTION_OBSERVATIONS = gql`
    query GetReportObservations($superReportTaskUUID: uuid!) {
        observations: inspections_report_observations(
            where: { super_report_task_uuid: { _eq: $superReportTaskUUID } }
            order_by: [{ priority: asc }, { uuid: asc }]
        ) {
            uuid
            description: description_html
            observationAOIs: report_observation_aois(limit: 1) {
                uuid
            }
            observationBlobs: report_observation_blobs {
                blob {
                    blobInstance: blob_instance_index {
                        url
                    }
                }
            }
        }
    }
`;

// TODO: Check if this query can be updated to pre-filter the qualifying differential inspection
export const GET_PREVIOUS_SITE_INSPECTIONS = gql`
    query GetPreviousSiteInspections(
        $siteId: uuid!
        $inspectionDate: timestamp
        $orderBy: [tasking_site_report_listing_view_order_by!] = { inspection_date: desc }
    ) {
        inspections: tasking_site_report_listing_view(
            where: { site_uuid: { _eq: $siteId }, inspection_date: { _lt: $inspectionDate } }
            order_by: $orderBy
        ) {
            publishDate: inspection_date
            reportType: report_type
            inspectionID: super_report_task_uuid
            powerLoss: power_loss_watts
            bosGrade: bos_grade
            majorGrade: major_grade
            minorGrade: minor_grade
            minor: minor_module_count
            major: major_module_count
            bos: bos_module_count
            activeModules: active_module_count
            superAnalysisTasks: super_analysis_tasks {
                superAnalysisTaskUUID: super_analysis_task_uuid
            }
            superAnalysisTasksCount: super_analysis_tasks_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`;
