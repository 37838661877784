import React from 'react';

import { IconProps } from './icon_definition';

export const ShowIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99999 5C5.55841 5 4.44157 6.32473 3.57131 7.35698C3.49162 7.45151 3.41399 7.54358 3.33761 7.63197C3.15704 7.84089 3.15704 8.15911 3.33761 8.36803C3.41399 8.45641 3.49162 8.54849 3.57131 8.64302C4.44157 9.67527 5.55841 11 7.99999 11C10.4416 11 11.5584 9.67527 12.4287 8.64302L12.4287 8.64293C12.5084 8.54843 12.586 8.45639 12.6624 8.36803C12.8429 8.15911 12.8429 7.84089 12.6624 7.63197C12.586 7.54361 12.5084 7.45157 12.4287 7.35707L12.4287 7.35698C11.5584 6.32474 10.4416 5 7.99999 5ZM9.99999 8C9.99999 9.10457 9.10456 10 7.99999 10C6.89542 10 5.99999 9.10457 5.99999 8C5.99999 6.89543 6.89542 6 7.99999 6C9.10456 6 9.99999 6.89543 9.99999 8ZM8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
