import React from 'react';

import { IconProps } from './icon_definition';

export const LaptopIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M4.5 4.5H11.5V8.5H4.5V4.5Z" stroke={color ?? '#222'} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 10H4.00001L3.042 11.437C3.01462 11.4781 3.00001 11.5263 3.00001 11.5757V11.75C3.00001 11.8881 3.11194 12 3.25001 12H12.75C12.8881 12 13 11.8881 13 11.75V11.5757C13 11.5263 12.9854 11.4781 12.958 11.437L12 10ZM7.04143 10.5C7.01491 10.5 6.98948 10.5105 6.97072 10.5293L6.67072 10.8293C6.60773 10.8923 6.65234 11 6.74143 11H9.25859C9.34768 11 9.3923 10.8923 9.3293 10.8293L9.0293 10.5293C9.01055 10.5105 8.98511 10.5 8.95859 10.5H7.04143Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
