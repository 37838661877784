import React from 'react';

import { IconProps } from './icon_definition';

export const DiagnosticPlusIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="2.5" y="2.5" width="11" height="11" rx="0.5" stroke={color ?? '#222'} />
            <circle cx="10" cy="4" r="2" fill={color ?? '#222'} />
            <path d="M4.00002 13.5L6.53576 7.8143" stroke={color ?? '#222'} />
            <path d="M7 7.8L9.53318 13.4867" stroke={color ?? '#222'} />
            <path d="M6.74999 6L7.83253 8.45H5.70705L6.74999 6Z" fill={color ?? '#222'} />
        </svg>
    );
};
