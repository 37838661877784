import React from 'react';

import { IconProps } from './icon_definition';

export const UpgradeIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.27553 2.56922C7.21475 2.38688 6.95684 2.38688 6.89606 2.56922L6.4468 3.91698C6.04866 5.1114 5.1114 6.04866 3.91698 6.4468L2.56922 6.89606C2.38688 6.95684 2.38688 7.21475 2.56922 7.27553L3.91698 7.72479C5.1114 8.12293 6.04866 9.06019 6.4468 10.2546L6.89606 11.6024C6.95684 11.7847 7.21475 11.7847 7.27553 11.6024L7.72479 10.2546C8.12293 9.06019 9.06019 8.12293 10.2546 7.72479L11.6024 7.27553C11.7847 7.21475 11.7847 6.95684 11.6024 6.89606L10.2546 6.4468C9.06019 6.04866 8.12293 5.1114 7.72479 3.91698L7.27553 2.56922ZM11.2969 9.37585C11.2567 9.25545 11.0864 9.25545 11.0463 9.37585C10.7834 10.1645 10.1645 10.7834 9.37585 11.0463C9.25545 11.0864 9.25545 11.2567 9.37585 11.2969C10.1645 11.5598 10.7834 12.1786 11.0463 12.9673C11.0864 13.0877 11.2567 13.0877 11.2969 12.9673C11.5597 12.1786 12.1786 11.5598 12.9673 11.2969C13.0877 11.2567 13.0877 11.0864 12.9673 11.0463C12.1786 10.7834 11.5597 10.1645 11.2969 9.37585Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
