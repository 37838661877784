import React from 'react';

import { IconProps } from './icon_definition';

export const SolarSitesIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M11 1V2" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M15 5L14 5" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M8 5L7 5" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path
                d="M13.8285 7.82849L13.1214 7.12138"
                stroke={color ?? '#222'}
                strokeWidth="0.5"
                strokeLinecap="round"
            />
            <path
                d="M8.87874 2.87872L8.17163 2.17162"
                stroke={color ?? '#222'}
                strokeWidth="0.5"
                strokeLinecap="round"
            />
            <path
                d="M13.1213 2.87872L13.8284 2.17162"
                stroke={color ?? '#222'}
                strokeWidth="0.5"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.63714 7.34285C1.57145 7.17863 1.69239 7 1.86926 7H10.8307C10.933 7 11.0249 7.06224 11.0629 7.15715L12.8629 11.6572C12.9285 11.8214 12.8076 12 12.6307 12H3.66926C3.56703 12 3.47511 11.9378 3.43714 11.8428L1.63714 7.34285ZM1.86926 6H10.8307C11.3419 6 11.8015 6.31119 11.9913 6.78576L13.7913 11.2858C14.1198 12.1068 13.5151 13 12.6307 13H3.66926C3.15813 13 2.69849 12.6888 2.50866 12.2142L0.708664 7.71424C0.380232 6.89316 0.984926 6 1.86926 6Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.384 6.44378C12.7637 6.07977 13 5.56749 13 5C13 3.89543 12.1046 3 11 3C9.89543 3 9 3.89543 9 5C9 5.17265 9.02188 5.34019 9.06301 5.5H10.8307C11.4886 5.5 12.0855 5.86818 12.384 6.44378ZM11.9749 6.74669C11.776 6.29418 11.3277 6 10.8307 6H9.26756C9.61337 6.5978 10.2597 7 11 7C11.354 7 11.6865 6.90803 11.9749 6.74669Z"
                fill={color ?? '#222'}
            />
            <path d="M4.5 7L6.5 12M8 7L10 12" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
        </svg>
    );
};
