import React from 'react';

import { IconProps } from './icon_definition';

export const ZoomOnSelectedIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M3 6.5H8H13C13.2761 6.5 13.5 6.72386 13.5 7V8V9C13.5 9.27614 13.2761 9.5 13 9.5H3C2.72386 9.5 2.5 9.27614 2.5 9V7C2.5 6.72386 2.72386 6.5 3 6.5Z"
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.85355 2.64645C6.65829 2.45118 6.34171 2.45118 6.14645 2.64645C5.95118 2.84171 5.95118 3.15829 6.14645 3.35355L7.64645 4.85355C7.84171 5.04882 8.15829 5.04882 8.35355 4.85355L9.85355 3.35355C10.0488 3.15829 10.0488 2.84171 9.85355 2.64645C9.65829 2.45118 9.34171 2.45118 9.14645 2.64645L8 3.79289L6.85355 2.64645Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.14645 13.3536C9.34171 13.5488 9.65829 13.5488 9.85355 13.3536C10.0488 13.1583 10.0488 12.8417 9.85355 12.6464L8.35355 11.1464C8.15829 10.9512 7.84171 10.9512 7.64645 11.1464L6.14645 12.6464C5.95118 12.8417 5.95118 13.1583 6.14645 13.3536C6.34171 13.5488 6.65829 13.5488 6.85355 13.3536L8 12.2071L9.14645 13.3536Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
