import React from 'react';

import { IconProps } from './icon_definition';

export const LocationIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 14C8 14 12 11 12 7C12 4.79086 10.2091 3 8 3C5.79086 3 4 4.79086 4 7C4 11 8 14 8 14ZM8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
