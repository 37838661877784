import React from 'react';

import { IconProps } from './icon_definition';

export const CalendarCompleteIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 5H3V13H13V5ZM2 4V3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V4V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V4Z"
                fill={color ?? '#222'}
            />
            <path d="M6 8.5L7.5 10L10.5 7" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
