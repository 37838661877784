import React from 'react';

import { IconProps } from './icon_definition';

export const FilterIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <line x1="2.5" y1="4.5" x2="13.5" y2="4.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <line x1="4.5" y1="8" x2="11.5" y2="8" stroke={color ?? '#222'} strokeLinecap="round" />
            <line x1="6.5" y1="11.5" x2="9.5" y2="11.5" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
