import React from 'react';

import { IconProps } from './icon_definition';

export const ArrowRightIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 8C2.5 7.72386 2.72386 7.5 3 7.5H13C13.2761 7.5 13.5 7.72386 13.5 8C13.5 8.27614 13.2761 8.5 13 8.5H3C2.72386 8.5 2.5 8.27614 2.5 8Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.64645 4.64645C9.84171 4.45118 10.1583 4.45118 10.3536 4.64645L13.3536 7.64645C13.5488 7.84171 13.5488 8.15829 13.3536 8.35355C13.1583 8.54882 12.8417 8.54882 12.6464 8.35355L9.64645 5.35355C9.45118 5.15829 9.45118 4.84171 9.64645 4.64645Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.64645 11.3536C9.45118 11.1583 9.45118 10.8417 9.64645 10.6464L12.6464 7.64645C12.8417 7.45118 13.1583 7.45118 13.3536 7.64645C13.5488 7.84171 13.5488 8.15829 13.3536 8.35355L10.3536 11.3536C10.1583 11.5488 9.84171 11.5488 9.64645 11.3536Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
