import React from 'react';

import { IconProps } from './icon_definition';

export const TagIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7071 3.70711C11.5196 3.51957 11.2652 3.41421 11 3.41421L9 3.41421C8.73478 3.41421 8.48043 3.51957 8.29289 3.70711L3.63604 8.36396C3.24551 8.75449 3.24551 9.38765 3.63604 9.77817L6.46447 12.6066C6.85499 12.9971 7.48816 12.9971 7.87868 12.6066L12.5355 7.94975C12.7231 7.76221 12.8284 7.50786 12.8284 7.24264L12.8284 5.24264C12.8284 4.97742 12.7231 4.72307 12.5355 4.53553L11.7071 3.70711ZM10 6.24264C10.3905 6.63316 11.0237 6.63316 11.4142 6.24264C11.8047 5.85212 11.8047 5.21895 11.4142 4.82843C11.0237 4.4379 10.3905 4.4379 10 4.82843C9.60948 5.21895 9.60948 5.85212 10 6.24264Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
