import React from 'react';

import { IconProps } from './icon_definition';

export const CombinerIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M4.5 11L5.5 12L4.5 13" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.5 3L5.5 4L4.5 5" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 6H13.5C14.3284 6 15 6.67157 15 7.5V8.5C15 9.32843 14.3284 10 13.5 10H12.5C11.6716 10 11 9.32843 11 8.5V7.5C11 6.67157 11.6716 6 12.5 6ZM12.5 7C12.2239 7 12 7.22386 12 7.5V8.5C12 8.77614 12.2239 9 12.5 9H13.5C13.7761 9 14 8.77614 14 8.5V7.5C14 7.22386 13.7761 7 13.5 7H12.5Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.58579 3.5H2V4.5H3.58579L4.08579 4L3.58579 3.5ZM6.36631 4.5H7C7.13088 4.5 7.21753 4.53341 7.2867 4.58174C7.36279 4.63491 7.44297 4.72445 7.52489 4.87011C7.69529 5.17308 7.80888 5.59245 7.94748 6.10416L7.95468 6.13074C8.08587 6.615 8.24285 7.18969 8.51993 7.63791C8.60197 7.77063 8.69667 7.89546 8.80568 8.00673C8.69988 8.12023 8.61157 8.24499 8.53686 8.37306C8.28178 8.81035 8.14224 9.36902 8.02452 9.84034L8.02452 9.84035L8.01493 9.87873C7.88471 10.3996 7.77482 10.8223 7.59936 11.1231C7.51707 11.2641 7.43328 11.3538 7.34826 11.4091C7.26807 11.4613 7.16132 11.5 7 11.5H6.36631C6.54456 11.8084 6.54456 12.1916 6.36631 12.5H7C7.33868 12.5 7.63818 12.4137 7.89393 12.2471C8.14484 12.0837 8.32668 11.8609 8.46314 11.6269C8.71822 11.1897 8.85776 10.631 8.97548 10.1596L8.98507 10.1213C9.11529 9.6004 9.22518 9.17772 9.40064 8.87694C9.48293 8.73587 9.56672 8.64624 9.65174 8.59087C9.73193 8.53866 9.83868 8.5 10 8.5H12V7.5H10C9.71472 7.5 9.53539 7.37878 9.37053 7.11209C9.18398 6.81031 9.0596 6.385 8.91989 5.86926L8.89647 5.7826C8.77301 5.32498 8.63007 4.7952 8.39649 4.37989C8.2675 4.15055 8.09643 3.92759 7.85945 3.76201C7.61555 3.59159 7.32731 3.5 7 3.5H6.36631C6.54456 3.80839 6.54456 4.19161 6.36631 4.5ZM2 11.5H3.58579L4.08579 12L3.58579 12.5H2V11.5Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
