import { useState, useEffect } from 'react';

import { Modal, Group, Button, Stack, Radio, Text } from '@mantine/core';

import { UpdateScope } from 'lib/constants/inspections';
import { getScopeComponentName } from 'lib/inspections/electricalComponents';

interface MultiModuleUpdateModalProps {
    opened: boolean;
    onClose: () => void;
    onConfirm: (scope: UpdateScope) => void;
    moduleCountsByScope: {
        inverter?: number;
        combiner?: number;
        stack?: number;
    };
    hasInverterEncoding: boolean;
    hasCombinerEncoding: boolean;
    // Component identifiers
    componentIds?: {
        inverterId?: string;
        combinerId?: string;
        stackId?: string;
    };
    initialScope?: UpdateScope;
}

const MultiModuleUpdateModal = ({
    opened,
    onClose,
    onConfirm,
    moduleCountsByScope,
    hasInverterEncoding,
    hasCombinerEncoding,
    componentIds,
    initialScope,
}: MultiModuleUpdateModalProps) => {
    // Default to initialScope if provided, otherwise use the broadest scope
    const getDefaultScope = () => {
        if (initialScope) {
            return initialScope;
        }

        if (hasInverterEncoding && moduleCountsByScope.inverter && moduleCountsByScope.inverter > 1) {
            return UpdateScope.INVERTER;
        } else if (moduleCountsByScope.stack && moduleCountsByScope.stack > 1) {
            return UpdateScope.STACK;
        }

        return UpdateScope.SINGLE;
    };

    const [selectedScope, setSelectedScope] = useState<UpdateScope>(getDefaultScope());

    // Reset selection when modal opens
    useEffect(() => {
        if (opened) {
            setSelectedScope(getDefaultScope());
        }
    }, [opened]);

    // Helper function to format combiner label with both combiner and inverter IDs
    const getCombinerLabel = (combinerId?: string, inverterId?: string) => {
        if (combinerId && inverterId) {
            return ` (Combiner: ${combinerId}, Inverter: ${inverterId})`;
        } else if (combinerId) {
            return ` (${combinerId})`;
        }

        return '';
    };

    const getOptions = () => {
        const options = [];

        // Add inverter option if available
        if (hasInverterEncoding && moduleCountsByScope.inverter && moduleCountsByScope.inverter > 1) {
            options.push({
                value: UpdateScope.INVERTER,
                label: `${moduleCountsByScope.inverter} modules affected by the same anomaly within the same ${getScopeComponentName(UpdateScope.INVERTER)}${componentIds?.inverterId ? ` (${componentIds.inverterId})` : ''}`,
            });
        }

        // Always add stack option if available (even if inverter is available)
        if (moduleCountsByScope.stack && moduleCountsByScope.stack > 1) {
            options.push({
                value: UpdateScope.STACK,
                label: `${moduleCountsByScope.stack} modules affected by the same anomaly within the same ${getScopeComponentName(UpdateScope.STACK)}${componentIds?.stackId ? ` (${componentIds.stackId})` : ''}`,
            });
        }

        // Add combiner option if available
        if (hasCombinerEncoding && moduleCountsByScope.combiner && moduleCountsByScope.combiner > 1) {
            options.push({
                value: UpdateScope.COMBINER,
                label: `${moduleCountsByScope.combiner} modules affected by the same anomaly within the same ${getScopeComponentName(UpdateScope.COMBINER)}${getCombinerLabel(componentIds?.combinerId, componentIds?.inverterId)}`,
            });
        }

        // String option has been removed per updated requirements

        // Always add single module option
        options.push({ value: UpdateScope.SINGLE, label: 'Selected module only' });

        return options;
    };

    const handleConfirm = () => {
        onConfirm(selectedScope);
        onClose();
    };

    return (
        <Modal opened={opened} onClose={onClose} title="Status Update" size="md">
            <Stack spacing="md">
                <Text size="md">Select what modules should be updated.</Text>
                <Radio.Group value={selectedScope} onChange={(value) => setSelectedScope(value as UpdateScope)}>
                    <Stack spacing="xs">
                        {getOptions().map((option) => (
                            <Radio key={option.value} value={option.value} label={option.label} />
                        ))}
                    </Stack>
                </Radio.Group>
                <Group position="right" mt="md" spacing="sm">
                    <Button variant="outline" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm}>Confirm</Button>
                </Group>
            </Stack>
        </Modal>
    );
};

export default MultiModuleUpdateModal;
