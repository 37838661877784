import React from 'react';

import { IconProps } from './icon_definition';

export const ArrowExpandIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M3.5 3.5L7 7" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M3.5 3.50001L3.5 6.50001" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M3.5 3.5L6.5 3.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M12.5 12.5L9 9" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M12.5 12.5L12.5 9.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M12.5 12.5L9.5 12.5" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
