import { Text } from '@mantine/core';
import { CellContext } from '@tanstack/react-table';

import { REPORT_TYPES, SPECIAL_GI_REPORT_SRTS } from 'lib/constants';
import { formatRowHeader } from 'lib/helpers';

export const tableColumns = [
    {
        header: formatRowHeader('Inspection Date'),
        accessorKey: 'publishDate',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Inspection Type'),
        accessorKey: 'inspectionType',
        cell: (info: CellContext<{ publishDate: { props: { to: string } } }, unknown>) => {
            const value = info.getValue();
            const srt = info.row.original.publishDate.props.to.split('/')[0];

            if (SPECIAL_GI_REPORT_SRTS.includes(srt)) {
                return <Text c="#5a6771">{REPORT_TYPES.GLASS_CRACK}</Text>;
            }

            return value;
        },
    },
    {
        header: formatRowHeader('Power Loss (KW)'),
        accessorKey: 'powerLoss',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('BoS (%)'),
        accessorKey: 'bos',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Major Module (%)'),
        accessorKey: 'major',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
    {
        header: formatRowHeader('Minor Module (%)'),
        accessorKey: 'minor',
        cell: (info: CellContext<string, unknown>) => info.getValue(),
    },
];
