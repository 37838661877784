import { useState, useEffect, useMemo } from 'react';

import { UpdateScope } from 'lib/constants/inspections';
import { useAnomalyMapData } from 'lib/hooks/map/useAnomalyMapData';
import {
    isMultiModuleAnomaly,
    getElectricalInfoFromAnomaly,
    countModulesByScope,
} from 'lib/inspections/electricalComponents';
import { changeAnomalyStatus, changeAnomalyStatusByScope } from 'lib/mutations/changeAnomalyStatus';
import { InspectionAnomaly } from 'lib/types/inspections/anomaliesMap';

interface UseMultiModuleAnomalyProps {
    anomaly: InspectionAnomaly;
    inspectionId: string;
    statusHasChanged: boolean;
    isEnabled: boolean;
}

interface UseMultiModuleAnomalyReturn {
    isMultiModule: boolean;
    showUpdateBanner: boolean;
    moduleCountsByScope: {
        inverter?: number;
        combiner?: number;
        stack?: number;
    };
    selectedUpdateScope: UpdateScope;
    setSelectedUpdateScope: (scope: UpdateScope) => void;
    multiModuleUpdateModalOpen: boolean;
    setMultiModuleUpdateModalOpen: (open: boolean) => void;
    handleSave: (statusId: number, onSuccess: (anomalyUuids: string[]) => void) => Promise<void>;
    handleMultiModuleUpdateConfirm: (scope: UpdateScope) => void;
    anomalyDetails: {
        anomalyCode: string;
        speciesUuid?: string;
        inverterId?: string;
        combinerId?: string;
        stackId?: string;
        hasInverterEncoding: boolean;
        hasCombinerEncoding: boolean;
    } | null;
}

/**
 * Custom hook for handling multi-module anomaly status updates
 */
export const useMultiModuleAnomaly = ({
    anomaly,
    inspectionId,
    statusHasChanged,
    isEnabled,
}: UseMultiModuleAnomalyProps): UseMultiModuleAnomalyReturn => {
    // Multi-module update state
    const [multiModuleUpdateModalOpen, setMultiModuleUpdateModalOpen] = useState(false);
    const [selectedUpdateScope, setSelectedUpdateScope] = useState<UpdateScope>(UpdateScope.SINGLE);
    const [moduleCountsByScope, setModuleCountsByScope] = useState<{
        inverter?: number;
        combiner?: number;
        stack?: number;
    }>({});

    // Get the speciesAssetMap from useAnomalyMapData
    const { speciesAssetMap } = useAnomalyMapData();

    // Extract anomaly details for multi-module update, leveraging the speciesAssetMap
    const anomalyDetails = useMemo(() => {
        if (!anomaly) {
            return null;
        }

        return getElectricalInfoFromAnomaly(anomaly, speciesAssetMap);
    }, [anomaly, speciesAssetMap]);

    // Check if this is a multi-module anomaly
    const isMultiModule = useMemo(() => {
        if (!anomalyDetails?.anomalyCode) {
            return false;
        }

        return isMultiModuleAnomaly(anomalyDetails.anomalyCode);
    }, [anomalyDetails?.anomalyCode]);

    // Track if banner should be shown (anomaly is multi-module and status has changed)
    // The feature flag is applied in the component, not here
    const showUpdateBanner = useMemo(() => isMultiModule && statusHasChanged, [isMultiModule, statusHasChanged]);

    // Calculate module counts when anomaly is loaded
    useEffect(() => {
        if (isMultiModule && anomaly && speciesAssetMap && anomalyDetails) {
            // Now electricalInfo (anomalyDetails) is a required parameter
            const counts = countModulesByScope(anomaly, speciesAssetMap, anomalyDetails);

            setModuleCountsByScope(counts);

            // Set default scope to the broadest available option
            if (counts.inverter && counts.inverter > 1 && anomalyDetails.hasInverterEncoding) {
                setSelectedUpdateScope(UpdateScope.INVERTER);
            } else if (counts.combiner && counts.combiner > 1 && anomalyDetails.hasCombinerEncoding) {
                setSelectedUpdateScope(UpdateScope.COMBINER);
            } else {
                setSelectedUpdateScope(UpdateScope.SINGLE);
            }
        }
    }, [isMultiModule, anomaly, anomalyDetails, speciesAssetMap]);

    // Handle save action
    const handleSave = async (statusId: number, onSuccess: (anomalyUuids: string[]) => void) => {
        // Feature flag disabled or not a multi-module anomaly - use single anomaly update
        if (!isEnabled || !isMultiModule) {
            await changeAnomalyStatus(inspectionId, [anomaly.uuid], statusId, onSuccess);

            return;
        }

        // If already showing update banner, update with selected scope
        if (showUpdateBanner) {
            // Pass the onSuccess callback directly - it will receive the anomaly UUIDs
            await changeAnomalyStatusByScope(
                inspectionId,
                anomaly,
                statusId,
                selectedUpdateScope,
                onSuccess,
                speciesAssetMap,
            );
        } else {
            // First time multi-module update - show selection modal
            setMultiModuleUpdateModalOpen(true);
        }
    };

    // Handle multi-module update confirmation from modal
    // Only updates the scope selection without making the API call
    const handleMultiModuleUpdateConfirm = (scope: UpdateScope) => {
        // Just update the selected scope
        setSelectedUpdateScope(scope);
        // The actual API call will be made when the user clicks Save
    };

    return {
        isMultiModule,
        showUpdateBanner,
        moduleCountsByScope,
        selectedUpdateScope,
        setSelectedUpdateScope,
        multiModuleUpdateModalOpen,
        setMultiModuleUpdateModalOpen,
        handleSave,
        handleMultiModuleUpdateConfirm,
        anomalyDetails,
    };
};
