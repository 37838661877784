import { useEffect } from 'react';

import { Info } from '@dronebase/shared-ui-icons';
import { ActionIcon, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Outlet, useLocation, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom';

import DifferentialHeader from 'components/Header/DifferentialHeader';
import DifferentialToggle from 'components/Header/DifferentialToggle';
import { InspectionInfoModal } from 'components/Header/InspectionInfo';
import { PageHeader } from 'components/Header/PageHeader';
import { SiteLayoutLoaderData } from 'components/Layout/SiteLayout';
import { SitesInpectionPageTabs } from 'components/tabs/SitesInspectionPageTabs';
import { REPORT_TABS, REPORT_TYPES, SPECIAL_GI_REPORT_SRTS } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { shortFormatDate } from 'lib/helpers';
import { getTabFromPath } from 'lib/helpers/routing';
import {
    AnomalyDataProvider,
    AnomalyOverviewFilterProvider,
    AnomalyMapFilterProvider,
    PivotTableProvider,
    PivotViewOptionsProvider,
    useDifferentialMode,
    useInspectionTabs,
    AssetLabelsProvider,
} from 'lib/hooks';
import { AnomalySpeciesProvider } from 'lib/hooks/useAnomalySpecies';

import { InspectionLoaderData } from './types';

export const InspectionPageLayout = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const navigate = useNavigate();
    const { inspectionId } = useParams();
    const { pathname } = useLocation();
    const { tabs } = useInspectionTabs();
    const siteData = useRouteLoaderData('site-details') as SiteLayoutLoaderData;
    const {
        reportType: currentReportType,
        inspectionDate,
        reportTier,
    } = useRouteLoaderData('inspection') as InspectionLoaderData;
    const { active: isDifferentialModeActive, deactivateDifferentialMode } = useDifferentialMode();

    /**
     * To properly handle the tab routing in case the customer has bookmarked an
     * inspection site without tabs.
     */
    useEffect(() => {
        const tab = getTabFromPath<REPORT_TABS>(pathname, tabs, REPORT_TABS.FINDINGS);

        if (!tab && currentReportType === REPORT_TYPES.DIAGNOSTIC_REPORT) {
            navigate(`/sites/${siteData.id}/${inspectionId}/diagnostic`, { replace: true });
        } else if (!tab && currentReportType === REPORT_TYPES.GLASS_CRACK) {
            navigate(`/sites/${siteData.id}/${inspectionId}/map`, { replace: true });
        } else if (!tab && reportTier === 1) {
            navigate(`/sites/${siteData.id}/${inspectionId}/files`, { replace: true });
        } else if (!tab) {
            navigate(`/sites/${siteData.id}/${inspectionId}/findings`, { replace: true });
        }
    }, [inspectionId, navigate, pathname, reportTier, currentReportType, siteData.id, tabs]);

    const isSpecialProductionGICase = inspectionId ? SPECIAL_GI_REPORT_SRTS.includes(inspectionId) : false;
    const reportType = isSpecialProductionGICase ? REPORT_TYPES.GLASS_CRACK : currentReportType;

    // If you're not on Map or Anomalies tab, then disable differential mode
    useEffect(() => {
        const tab = getTabFromPath<REPORT_TABS>(pathname, tabs, REPORT_TABS.FINDINGS);

        if (tab !== REPORT_TABS.MAP && tab !== REPORT_TABS.ANOMALIES && isDifferentialModeActive) {
            deactivateDifferentialMode();
        }
    }, [deactivateDifferentialMode, isDifferentialModeActive, pathname, tabs]);

    return (
        <AnomalyDataProvider>
            <AnomalySpeciesProvider>
                <AnomalyOverviewFilterProvider>
                    <AssetLabelsProvider>
                        <AnomalyMapFilterProvider>
                            <PivotTableProvider>
                                <PivotViewOptionsProvider>
                                    <PageHeader
                                        title={siteData.name}
                                        subtitle={
                                            isDifferentialModeActive ? (
                                                <Text fz="0.875rem" color="gray.2">
                                                    Back to {shortFormatDate(inspectionDate!)}
                                                </Text>
                                            ) : (
                                                <Group fz="0.875rem" spacing="0.25rem">
                                                    <Text color="gray.2">{reportType}</Text>
                                                    {reportType !== REPORT_TYPES.GLASS_CRACK && (
                                                        <ActionIcon size="sm" p={0} onClick={open}>
                                                            <Info color="var(--color-grey-300)" />
                                                        </ActionIcon>
                                                    )}
                                                </Group>
                                            )
                                        }
                                        backTo={
                                            isDifferentialModeActive
                                                ? `${ROUTES.sites.inspection.map.index}`
                                                : `${ROUTES.sites.index}/${siteData.id}`
                                        }
                                        onBackCallback={
                                            isDifferentialModeActive ? deactivateDifferentialMode : undefined
                                        }
                                        segmentedPageTabs={
                                            reportTier === 1 ||
                                            currentReportType === REPORT_TYPES.DIAGNOSTIC_REPORT ? undefined : (
                                                <SitesInpectionPageTabs />
                                            )
                                        }
                                        flexEndContent={
                                            isDifferentialModeActive ? <DifferentialHeader /> : <DifferentialToggle />
                                        }
                                    />
                                    <Outlet />
                                    <InspectionInfoModal
                                        reportType={currentReportType}
                                        opened={opened}
                                        onClose={close}
                                    />
                                </PivotViewOptionsProvider>
                            </PivotTableProvider>
                        </AnomalyMapFilterProvider>
                    </AssetLabelsProvider>
                </AnomalyOverviewFilterProvider>
            </AnomalySpeciesProvider>
        </AnomalyDataProvider>
    );
};
