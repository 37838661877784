import React from 'react';

import { IconProps } from './icon_definition';

export const EditIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M9.341 4.99478L10.4017 3.93412C10.7922 3.5436 11.4254 3.5436 11.8159 3.93412V3.93412C12.2064 4.32465 12.2064 4.95781 11.8159 5.34833L10.7552 6.409C10.7552 6.409 11.4623 5.70189 10.7552 4.99478C10.0481 4.28767 9.341 4.99478 9.341 4.99478Z"
                fill={color ?? '#222'}
            />
            <path
                d="M4.15847 11.9078C3.96303 11.9729 3.77709 11.787 3.84224 11.5915L4.39123 9.94455C4.39123 9.94455 4.56801 10.1213 5.09834 10.6517C5.62867 11.182 5.80545 11.3588 5.80545 11.3588L4.15847 11.9078Z"
                fill={color ?? '#222'}
            />
            <mask id="path-3-inside-1_2989_262999" fill="white">
                <path d="M4.39124 9.94455L10.0481 4.2877L11.4623 5.70191L5.80545 11.3588L4.39124 9.94455Z" />
            </mask>
            <path
                d="M4.74479 10.2981L10.4016 4.64125L9.69454 3.93414L4.03768 9.591L4.74479 10.2981ZM11.1087 5.34836L5.4519 11.0052L6.159 11.7123L11.8159 6.05546L11.1087 5.34836Z"
                fill={color ?? '#222'}
                mask="url(#path-3-inside-1_2989_262999)"
            />
        </svg>
    );
};
