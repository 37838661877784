import { DEFAULT_PERMISSIONS, PORTAL_MIGRATION_USER_EMAIL, USER_MANAGEMENT_ROLE } from 'lib/constants';
import { User } from 'lib/context/UsersContext';
import { getCookie } from 'lib/cookies';
import { getAppPermissions } from 'lib/helpers';
import { GET_WORKSPACE_APPS, GET_WORKSPACE_USERS } from 'lib/queries/users';
import { WorkspaceApp } from 'lib/types';
import { client, getHasuraFetchOptions } from 'lib/urqlClient';

export const usersLoader = async () => {
    const hasAppPermission = getAppPermissions(USER_MANAGEMENT_ROLE, false);

    if (!hasAppPermission) {
        throw 'An error occured';
    }

    const workspaceId = getCookie('helio-workspace')?.id;

    const workspaceUsersPromise = client
        .query(
            GET_WORKSPACE_USERS,
            {
                workspaceId,
                portalEmail: PORTAL_MIGRATION_USER_EMAIL,
            },
            {
                requestPolicy: 'network-only',
                fetchOptions: getHasuraFetchOptions(USER_MANAGEMENT_ROLE),
            },
        )
        .toPromise();

    const workspaceDataPromise = client
        .query(
            GET_WORKSPACE_APPS,
            {
                workspaceId,
                permissionNames: DEFAULT_PERMISSIONS,
            },
            {
                fetchOptions: getHasuraFetchOptions(USER_MANAGEMENT_ROLE),
            },
        )
        .toPromise();

    const { data: workspaceUsersData } = await workspaceUsersPromise;
    const { data: workspaceData } = await workspaceDataPromise;

    const workspaceUsers = workspaceUsersData.workspaceUsers.map(
        ({ user, lastModified, userPermissions }: { user: any; lastModified: any; userPermissions: any }) => ({
            ...user,
            lastModified: lastModified.aggregate.max.createdAt,
            permissions: userPermissions,
        }),
    );

    const workspaceApps = workspaceData.apps.map((app: any) => ({
        name: app.name,
        group: app.name.split(' ').join('_').toLowerCase(),
        permissions: app.permissions?.map((permission: any) => ({
            id: permission.uuid,
            key: permission.name,
            label: permission?.description || permission.name,
        })),
    }));

    const defaultPermissionIDs = workspaceData.permissions.map(({ uuid }: { uuid: string }) => uuid) || [];

    // Forcing type enforcement
    const loaderData: WorkspaceUsersLoader = { workspaceUsers, workspaceApps, defaultPermissionIDs };

    return loaderData;
};

export interface WorkspaceUsersLoader {
    workspaceUsers: User[];
    workspaceApps: WorkspaceApp[];
    defaultPermissionIDs: string[];
}
