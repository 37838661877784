import { useState, useMemo } from 'react';

import { Loader, notifications } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Box, Button, ButtonProps, Stack } from '@mantine/core';
import { useAtom } from 'jotai';

import { ANOMALY_FILE_DEFAULT_SHOWN_LIMIT } from 'lib/constants';
import { getCookie } from 'lib/cookies';
import useWorkspaceDetails from 'lib/hooks/useWorkspaceDetails';
import { BlobInstance, HandleInitiateDelete } from 'lib/types/inspections/anomalies';
import { uploadStatesAtom } from 'state/inspections/anomalySidebar';

import AnomalyFileCard from './AnomalyTab/AnomalyFileCard';
import UploadingAnomalyFileCard from './AnomalyTab/UploadingAnomalyFileCard';

interface UploadedFilesListContainerProps {
    cancelUpload: (file: File) => void;
    files: BlobInstance[];
    fetchingFiles: boolean;
    onDelete: HandleInitiateDelete;
    onRowClick: (fileIndex: number) => void;
    anomalyUUID: string;
}

export const UploadedFilesListContainer = ({
    cancelUpload,
    files,
    fetchingFiles,
    onDelete,
    onRowClick,
    anomalyUUID,
}: UploadedFilesListContainerProps) => {
    const defaultLimit = ANOMALY_FILE_DEFAULT_SHOWN_LIMIT;
    const [visibleCount, setVisibleCount] = useState(defaultLimit);
    const [expanded, setExpanded] = useState(false);

    const [uploadStates] = useAtom(uploadStatesAtom);
    const { currentUserHasFileManagerPermission } = useWorkspaceDetails();
    const userId = String(getCookie('helio-userId'));

    const showMore = () => {
        setVisibleCount(files.length);
        setExpanded(true);
    };

    const showLess = () => {
        setVisibleCount(defaultLimit);
        setExpanded(false);
    };

    const displayedFiles = useMemo(() => files.slice(0, visibleCount), [files, visibleCount]);

    const userUploadedFileCards = useMemo(
        () =>
            displayedFiles.map((file, index) => {
                const fileName = file.blob_index.object_name.split('/').pop() || '';
                const dynamicIndex = `${fileName}-${index}`;

                const userCanDelete =
                    currentUserHasFileManagerPermission &&
                    file.blob_index.blob_index_bonds.some((bond) => bond.user_uuid === userId);

                return (
                    <AnomalyFileCard
                        key={dynamicIndex}
                        file={file}
                        fileName={fileName}
                        onRowClick={() => onRowClick(index)}
                        onDelete={() => onDelete(file)}
                        isDeleteEnabled={userCanDelete}
                    />
                );
            }),
        [displayedFiles, currentUserHasFileManagerPermission, userId, onRowClick, onDelete],
    );

    const handleCancel = (file: File) => {
        cancelUpload(file);
        notifications.info({
            title: 'Your upload has been cancelled.',
            message: `File: ${file.name}`,
            withCloseButton: true,
        });
    };

    return (
        <Stack>
            {fetchingFiles ? (
                <Box p="lg" my="2rem">
                    <Loader />
                </Box>
            ) : (
                <Stack spacing="0.25rem" pt="0.25rem" pos="relative" w="100%">
                    {Array.from(uploadStates.values())
                        .filter((uploadState) => uploadState.anomalyUUID === anomalyUUID)
                        .map((uploadState, index) => (
                            <UploadingAnomalyFileCard
                                key={`uploading-${index}`}
                                uploadState={uploadState}
                                cancelUpload={() => handleCancel(uploadState.file)}
                            />
                        ))}

                    {userUploadedFileCards}

                    {files.length > defaultLimit && (
                        <ShowMoreButton size="md" onClick={expanded ? showLess : showMore} variant="text">
                            {expanded ? 'Show Less' : 'Show More'}
                        </ShowMoreButton>
                    )}
                </Stack>
            )}
        </Stack>
    );
};

const ShowMoreButton = styled(Button)<ButtonProps & { onClick:() => void }>({
    ':hover': {
        color: 'var(--color-purple-400)',
    },
    'minHeight': 'var(--btn-min-h-md)',
    'minWidth': 'var(--btn-min-w-md)',
    'fontSize': 'var(--btn-font-size-md)',
    'width': 'min-content',
    'color': 'var(--color-purple-300)',
    'textAlign': 'center',
    'fontFamily': 'Roboto',
    'fontStyle': 'normal',
    'fontWeight': 400,
    'lineHeight': '150%',
});
