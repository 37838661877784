import { useState, useRef } from 'react';

import { DefaultFile, MoreHor } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Flex, ActionIcon, Image, Menu, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useInView } from 'react-intersection-observer';

import ImageFile from 'components/common/Icons/ImageFile';
import OverflowTooltip from 'components/common/OverFlowTooltip';
import useDownloadImage from 'lib/hooks/useDownloadImage';
import { BlobInstance } from 'lib/types/inspections/anomalies';

export interface AnomalyFileCardProps {
    file?: BlobInstance;
    fileName?: string;
    isDeleteEnabled?: boolean;
    onRowClick?: () => void;
    onDelete?: (file: BlobInstance) => void;
    ref?: React.Ref<HTMLDivElement>;
}

export default function AnomalyFileCard(props: AnomalyFileCardProps) {
    const { file, fileName, isDeleteEnabled, onRowClick, onDelete } = props;

    const download = useDownloadImage();
    const [menuOpen, { open: openMenu, close: closeMenu }] = useDisclosure(false);
    const [loaded, setLoaded] = useState(false);
    const imageRef = useRef<HTMLImageElement | null>(null);

    const fileIsImage = !!file?.blob_index?.type?.includes('image');

    const { ref, inView } = useInView({
        triggerOnce: false,
        rootMargin: '50px',
    });

    const handleDelete = () => {
        if (file && onDelete) {
            onDelete(file);
        }
    };

    if (!file) {
        return null;
    }

    return (
        <HoverableFlex
            align="center"
            justify="space-between"
            h="3.5rem"
            w="100%"
            p="0.25rem"
            gap="0.75rem"
            onClick={onRowClick}
            data-testid="anomaly-file-card"
            ref={ref} // Attach IntersectionObserver for lazy loading
        >
            {inView ? (
                <>
                    <Image
                        withPlaceholder
                        imageRef={imageRef}
                        imageProps={{
                            // downscale as much as possible, as we don't have thumbnails
                            style: { imageRendering: 'pixelated' },
                            sizes: '3rem',
                        }}
                        placeholder={fileIsImage ? <ImageFile /> : <DefaultFile />}
                        alt={fileName}
                        src={file.url}
                        width="3rem"
                        height="3rem"
                        fit="cover"
                        radius="0.25rem"
                        onLoad={() => setLoaded(true)}
                        style={{ display: loaded ? 'block' : 'none' }}
                    />
                    {!loaded && (
                        <div
                            style={{
                                width: '3rem',
                                height: '3rem',
                                backgroundColor: 'var(--color-grey-800)',
                                borderRadius: '0.25rem',
                            }}
                        />
                    )}
                </>
            ) : (
                <div
                    style={{
                        width: '3rem',
                        height: '3rem',
                        backgroundColor: 'var(--color-grey-900)',
                        borderRadius: '0.25rem',
                    }}
                />
            )}

            <TextContainer justify="center" spacing="0.12rem">
                <OverflowTooltip text={file.caption || ''} variant="body2light" color="gray.0" />
                <OverflowTooltip text={fileName || ''} variant="caption1" color="gray.2" />
            </TextContainer>

            <Flex w="max-content" onClick={(e) => e.stopPropagation()}>
                <Menu
                    opened={menuOpen}
                    onOpen={openMenu}
                    onClose={closeMenu}
                    position="bottom-end"
                    width="10rem"
                    shadow="2px 2px 20px 0px #0000001A"
                >
                    <Menu.Target>
                        <ActionIcon p={0}>
                            <MoreHor />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown style={{ border: 0 }}>
                        <Menu.Item onClick={() => download(file.url)}>Download</Menu.Item>
                        {isDeleteEnabled && <Menu.Item onClick={handleDelete}>Delete</Menu.Item>}
                    </Menu.Dropdown>
                </Menu>
            </Flex>
        </HoverableFlex>
    );
}

const HoverableFlex = styled(Flex)({
    '&:hover': {
        backgroundColor: 'var(--color-grey-900)',
        borderRadius: '0.5rem',
        cursor: 'pointer',
    },
});

const TextContainer = styled(Stack)({
    flex: 1,
    minWidth: 0,
});
