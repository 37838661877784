import React from 'react';

import { IconProps } from './icon_definition';

export const SupportIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3Z"
                fill={color ?? '#222'}
            />
            <path
                d="M7.57241 8.92697C7.49913 8.92697 7.46249 8.88717 7.46249 8.80758V8.61798C7.46249 8.3839 7.51637 8.19663 7.62413 8.05618C7.7362 7.91105 7.89568 7.7589 8.10258 7.59972C8.27068 7.47331 8.39568 7.36096 8.47758 7.26264C8.55948 7.16433 8.60042 7.04494 8.60042 6.90449C8.60042 6.75468 8.5487 6.6353 8.44525 6.54635C8.3418 6.45272 8.20603 6.4059 8.03793 6.4059C7.85689 6.4059 7.71034 6.45506 7.59827 6.55337C7.49051 6.65169 7.43663 6.77809 7.43663 6.93258V7.00983C7.43663 7.08474 7.39999 7.12219 7.32672 7.12219L6.63491 7.0941C6.60474 7.0941 6.57887 7.08708 6.55732 7.07303C6.53577 7.05431 6.52499 7.03324 6.52499 7.00983V6.93258C6.52499 6.65169 6.58965 6.40356 6.71896 6.1882C6.84827 5.96816 7.02715 5.79963 7.2556 5.68258C7.48836 5.56086 7.7556 5.5 8.05732 5.5C8.35043 5.5 8.60689 5.55852 8.82672 5.67556C9.05086 5.78792 9.22327 5.9471 9.34396 6.15309C9.46465 6.35908 9.52499 6.59785 9.52499 6.86938C9.52499 7.07537 9.49051 7.25328 9.42155 7.40309C9.35689 7.54822 9.27715 7.6676 9.18232 7.76124C9.08749 7.85487 8.9668 7.95787 8.82025 8.07022C8.66508 8.18727 8.55086 8.28792 8.47758 8.37219C8.4043 8.45646 8.36767 8.55946 8.36767 8.68118V8.80758C8.36767 8.88717 8.33103 8.92697 8.25775 8.92697H7.57241ZM7.95387 10.5C7.80301 10.5 7.6737 10.4462 7.56594 10.3385C7.45818 10.2261 7.4043 10.0927 7.4043 9.9382C7.4043 9.78371 7.45818 9.65262 7.56594 9.54494C7.67801 9.43258 7.80948 9.3764 7.96034 9.3764C8.10689 9.3764 8.23405 9.43258 8.3418 9.54494C8.45387 9.65262 8.50991 9.78371 8.50991 9.9382C8.50991 10.0927 8.45387 10.2261 8.3418 10.3385C8.23405 10.4462 8.10474 10.5 7.95387 10.5Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
