import React from 'react';

import { IconProps } from './icon_definition';

export const FindingsIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="7" y="3" width="7" height="1" rx="0.5" fill={color ?? '#222'} />
            <rect x="7" y="7.5" width="7" height="1" rx="0.5" fill={color ?? '#222'} />
            <rect x="7" y="12" width="7" height="1" rx="0.5" fill={color ?? '#222'} />
            <path d="M2.5 3.5L3.5 4.5L5.5 2.5" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.5 8L3.5 9L5.5 7" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M2.5 12.5L3.5 13.5L5.5 11.5"
                stroke={color ?? '#222'}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
