import React from 'react';

import { IconProps } from './icon_definition';

export const ArchiveIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 4H13V5H3L3 4ZM2 4C2 3.44772 2.44772 3 3 3H13C13.5523 3 14 3.44772 14 4V5C14 5.55228 13.5523 6 13 6V12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12L3 6C2.44772 6 2 5.55228 2 5V4ZM4 6V12H12V6H4ZM9.5 7C9.77614 7 10 7.22386 10 7.5C10 7.77614 9.77614 8 9.5 8H6.5C6.22386 8 6 7.77614 6 7.5C6 7.22386 6.22386 7 6.5 7L9.5 7Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
