import React from 'react';

import { IconProps } from './icon_definition';

export const FilesIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 6.5V12H3L3 8.6667L2.28267 11.7751C2.13806 12.4018 2.61397 13 3.25706 13H13C13.5523 13 14 12.5523 14 12V6.5H13ZM13 5.5H14V5C14 4.44772 13.5523 4 13 4H8.41421C8.149 4 7.89464 3.89464 7.70711 3.70711L7.29289 3.29289C7.10536 3.10536 6.851 3 6.58579 3H3C2.44772 3 2 3.44772 2 4V8.55281L2.34674 7.05028C2.44627 6.61897 2.68104 6.24744 3 5.97631L3 4H6.58579L7 4.41421C7.37507 4.78929 7.88378 5 8.41421 5H13V5.5Z"
                fill={color ?? '#222'}
            />
            <path
                d="M3.32112 7.27514C3.42583 6.82141 3.82986 6.5 4.29551 6.5H14.2429C14.886 6.5 15.3619 7.09824 15.2173 7.72486L14.1789 12.2249C14.0742 12.6786 13.6701 13 13.2045 13H3.25705C2.61397 13 2.13806 12.4018 2.28266 11.7751L3.32112 7.27514Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
