import React from 'react';

import { IconProps } from './icon_definition';

export const DropdownIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M8 9L6.5 7.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M8 9L9.5 7.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path
                d="M5.5 4.5H8H10.5C11.0523 4.5 11.5 4.94772 11.5 5.5V8V10.5C11.5 11.0523 11.0523 11.5 10.5 11.5H5.5C4.94772 11.5 4.5 11.0523 4.5 10.5V5.5C4.5 4.94772 4.94772 4.5 5.5 4.5Z"
                stroke={color ?? '#222'}
                strokeLinecap="round"
            />
        </svg>
    );
};
