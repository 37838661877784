import React from 'react';

import { IconProps } from './icon_definition';

export const InverterIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="2.5" y="2.5" width="11" height="11" rx="0.5" stroke={color ?? '#222'} />
            <path d="M2.5 13.5L13.5 2.5" stroke={color ?? '#222'} strokeWidth="0.5" />
            <path d="M4 6C4 5.66667 4.2 5 5 5C6 5 6 6 7 6C7.8 6 8 5.33333 8 5" stroke={color ?? '#222'} />
            <path d="M7 10.5L12 10.5" stroke={color ?? '#222'} />
            <path d="M7 12H9" stroke={color ?? '#222'} strokeWidth="0.5" />
            <path d="M10 12H12" stroke={color ?? '#222'} strokeWidth="0.5" />
        </svg>
    );
};
