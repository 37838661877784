import React from 'react';

import { IconProps } from './icon_definition';

export const LayersIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <g clipPath="url(#clip0_3240_264619)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.20387 6.44144L3.23428 6.82927C2.17012 7.25493 2.19212 8.7689 3.2682 9.16346L7.48362 10.7091C7.81702 10.8314 8.18298 10.8314 8.51638 10.7091L12.7318 9.16346C13.8079 8.7689 13.8299 7.25493 12.7657 6.82927L11.7961 6.44144L10.3913 6.95654L12.3943 7.75775C12.6072 7.84288 12.6028 8.14567 12.3875 8.22458L8.17213 9.77024C8.06099 9.81099 7.93901 9.81099 7.82787 9.77024L3.61246 8.22458C3.39724 8.14567 3.39284 7.84288 3.60567 7.75775L5.60869 6.95654L4.20387 6.44144Z"
                    fill={color ?? '#222'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.20387 8.44144L3.23428 8.82927C2.17012 9.25493 2.19212 10.7689 3.2682 11.1635L7.48362 12.7091C7.81702 12.8314 8.18298 12.8314 8.51638 12.7091L12.7318 11.1635C13.8079 10.7689 13.8299 9.25493 12.7657 8.82927L11.7961 8.44144L10.3913 8.95654L12.3943 9.75775C12.6072 9.84288 12.6028 10.1457 12.3875 10.2246L8.17213 11.7702C8.06099 11.811 7.93901 11.811 7.82787 11.7702L3.61246 10.2246C3.39724 10.1457 3.39284 9.84288 3.60567 9.75775L5.60869 8.95654L4.20387 8.44144Z"
                    fill={color ?? '#222'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.23428 4.82926L7.44291 3.1458C7.80053 3.00276 8.19947 3.00276 8.55709 3.1458L12.7657 4.82926C13.8299 5.25492 13.8079 6.76888 12.7318 7.16345L8.51638 8.7091C8.18298 8.83135 7.81702 8.83135 7.48362 8.7091L3.2682 7.16345C2.19213 6.76888 2.17012 5.25492 3.23428 4.82926ZM3.61246 6.22457C3.39724 6.14566 3.39284 5.84286 3.60568 5.75773L7.81431 4.07428C7.93351 4.0266 8.06649 4.0266 8.1857 4.07428L12.3943 5.75773C12.6072 5.84286 12.6028 6.14566 12.3875 6.22457L8.17213 7.77022C8.06099 7.81097 7.93901 7.81097 7.82787 7.77022L3.61246 6.22457Z"
                    fill={color ?? '#222'}
                />
            </g>
            <defs>
                <clipPath id="clip0_3240_264619">
                    <rect width="24" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
