import React from 'react';

import { IconProps } from './icon_definition';

export const ArrowCollapseIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M7.5 7.5L4 4" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M7.5 7.5L7.5 4.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M7.5 7.5L4.5 7.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M10.5 10.5L14 14" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M10.5 10.5L10.5 13.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M10.5 10.5L13.5 10.5" stroke={color ?? '#222'} strokeLinecap="round" />
        </svg>
    );
};
