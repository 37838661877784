import React from 'react';

import { IconProps } from './icon_definition';

export const DownloadIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M8 10V1" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M8 10L10.5 7.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path d="M8 10L5.5 7.5" stroke={color ?? '#222'} strokeLinecap="round" />
            <path
                d="M6 2.5H3.5C2.94772 2.5 2.5 2.94772 2.5 3.5V12.5C2.5 13.0523 2.94772 13.5 3.5 13.5H12.5C13.0523 13.5 13.5 13.0523 13.5 12.5V3.5C13.5 2.94771 13.0523 2.5 12.5 2.5H10"
                stroke={color ?? '#222'}
            />
        </svg>
    );
};
