import React from 'react';

import { IconProps } from './icon_definition';

export const GroupingIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 15C13 15 15 13 15 10.8182C15 9.81403 14 9 13 9C11.8954 9 11 9.81403 11 10.8182C11 13 13 15 13 15ZM13 12C13.5523 12 14 11.5523 14 11C14 10.4477 13.5523 10 13 10C12.4477 10 12 10.4477 12 11C12 11.5523 12.4477 12 13 12Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 12V11H3L3 12H3.83333V13H3C2.44772 13 2 12.5523 2 12ZM3 9H2V7H3V9ZM3 5H2V4C2 3.44772 2.44772 3 3 3H3.83333V4L3 4V5ZM5.5 4V3H7.16667V4H5.5ZM8.83333 4V3H10.5V4H8.83333ZM12.1667 4V3H13C13.5523 3 14 3.44772 14 4V5H13V4H12.1667ZM13 7H14V8.17477C13.6828 8.06241 13.3447 8 13 8V7ZM10.1455 12H8.83333V13H10.4793C10.3464 12.6869 10.2302 12.3516 10.1455 12ZM14 11C14 11.0947 13.9868 11.1864 13.9622 11.2732C13.8434 11.6927 13.4576 12 13 12H12.1667V13H13C13.5523 13 14 12.5523 14 12V11ZM7.16667 12V13H5.5V12H7.16667Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
