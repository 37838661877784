import React from 'react';

import { IconProps } from './icon_definition';

export const CheckmarkIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M4.5 7.5L7 10L11.5 5.5" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
