import { useMemo } from 'react';

import { Loader } from '@dronebase/shared-ui-core';
import { Center } from '@mantine/core';
import { useAtomValue } from 'jotai';
import { useParams } from 'react-router-dom';

import { AnomalyEntry } from 'components/inspections/types';
import { useAnomalyOverviewFilters, useDifferentialMode } from 'lib/hooks';
import { useAssetRegionAnomalies } from 'lib/hooks/useAssetRegionAnomalies';
import { anomalyTabDropdownInspectionIdAtom } from 'state/inspections/anomaliesMap';

import AnomalyDetailsContent from './AnomalyDetailsContent';

const AnomalyTabContent = () => {
    const {
        active: differentialModeIsActive,
        previousInspectionSummary,
        differentialModeInspectionId,
    } = useDifferentialMode();
    const { siteId, inspectionId, assetRegionUUID, anomalyTabNumber } = useParams();
    const { superAnalysisTaskUUIDs } = useAnomalyOverviewFilters();
    const anomalyTabDropdownInspectionId = useAtomValue(anomalyTabDropdownInspectionIdAtom);

    const {
        anomalies: currentInspectionAnomalies,
        fetching: currentInspectionFetching,
        refetchAssetRegionAnomalies,
    } = useAssetRegionAnomalies({
        assetRegionUuid: assetRegionUUID,
        superAnalysisTaskUUIDs,
    });

    // Do not need to sort previous inspection anomalies because we're only going to show one
    const { anomalies: previousInspectionAnomalies, fetching: previousInspectionFetching } = useAssetRegionAnomalies({
        assetRegionUuid: assetRegionUUID,
        superAnalysisTaskUUIDs: previousInspectionSummary?.superAnalysisTasks[0]?.superAnalysisTaskUUID,
    });

    const currentAndPreviousAnomaliesDictionary = useMemo(
        () => ({
            [`${inspectionId}`]: currentInspectionAnomalies,
            [`${previousInspectionSummary?.inspectionID || 'no-previous-inspection'}`]:
                previousInspectionAnomalies || [],
        }),
        [
            inspectionId,
            previousInspectionAnomalies,
            previousInspectionSummary?.inspectionID,
            currentInspectionAnomalies,
        ],
    );

    const inspectionIdLookUp =
        differentialModeInspectionId === anomalyTabDropdownInspectionId || !anomalyTabDropdownInspectionId
            ? differentialModeInspectionId
            : anomalyTabDropdownInspectionId;

    if (currentInspectionFetching || previousInspectionFetching) {
        return (
            <Center h="100%">
                <Loader />
            </Center>
        );
    }

    if (!inspectionId || !assetRegionUUID || !anomalyTabNumber) {
        return null;
    }

    const anomalyToDisplay: AnomalyEntry = differentialModeIsActive
        ? currentAndPreviousAnomaliesDictionary[inspectionIdLookUp][0]
        : currentInspectionAnomalies[Number(anomalyTabNumber) - 1];

    if (!anomalyToDisplay) {
        return null;
    }

    return (
        <AnomalyDetailsContent
            anomalyToDisplay={anomalyToDisplay}
            currentInspectionAnomalies={currentInspectionAnomalies}
            inspectionId={inspectionId}
            siteId={siteId}
            assetRegionUUID={assetRegionUUID}
            anomalyTabNumber={anomalyTabNumber}
            refetchAssetRegionAnomalies={refetchAssetRegionAnomalies}
        />
    );
};

export default AnomalyTabContent;
