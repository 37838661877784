import React from 'react';

import { IconProps } from './icon_definition';

export const ModuleAdvancedIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="2.5" y="2.5" width="11" height="11" rx="0.5" stroke={color ?? '#222'} />
            <rect x="4" y="4" width="2" height="2" rx="0.5" fill={color ?? '#222'} />
            <rect x="4" y="7" width="8" height="1" rx="0.5" fill={color ?? '#222'} />
            <rect x="4" y="11" width="8" height="3" rx="1" fill={color ?? '#222'} />
            <rect x="7" y="4" width="2" height="2" rx="0.5" fill={color ?? '#222'} />
            <rect x="10" y="4" width="2" height="2" rx="0.5" fill={color ?? '#222'} />
            <rect x="4" y="9" width="8" height="1" rx="0.5" fill={color ?? '#222'} />
        </svg>
    );
};
