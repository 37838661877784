import React from 'react';

import { IconProps } from './icon_definition';

export const ArrowLeftIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 8C13.5 8.27614 13.2761 8.5 13 8.5L3 8.5C2.72386 8.5 2.5 8.27614 2.5 8C2.5 7.72386 2.72386 7.5 3 7.5L13 7.5C13.2761 7.5 13.5 7.72386 13.5 8Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.35355 11.3536C6.15829 11.5488 5.84171 11.5488 5.64645 11.3536L2.64645 8.35355C2.45118 8.15829 2.45118 7.84171 2.64645 7.64645C2.84171 7.45119 3.15829 7.45119 3.35355 7.64645L6.35355 10.6464C6.54882 10.8417 6.54882 11.1583 6.35355 11.3536Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.35355 4.64645C6.54881 4.84171 6.54881 5.15829 6.35355 5.35355L3.35355 8.35355C3.15829 8.54882 2.84171 8.54882 2.64645 8.35355C2.45118 8.15829 2.45118 7.84171 2.64645 7.64645L5.64645 4.64645C5.84171 4.45119 6.15829 4.45119 6.35355 4.64645Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
