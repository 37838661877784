import React from 'react';

import { IconProps } from './icon_definition';

export const SettingsIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 3C8.77614 3 9 3.22386 9 3.5V4.12602C9.36702 4.22048 9.71366 4.36573 10.032 4.55382L10.4749 4.11091C10.6701 3.91565 10.9867 3.91565 11.182 4.11091L11.8891 4.81802C12.0844 5.01328 12.0844 5.32986 11.8891 5.52512L11.4462 5.96803C11.6343 6.28634 11.7795 6.63298 11.874 7H12.5C12.7761 7 13 7.22386 13 7.5V8.5C13 8.77614 12.7761 9 12.5 9H11.874C11.7795 9.36702 11.6343 9.71366 11.4462 10.032L11.8891 10.4749C12.0843 10.6701 12.0843 10.9867 11.8891 11.182L11.182 11.8891C10.9867 12.0844 10.6701 12.0844 10.4749 11.8891L10.032 11.4462C9.71365 11.6343 9.36702 11.7795 9 11.874V12.5C9 12.7761 8.77614 13 8.5 13H7.5C7.22386 13 7 12.7761 7 12.5V11.874C6.63298 11.7795 6.28634 11.6343 5.96803 11.4462L5.52513 11.8891C5.32987 12.0843 5.01329 12.0843 4.81802 11.8891L4.11092 11.182C3.91565 10.9867 3.91565 10.6701 4.11092 10.4749L4.55382 10.032C4.36573 9.71366 4.22048 9.36702 4.12602 9H3.5C3.22386 9 3 8.77614 3 8.5V7.5C3 7.22386 3.22386 7 3.5 7H4.12602C4.22048 6.63298 4.36573 6.28635 4.55382 5.96804L4.11091 5.52513C3.91565 5.32987 3.91565 5.01328 4.11091 4.81802L4.81802 4.11092C5.01328 3.91565 5.32986 3.91565 5.52512 4.11092L5.96803 4.55382C6.28634 4.36573 6.63298 4.22048 7 4.12602V3.5C7 3.22386 7.22386 3 7.5 3H8.5ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
