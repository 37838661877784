import React from 'react';

import { IconProps } from './icon_definition';

export const WebhooksIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 5H3L3 11H13V5ZM3 4C2.44772 4 2 4.44772 2 5V11C2 11.5523 2.44772 12 3 12H13C13.5523 12 14 11.5523 14 11V5C14 4.44772 13.5523 4 13 4H3Z"
                fill={color ?? '#222'}
            />
            <path d="M4.5 9.5L6 8L4.5 6.5" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 9.5L10.5 9.5" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
