import React from 'react';

import { IconProps } from './icon_definition';

export const DiagnosticIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <rect x="5" y="1" width="6" height="3" rx="1" fill={color ?? '#222'} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 2H3C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H13C13.5523 14 14 13.5523 14 13V3C14 2.44772 13.5523 2 13 2H12V3H13V13H3L3 3H4V2ZM11 3V2H5V3H11Z"
                fill={color ?? '#222'}
            />
            <path d="M6 8.5L7.5 10L10.5 7" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
