import React from 'react';

import { IconProps } from './icon_definition';

export const CurrentLocationIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M4.42303 7.18251L10.5366 5.14465C10.9275 5.01436 11.2994 5.38623 11.1691 5.77711L9.1312 11.8907C8.97925 12.3465 8.33447 12.3465 8.18252 11.8907L7.3217 9.30824C7.27194 9.15894 7.15478 9.04178 7.00548 8.99201L4.42303 8.1312C3.96718 7.97925 3.96718 7.33446 4.42303 7.18251Z"
                fill={color ?? '#222'}
            />
        </svg>
    );
};
