import React from 'react';

import { IconProps } from './icon_definition';

export const SortArrowIcon = (props: IconProps) => {
    const { color = '#222222', ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M10.5 4L10.5 13" stroke={color} strokeLinecap="round" />
            <path d="M5.5 4L5.5 13" stroke={color} strokeLinecap="round" />
            <path d="M10.5 4L8 6.5" stroke={color} strokeLinecap="round" />
            <path d="M5.5 13L8 10.5" stroke={color} strokeLinecap="round" />
            <path d="M10.5 4L13 6.5" stroke={color} strokeLinecap="round" />
            <path d="M5.5 13L3 10.5" stroke={color} strokeLinecap="round" />
        </svg>
    );
};
