import React from 'react';

import { IconProps } from './icon_definition';

export const SortColumnsIcon = (props: IconProps) => {
    const { color = '#222222', ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M5.5 8L11.5 8" stroke={color} strokeLinecap="round" />
            <path d="M8.5 5.5L8.5 1.5" stroke={color} strokeLinecap="round" />
            <path d="M8.5 10.5L8.5 14.5" stroke={color} strokeLinecap="round" />
            <path d="M8.5 1.5L6.5 3.5" stroke={color} strokeLinecap="round" />
            <path d="M8.5 14.5L10.5 12.5" stroke={color} strokeLinecap="round" />
            <path d="M8.5 1.5L10.5 3.5" stroke={color} strokeLinecap="round" />
            <path d="M8.5 14.5L6.5 12.5" stroke={color} strokeLinecap="round" />
        </svg>
    );
};
