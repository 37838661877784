import React from 'react';

import { IconProps } from './icon_definition';

// eslint-disable-next-line complexity
export const NeedInspectionIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5 1C1.24112 1 1.02819 1.19675 1.00258 1.44888C1.00087 1.46569 1 1.48274 1 1.5V3.5C1 3.77614 1.22386 4 1.5 4C1.77614 4 2 3.77614 2 3.5L2 2L3.5 2C3.77614 2 4 1.77614 4 1.5C4 1.22386 3.77614 1 3.5 1H1.5Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 1.5C15 1.24112 14.8032 1.02819 14.5511 1.00258C14.5343 1.00087 14.5173 1 14.5 1L12.5 1C12.2239 1 12 1.22386 12 1.5C12 1.77614 12.2239 2 12.5 2L14 2L14 3.5C14 3.77614 14.2239 4 14.5 4C14.7761 4 15 3.77614 15 3.5L15 1.5Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5 15C14.7589 15 14.9718 14.8032 14.9974 14.5511C14.9991 14.5343 15 14.5173 15 14.5L15 12.5C15 12.2239 14.7761 12 14.5 12C14.2239 12 14 12.2239 14 12.5L14 14L12.5 14C12.2239 14 12 14.2239 12 14.5C12 14.7761 12.2239 15 12.5 15L14.5 15Z"
                fill={color ?? '#222'}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 14.5C1 14.7589 1.19675 14.9718 1.44888 14.9974C1.46569 14.9991 1.48274 15 1.5 15L3.5 15C3.77614 15 4 14.7761 4 14.5C4 14.2239 3.77614 14 3.5 14L2 14L2 12.5C2 12.2239 1.77614 12 1.5 12C1.22386 12 1 12.2239 1 12.5L1 14.5Z"
                fill={color ?? '#222'}
            />
            <path
                d="M11.8048 5.5H4.19519C3.85104 5.5 3.55106 5.73422 3.46759 6.0681L2.59259 9.5681C2.47425 10.0415 2.83227 10.5 3.32019 10.5H12.6798C13.1677 10.5 13.5258 10.0415 13.4074 9.5681L12.5324 6.0681C12.4489 5.73422 12.149 5.5 11.8048 5.5Z"
                stroke={color ?? '#222'}
            />
            <path d="M6 6L5 10" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M10 12.5L6 12.5" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M8 11V12" stroke={color ?? '#222'} strokeLinecap="square" />
            <path d="M10 6L11 10M8 6V10" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
            <path d="M3.5 8H12.5" stroke={color ?? '#222'} strokeWidth="0.5" strokeLinecap="round" />
        </svg>
    );
};
