import { useEffect, useState } from 'react';

import color from 'color';
import { Layer } from 'recharts';

import { colors } from 'lib/constants';

const SankeyLink = ({
    sourceX,
    targetX,
    sourceY,
    targetY,
    sourceControlX,
    targetControlX,
    payload,
    linkWidth,
    index,
    backwardLinks,
    hoveredLinkIndex,
    setHoveredLinkIndex,
    onLinkClick,
}: any) => {
    const defaultFillColor = colors.lightGray;
    const [fill, setFill] = useState(defaultFillColor);

    useEffect(() => {
        if (
            backwardLinks.includes(index) ||
            hoveredLinkIndex === index ||
            (index % 2 === 0 ? hoveredLinkIndex === index + 1 : hoveredLinkIndex === index - 1)
        ) {
            setFill(color(defaultFillColor).darken(0.1).hex());
        } else {
            setFill(defaultFillColor);
        }
    }, [backwardLinks, index, hoveredLinkIndex, defaultFillColor]);

    return (
        <Layer key={`CustomLink-${payload?.source}-${payload?.target}-${index}`}>
            <path
                d={`
                    M${sourceX},${sourceY + linkWidth / 2}
                    C${sourceControlX},${sourceY + linkWidth / 2}
                    ${targetControlX},${targetY + linkWidth / 2}
                    ${targetX},${targetY + linkWidth / 2}
                    L${targetX},${targetY - linkWidth / 2}
                    C${targetControlX},${targetY - linkWidth / 2}
                    ${sourceControlX},${sourceY - linkWidth / 2}
                    ${sourceX},${sourceY - linkWidth / 2}
                    Z
                `}
                fill={fill}
                data-cy="sankey-link"
                strokeWidth="01"
                style={{ cursor: 'pointer' }}
                onClick={() => onLinkClick(payload)}
                onMouseEnter={() => {
                    setHoveredLinkIndex(index);
                }}
                onMouseLeave={() => {
                    setHoveredLinkIndex(null);
                }}
            />
        </Layer>
    );
};

export default SankeyLink;
