import React from 'react';

import { IconProps } from './icon_definition';

export const DashIcon = (props: IconProps) => {
    const { color, ...rest } = props;

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M4.5 8L11.5 8" stroke={color ?? '#222'} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
